import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Proposal } from '../_models/proposal';
import { MeetingService } from '../_services/meeting.service';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {

  @Input() proposal: Proposal = new Proposal();
  @Input('disabled') isDisabled: Boolean = false;

  constructor(private meetingService: MeetingService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  accept() {
    this.meetingService.acceptProposal(this.proposal.id).subscribe(result => {
      this.proposal.proposalStatus = 'accepted';
      this.toastrService.success("Udało się pomyślnie zaakceptować propozycje!");
    })
  }

  reject() {
    this.meetingService.rejectProposal(this.proposal.id).subscribe(result => {
      this.proposal.proposalStatus = 'rejected';
      this.toastrService.success("Udało się pomyślnie zaakceptować propozycje!");
    })
  }

}
