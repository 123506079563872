export class Relation {
    id: number;
    conferenceId: number;
    title: string;
    description: string;
    photo: string;
    photoFileName: string;
    photoFile: string;
    userId: number;
    userName: string;
    userLastName: string;
    image: ArrayBuffer;
}
