import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeInterval'
})
export class TimeIntervalPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'empty': {
        return 'Wolne';
      }
      case 'suspended': {
        return 'Zablokowane';
      }
      case 'booked': {
        return 'Zajęte';
      }
      default: {
        return '';
      }
    }
  }

}
