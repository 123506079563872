<form>
    <div class="row mt-3 m-1">
        <div class="col-10">
            <mat-form-field class="w-100">
                <input matInput required placeholder="Symbol" [(ngModel)]="meetingStand.symbol" name="text"
                    #name="ngModel">
            </mat-form-field>
        </div>
        <div class="col-1">
            <button class="w-100" type="button" mat-raised-button color="primary" (click)="update()">Zmień</button>
        </div>
        <div class="col-1">
            <button class="w-100" type="button" mat-raised-button color="warn" (click)="delete()">Usuń</button>
        </div>

    </div>
</form>

<app-b2b-hour class="w-100" *ngFor="let time of meetingStand.timeIntervals"
    (timeIntervalDeleted)="deleteTimeInterval($event)" [timeInterval]="time"></app-b2b-hour>

<form (ngSubmit)="addTimeInterval()" #hourForm="ngForm" class="col-12 m-1">
    <mat-form-field>
        <mat-label>Od</mat-label>
        <input matInput type="time" required [(ngModel)]="newTimeInterval.from" name="from">
    </mat-form-field>
    <mat-form-field class="ml-3">
        <mat-label>Do</mat-label>
        <input matInput type="time" required [(ngModel)]="newTimeInterval.to" name="to">
    </mat-form-field>
    <button type="submit" class="ml-3" mat-mini-fab color="primary">
        <mat-icon>add</mat-icon>
    </button>
</form>