<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="w-100">
  <div class="row">
    <div class="col-6">
      <h1><strong>Edytowanie Stoiska</strong></h1>
    </div>
    <div class="col-6 add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/stands-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Zapisz</button>
      </div>

    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Firma</mat-label>
            <mat-select disabled (selectionChange)="onCompanyChanged($event)" [(ngModel)]="model.companyId"
              name="companyId">
              <mat-option *ngFor="let element of companies" [value]="element.id">
                {{element.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Numer stoiska" (keydown.enter)="onSubmit()" required [(ngModel)]="model.symbol"
              name="symbol" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Nazwa stoiska" (keydown.enter)="onSubmit()" [(ngModel)]="model.name"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" data="<p></p>"
            [(ngModel)]="model.description" name="description" #name="ngModel"></ckeditor>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
