import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionType'
})
export class QuestionTypePipe implements PipeTransform {

  transform(value: string, args: string): string {
    switch (value) {
      case 'singlechoice': {
        return 'Pojedynczy wybór';
      }
      case 'multiplechoice': {
        return 'Wielokrotnego wyboru';
      }
      case 'text': {
        return 'Tekst';
      }
      case 'multiplechoicewithtext': {
        return 'Wielokrotnego wyboru oraz tekst';
      }
      default: {
        return '';
      }
    }
  }
}
