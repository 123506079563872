export class Stand {
    id: number;
    symbol: string;
    name: string;
    description: string;
    conferenceId: number;
    conferenceName: string;
    companyId: number;
    companyName: string;
}
