import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Company } from '@management/comapnies/_models/company';
import { CompaniesService } from '@management/comapnies/_services/companies.service';
import { Router } from '@angular/router';
import { BaseTable } from '@shared/_base/base-table'
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'logo', 'id', 'symbol', 'name', 'nip', 'country'];
  dataSource = new MatTableDataSource<Company>();
  selection = new SelectionModel<Company>(true, []);
  resourceUrl:string;


  ngOnInit(): void {
    this.resourceUrl=environment.resourceUrl;
    this.companiesService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Company, filter: string) =>  !filter ||
      data.name.trim().toLowerCase().indexOf(filter) !== -1 ||
      data.symbol.trim().toLowerCase().indexOf(filter) !== -1 ||
      (data.nip != null && data.nip.trim().toLowerCase().indexOf(filter) !== -1) ||
      (data.country != null && data.country.trim().toLowerCase().indexOf(filter) !== -1);
      this.setPage();
    })
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  edit() {
    if (this.selection.selected.length > 0) {
      let selectedElement = this.selection.selected[0];
      this.router.navigate(['management/company-edit', selectedElement.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  delete() {
    if (this.selection.selected.length > 0) {
      this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
        if (result) {
          this.selection.selected.forEach(element => {
            let id = element.id;
            this.companiesService.delete(id).subscribe(_ => {
              let indexToDelete = this.dataSource.data.indexOf(element);
              this.dataSource.data.splice(indexToDelete, 1);
              this.dataSource._updateChangeSubscription();
              this.toastr.success("Pomyślnie usunięto firmę!")
            })
            this.selection.clear();
          });
        }
      });
    }
  }

  constructor(private companiesService: CompaniesService,
    private router: Router,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
