import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Poll } from '@conference/polls/_models/poll';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';

@Injectable({
  providedIn: 'root'
})
export class PollsService {

  private apiUrl: string = `${environment.apiUrl}`;
  private currentConference: CurrentConference;
  private endPoint: string = '/Polls';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<Poll[]>(this.apiUrl + '/Polls/GetPollsByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<Poll>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  edit(poll: Poll) {
    return this.http.put(this.apiUrl + this.endPoint, poll, { headers: this.headers });
  }

  add(poll: Poll) {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    poll.conferenceId = this.currentConference.id;
    return this.http.post(this.apiUrl + this.endPoint, poll, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  start(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/StartPoll/' + id,null, { headers: this.headers });
  }

  end(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/FinishPoll/' + id,null, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
