import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxText'
})
export class MaxTextPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let text =  value as string;

    if(text.length >= 150){
      return text.substr(0,150).concat("...");
    }
    else{
      return text;
    }
  }
}
