import { Sponsor } from '@conference/companies/_models/sponsor'

export class ConferenceDescription {
    id: number;
    conferenceId: number;
    companyId: number;
    companyName: string;
    pageLink: string;
    email: string;
    phone: string;
    fax: string;
    logoPath: string;
    logoFile: string | ArrayBuffer | null;;
    description: string;
    offer: string;
    hasStand: true;
    address: string;
    sponsorTypeId: number;
    sponsorType: Sponsor;
    materialPath: string;
}
