import { Element } from "./element";


export class Conference {
    id: number;
    name: string;
    symbol: string;
    location: string;
    description: string;
    logoUrl: string;
    portalId: number;
    startDateOfConference: Date;
    endDateOfConference: Date;
    elements: Element[];
    showParticipants: boolean;
    liveStreamUrl: string;
}
