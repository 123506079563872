import { Component, OnInit } from '@angular/core';
import { Company } from '@management/comapnies/_models/company';
import { CompaniesService } from '@management/comapnies/_services/companies.service';
import { Router } from '@angular/router';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';
import { CountryHelperService } from '@core/_services/country-helper.service'



@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss']
})
export class CompanyAddComponent implements OnInit {

  model: Company = new Company();
  public image: any;
  page:any;
  pageSize:any;
  public Editor = ClassicEditor;
  email = new FormControl('', [Validators.required, Validators.email]);
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    }
  };
  ngOnInit(): void {
    this.model.isActive = true;
  }

  onSubmit() {
    if(this.email.hasError('email')){
      this.toastr.error("Błędny email!");
      return;
    }
    this.companiesService.add(this.model).subscribe(result => {
      this.toastr.success("Pomyślnie dodano firmę!")
      this.router.navigate(['management/company-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  imageAdded($event) {
    this.image = $event;
    this.model.logoFile = this.image;
  }

  fileNameChanged($event) {
    this.model.logoFileName = $event;
    this.model.logo = null;

  }

  getErrorMessage() {
    return this.email.hasError('email') ? 'Błędny email' : '';
  }

  constructor(private companiesService: CompaniesService,
    private toastr: ToastrService,
    public countryHelperService:CountryHelperService,
    private router: Router) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }
}
