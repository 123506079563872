export class RegistrationForm {
    id: number;
    userId: number;
    firstName: string;
    lastName: string;
    contactEmail: string;
    contactPhoneNumber: string;
    companyId: number;
    company: string;
    comments: string;
    registrationTime: Date;
    conferenceId: number;
    conferenceName: string;
    status: boolean;
}

