import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConferencePlan } from '@conference/conference-info/_models/conference-plan';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';

@Injectable({
  providedIn: 'root'
})
export class ConferencePlanService {

  private currentConference: CurrentConference;
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/ConferencePlans';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getByConferenceId() {
    return this.http.get<ConferencePlan>(this.apiUrl + this.endPoint + '/GetByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<ConferencePlan>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  add(conferencePlan: ConferencePlan) {
    return this.http.post(this.apiUrl + this.endPoint, conferencePlan, { headers: this.headers });
  }

  edit(conferencePlan: ConferencePlan) {
    return this.http.put(this.apiUrl + this.endPoint, conferencePlan, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });

  }
}
