import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Stand } from '@conference/stands/_models/stand'
import { StandsService } from '@conference/stands/_services/stands.service'
import { BaseTable } from '@shared/_base/base-table'
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';

@Component({
  selector: 'app-stands-list',
  templateUrl: './stands-list.component.html',
  styleUrls: ['./stands-list.component.css']
})
export class StandsListComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'symbol', 'name', 'companyName'];
  dataSource = new MatTableDataSource<Stand>();
  selection = new SelectionModel<Stand>(true, []);

  ngOnInit(): void {
    this.standsService.getAllByCurrentConferenceId().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Stand, filter: string) => {
        return  (data.name != null && data.name.trim().toLowerCase().indexOf(filter) !== -1) ||
        (data.companyName != null && data.companyName.trim().toLowerCase().indexOf(filter) !== -1) ||
        (data.symbol != null && data.symbol.trim().toLowerCase().indexOf(filter) !== -1)
    }
    this.setPage();
    }, error => {
      this.toastr.error( "Nie udało się pobrać stoisk!")
    })
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  edit() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/stands-edit/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  addStandsPhoto() {
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.standsService.delete(element.id).subscribe(result => {
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
            this.toastr.success("Pomyślnie usunięto stoisko!");
          });
        }
        this.selection.clear();
      }
    });
  }

  generateStands(){
    this.confirmDialogService.afterClosed('Czy na pewno chcesz wygenerować stoisk firm? (Operacja może chwilę potrwać)').subscribe(result => {
      if (result) {
        this.standsService.generateStandsForConference().subscribe(result=>{
          this.toastr.success("Udało się wygenerować stoiska dla konferencji.");
          this.ngOnInit();
        })
      }
    });

  }

  constructor(private standsService: StandsService,
    private toastr: ToastrService,
    private router: Router,
    private confirmDialogService:ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }

}
