<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="w-100">
  <div class="row">
    <div class="col-6">
      <h1><strong>Dodawanie Powiadomienia</strong></h1>

    </div>
    <div class="col-6 add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/notifications-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Dodaj</button>
      </div>

    </div>
  </div>
  <div class="row">
    <mat-card class="col-12">
      <mat-card-content>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <input matInput placeholder="Nazwa" required [(ngModel)]="model.title" name="start" #name="ngModel">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Data wysyłki</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="model.dontSendBefore" name="dontSentBefore"
                #name="ngModel">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>Czas wysyłki</mat-label>
              <input type="time" matInput required name="dontSentBeforeTime" [(ngModel)]="currentTime" #name="ngModel">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Typ powiadomienia</mat-label>
              <mat-select required [(ngModel)]="model.notificationType" #name="ngModel" name="programElementId">
                <mat-option *ngFor="let type of notificationTypes" [value]="type.name">
                  <div [innerHTML]="type.displayName"></div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" [(ngModel)]="model.description"
              [data]="model.description" name="description" #description="ngModel"></ckeditor>
          </div>
        </div>

        <div class="row">
          <div class="col-3 mt-1">
            <button mat-raised-button type="button" (click)="selectAll()" color="primary">Zaznaczenie</button>
          </div>
        </div>

        <mat-form-field class="w-100">
          <mat-label>Filtr</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
        </mat-form-field>
        <div class="mat-elevation-z8">

          <table class="w-100" mat-table matSort [dataSource]="dataSource">
            <caption></caption>
            <ng-container matColumnDef="select">
              <th scope="col" mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]=production>Id</th>
              <td mat-cell *matCellDef="let element" [hidden]=production> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="firstName">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Imię</th>
              <td mat-cell *matCellDef="let element"> {{element?.firstName}} </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Nazwisko</th>
              <td mat-cell *matCellDef="let element"> {{element?.lastName}} </td>
            </ng-container>

            <ng-container matColumnDef="userName">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Login</th>
              <td mat-cell *matCellDef="let element"> {{element?.userName}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
              <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
            </ng-container>

            <ng-container matColumnDef="personConnection">
              <th scope="col" mat-header-cell *matHeaderCellDef>Powiązanie</th>
              <td mat-cell *matCellDef="let element">
                <mat-icon style="color:green;text-align:center;" *ngIf="element.personConnectionId > 0">
                  done</mat-icon>
                <mat-icon style="color:red;text-align:center;" *ngIf="element.personConnectionId <= 0">
                  clear</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator>
          </mat-paginator>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</form>
