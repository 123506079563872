import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Conference } from "@conference/conference-info/_models/conference";
import { CurrentConferenceHelperService } from "@core/_services/current-conference-helper.service";
import { HttpHeadersHelperService } from "@core/_services/http-headers-helper.service";
import { environment } from "@env";
import { CurrentConference } from "@shared/_models/current-conference";

@Injectable({
  providedIn: "root",
})
export class ConferenceService {
  private currentConference: CurrentConference;
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = "/Conferences";
  private headers: HttpHeaders =
    this.httpHeadersHelperService.getCustomHeaders();

  get(id: number) {
    return this.http.get<Conference>(this.apiUrl + this.endPoint + "/" + id, {
      headers: this.headers,
    });
  }

  getProgramElements() {
    this.getCurrentConference();
    return this.http.get<Conference>(
      this.apiUrl + this.endPoint + "/" + this.currentConference.id,
      { headers: this.headers }
    );
  }

  private getCurrentConference() {
    this.currentConferenceHelperService.currentConference.subscribe((x) => {
      this.currentConference = x;
    });
  }

  setLiveStreamUrl(liveStreamUrl: string) {
    this.getCurrentConference();
    return this.http.post(
      this.apiUrl + this.endPoint + "/SetLiveStreamUrl/",
      { conferenceId: this.currentConference.id, liveStreamUrl: liveStreamUrl },
      { headers: this.headers }
    );
  }

  switchShowParticipants() {
    this.getCurrentConference();
    return this.http.post(
      this.apiUrl +
      this.endPoint +
      "/SwitchShowParticipants/" +
      this.currentConference.id,
      null,
      { headers: this.headers }
    );
  }

  constructor(
    private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService
  ) { }
}
