import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { MeetingArea } from '@conference/b2b/_models/meeting-area';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { MeetingStand } from '../_models/meeting-stand';


@Injectable({
  providedIn: 'root'
})
export class MeetingAreaService {

  private currentConference: CurrentConference = new CurrentConference();
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/MeetingAreas';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();


  add(meetingArea: MeetingArea) {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    meetingArea.conferenceId = this.currentConference.id;
    return this.http.post(this.apiUrl + this.endPoint, meetingArea, { headers: this.headers });
  }

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<MeetingArea[]>(this.apiUrl + this.endPoint + '/GetByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<MeetingArea[]>(this.apiUrl + this.endPoint + id, { headers: this.headers });
  }

  addMeetingStand(meetingStand: MeetingStand) {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.post(this.apiUrl + this.endPoint + '/AddMeetingStand', meetingStand, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + "/" + id, { headers: this.headers });
  }

  updateMeetingStand(meetingStand: MeetingStand) {
    return this.http.put(this.apiUrl + this.endPoint + '/UpdateMeetingStand', meetingStand, { headers: this.headers });
  }

  deleteMeetingStand(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/RemoveMeetingStand/' + id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
