<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="company-form">
  <div class="row">
    <div class="col">
      <h1><strong>Dodawanie Użytkownika</strong></h1>
    </div>
    <div class="col add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/management/user-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Zapisz</button>
      </div>

    </div>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field class="w-100">
            <input matInput placeholder="Imię" required [(ngModel)]="model.firstName" name="firstName" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Nazwisko" required [(ngModel)]="model.lastName" name="lastName"
              #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Email" [formControl]="email" required [(ngModel)]="model.email" name="email">
            <mat-error *ngIf="email.invalid">{{getErrorMessage(email)}}</mat-error>
          </mat-form-field>
          <form [formGroup]="passwords">
            <mat-form-field class="w-100">
              <input matInput placeholder="Hasło" formControlName="password" type="password" required
                [(ngModel)]="model.password" name="password">
              <mat-error *ngIf="passwords.controls.password.invalid">
                {{getErrorMessage(passwords.controls.password)}}</mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="Powtórz hasło" formControlName="confirmPassword" type="password" required
                [(ngModel)]="model.confirmPassword" name="confirmPassword">
              <mat-error *ngIf="passwords.controls.confirmPassword.invalid">
                {{getErrorMessage(passwords.controls.confirmPassword)}}
              </mat-error>
            </mat-form-field>
          </form>
          <mat-form-field class="w-100">
            <input required type="text" placeholder="Firma" aria-label="company" matInput
              [(ngModel)]="model.companyName" [formControl]="companiesFormControl" [matAutocomplete]="companies">
            <mat-autocomplete #companies="matAutocomplete">
              <mat-option *ngFor="let company of filteredCompanies | async | slice : 0 : 10" [value]="company.name"
                matTooltip="{{company.name}} | {{company.nip}} | {{company.symbol}}">
                {{company.name}} | {{company.nip}} | {{company.symbol}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Telefon" [(ngModel)]="model.phoneNumber" name="phone" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Stanowisko" [(ngModel)]="model.companyPosition" name="position"
              #name="ngModel">
          </mat-form-field>

          <mat-form-field class="w-100">
            <input type="text" placeholder="Użytkownik w startm systemie" aria-label="User" matInput
              [(ngModel)]="model.oldSystemUserEmail" [formControl]="oldSystemUsersControl" [matAutocomplete]="oldUsers">
            <mat-autocomplete #oldUsers="matAutocomplete">
              <mat-option *ngFor="let oldUser of filteredOptions | async | slice : 0 : 10" [value]="oldUser.email">
                {{oldUser.name}} {{oldUser.surname}} {{oldUser.email }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
