import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { ConferenceModule } from '@conference/conference.module';
import { CoreModule } from '@core/core.module';
import { HttpErrorInterceptor } from '@core/_interceptors/http-error.interceptor';
import { ManagementModule } from '@management/management.module';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import { appRoutingModule } from './app-routing';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ManagementModule,
    ConferenceModule,
    SharedModule,
    CoreModule,
    MaterialModule,
    appRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
