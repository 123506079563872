import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '@management/comapnies/_models/company';
import { CompaniesService } from '@management/comapnies/_services/companies.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppUser } from '../_models/app-user';
import { OldUser } from '../_models/old-user';
import { AppUserService } from '../_services/app-user.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  model:AppUser = new AppUser();
  currentImage:any;
  oldUsers: OldUser[] = [];
  oldSystemUsersControl = new FormControl();
  filteredOptions: Observable<OldUser[]>;
  companies: Company[] = [];
  companiesFormControl = new FormControl();
  filteredCompanies: Observable<Company[]>;
  email = new FormControl('', [Validators.required, Validators.email]);
  passwords: FormGroup;
  page:any;
  pageSize:any;

  ngOnInit(): void {
    this.appUserService.getAllOldUsers().subscribe(result=>{
      this.oldUsers = result;
      this.filteredOptions = this.oldSystemUsersControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });

    this.companiesService.getAll().subscribe(result=>{
      this.companies = result;
      this.filteredCompanies = this.companiesFormControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterCompanies(value))
        );
    });

}

private _filterCompanies(value: string): Company[] {
  const filterValue = value.toLowerCase();

  return this.companies.filter(option => option.name.concat(" ").concat(option.nip).concat(" ")
          .concat(option.contactEmail).toLowerCase().includes(filterValue)
  );
}

private _filter(value: string): OldUser[] {
  const filterValue = value.toLowerCase();

  return this.oldUsers.filter(option => option.name.concat(" ").concat(option.surname).concat(" ")
          .concat(option.email).toLowerCase().includes(filterValue)
  );
}

  onSubmit(){
    if(this.model.password !== this.model.confirmPassword)
    {
      this.toastr.error("Podane hasła muszą być zgodne!");
      return;
    }

    if(this.oldSystemUsersControl.value === ""){
      this.model.oldSystemUserId = 0;
    }
    else{
      var oldUser = this.oldUsers.filter(user=>user.email == this.model.oldSystemUserEmail)[0];
      if(oldUser){
        this.model.oldSystemUserId = oldUser.id;
      }
    }
    if(this.email.hasError('required')||this.email.hasError('email')){
      this.toastr.error("Błędny email!");
      return;
    }
    if(this.companiesFormControl.value == null || this.companiesFormControl.value === ""){
      this.toastr.error("Firma musi być wypełniona!");
      return;
    }
    else{
      var company = this.companies.filter(company=>company.name == this.model.companyName)[0];
      if(company){
        this.model.companyId = company.id;
      }
    }

    this.appUserService.add(this.model).subscribe(result => {
      this.toastr.success("Pomyślnie dodano użytkownika!")
      this.router.navigate(['management/user-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  getErrorMessage(test: FormControl): string {
    if (test.hasError('required')) {
      return 'Wprowadź wartość';
    }
    if (test.hasError('minlength')){
      return 'Wymagana długość to '+test.errors.minlength.requiredLength;
    }
    if (test.hasError('email')) {
      return 'Błędny email';
    }
    if (test.hasError('pattern')){
      return 'Hasło musi zawierać: małą literę, dużą literę, numer i znak specjalny';
    }
    if (test.hasError('mustMatch')){
      return 'Hasła muszą być identyczne';
    }
    return  '';
  }

  mustMatch(passwordName: string, confirmPassowrdName: string) {
    return (formGroup: FormGroup) => {
      const password = formGroup.controls[passwordName];
      const confirmPassowrd = formGroup.controls[confirmPassowrdName];

      if (confirmPassowrd.errors && !confirmPassowrd.errors.mustMatch) {
          return;
      }

      if (password.value !== confirmPassowrd.value) {
          confirmPassowrd.setErrors({ mustMatch: true });
      } else {
          confirmPassowrd.setErrors(null);
      }
  }
}

  constructor(
    private appUserService: AppUserService,
    private router: Router,
    private companiesService:CompaniesService,
    private toastr:ToastrService,
    private formBuilder:FormBuilder
    ) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
      this.passwords = this.formBuilder.group({
        password :['', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*')])],
        confirmPassword : ['']
      }, {validator: this.mustMatch('password', 'confirmPassword')})
     }

}
