import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupedNotification } from '../_models/grouped-notification';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { NotificationsService } from '../_services/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AppUser } from '@management/users/_models/app-user';
import { BaseTable } from '@shared/_base/base-table';
import { AppUserService } from '@management/users/_services/app-user.service';

@Component({
  selector: 'app-notifications-details',
  templateUrl: './notifications-details.component.html',
  styleUrls: ['./notifications-details.component.css']
})
export class NotificationsDetailsComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'firstName', 'lastName', 'userName', 'email', 'personConnection'];
  dataSource = new MatTableDataSource<AppUser>();
  selection = new SelectionModel<AppUser>(true, []);
  model: GroupedNotification = new GroupedNotification();
  public Editor = ClassicEditor;
  page:any;
  pageSize:any;
  notificationTypes: any[] = [
    { id: 1, name: 'sms', displayName: 'SMS' },
    { id: 2, name: 'push', displayName: 'Push' },
    { id: 3, name: 'email', displayName: 'Email' }
  ];

  ngOnInit(): void {
    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.notificationsService.get(id).subscribe(result => {
      this.model = result;
      this.appUserService.getUsersByConferenceId(this.model.conferenceId).subscribe(result => {
        this.dataSource.data = result;
        this.model.notifications.forEach(element => {
          const singleElement = result.find(x => x.id == element.receiverId);
          if (singleElement) {
            this.selection.select(singleElement);
          }
        });
      })
    }, error => {
      this.router.navigate(['conference/notifications-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });

  }

  constructor(private notificationsService: NotificationsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private appUserService: AppUserService) { super()
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }

}
