<div class="row m-1">

    <form (ngSubmit)="saveTimeInterval()" #hourForm="ngForm" class="col-5">
        <mat-form-field>
            <mat-label>Od</mat-label>
            <input matInput type="time" required [(ngModel)]="from" name="from">
        </mat-form-field>
        <mat-form-field class="ml-3">
            <mat-label>Do</mat-label>
            <input matInput type="time" required [(ngModel)]="to" name="to">
        </mat-form-field>
        <button type="submit" class="ml-3" mat-mini-fab color="primary"
            [disabled]="(timeInterval.timeIntervalStatus !== 'empty')">
            <mat-icon>save</mat-icon>
        </button>
    </form>

    

    <div class="col-2">
        <h2><strong>{{timeInterval.timeIntervalStatus | timeInterval}}</strong></h2>
    </div>
    <div class="col-1">
        <button type="button" mat-mini-fab (click)="timeIntervalInfo()"
            [disabled]="timeInterval.timeIntervalStatus !== 'booked'">
            <mat-icon>info</mat-icon>
        </button>
    </div>
    <div class="col-4">
        <div class="float-right">
            <button type="button" mat-stroked-button color="primary" class="ml-1" (click)="unblock()">
                Odblokuj
            </button>
            <button type="button" mat-stroked-button color="accent" class="ml-1" (click)="block()">
                Zablokuj
            </button>
            <button type="button" mat-stroked-button color="warn" class="ml-1" (click)="delete()">
                Usuń
            </button>
        </div>
    </div>

    
</div>