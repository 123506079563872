import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/_services/auth.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        //Token not set
        if (!currentUser) {
            this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
            return false
        };
        //Date expired
        if (this.tokenExpired(currentUser?.tokenData?.token)) {
            this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
            return false
        };
        //Token set and valid
        if (currentUser) return true;
        // not logged in so redirect to login page with the return url
        this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    private tokenExpired(token: string) {
        if (token == null) {
          return true;
        }
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
}
