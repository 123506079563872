<div class="row">
  <div class="col-2">
    <h1><strong>Ankiety</strong></h1>

  </div>
  <div class="col-10">
    <div class="float-right">
      <button routerLink="/conference/polls-add" [state]="{page: paginator.pageIndex,pageSize: paginator.pageSize}"
        mat-raised-button color="primary">
        Dodaj
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="accent"
        (click)="edit()">
        Edytuj
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="primary"
        (click)="runOnWall()">
        Pokaż na Wall'u
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="primary"
        (click)="start()">
        Start
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="warn" (click)="end()">
        Koniec
      </button>

      <button [disabled]="selection.selected.length < 1" class="ml-1" mat-raised-button color="warn" (click)="delete()">
        Usuń
      </button>
    </div>

  </div>

</div>

<mat-form-field class="w-100">
  <mat-label>Filtr</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
</mat-form-field>
<div class="mat-elevation-z8">

  <table mat-table matSort class="w-100" [dataSource]="dataSource">
    <caption></caption>
    <ng-container matColumnDef="select">
      <th scope="col" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]=production>Id</th>
      <td mat-cell *matCellDef="let element" [hidden]=production> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa</th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Początek</th>
      <td mat-cell *matCellDef="let element"> {{element.start | date:'dd/MM/yyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Koniec</th>
      <td mat-cell *matCellDef="let element"> {{element.end | date:'dd/MM/yyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="pollState">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element"> {{element.pollState | pollState}} </td>
    </ng-container>

    <ng-container matColumnDef="answers">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Odpowiedzi</th>
      <td mat-cell *matCellDef="let element"> {{element.numberOfFilled}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator></mat-paginator>
</div>
