import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupedNotification } from '@conference/notifications/_models/grouped-notification';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private currentConference: CurrentConference = new CurrentConference();
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Notifications/GroupNotification';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  get(id: number) {
    return this.http.get<GroupedNotification>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<GroupedNotification[]>(this.apiUrl + '/Notifications/GetGroupNotificationsByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete<GroupedNotification>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  add(groupedNotification: GroupedNotification) {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    groupedNotification.conferenceId = this.currentConference.id;
    return this.http.post<GroupedNotification>(this.apiUrl + this.endPoint, groupedNotification, { headers: this.headers });
  }

  edit(groupedNotification: GroupedNotification) {
    return this.http.put<GroupedNotification>(this.apiUrl + this.endPoint, groupedNotification, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
