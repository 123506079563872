import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as  ClassicEditor from 'assets/ckeditor/ckeditor.js';
import { Company } from '@conference/companies/_models/company';
import { CompanyService } from '@conference/companies/_services/company.service';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConferenceDescription } from '../_models/conference-description';
import { Sponsor } from '../_models/sponsor';

@Component({
  selector: 'app-companies-add',
  templateUrl: './companies-add.component.html',
  styleUrls: ['./companies-add.component.scss']
})
export class CompaniesAddComponent implements OnInit {
  resourceUrl: string;
  selectedCompany: Company;
  sponsors: Sponsor[] = [];
  public Editor = ClassicEditor;
  companyControl = new FormControl();
  filteredCompanies: Observable<Company[]>;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };
  public model: ConferenceDescription = new ConferenceDescription();
  private currentConference: CurrentConference;
  page:any;
  pageSize:any;
  public companies: Company[];
  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(private companiesService: CompanyService,
    protected currentConferenceHelper: CurrentConferenceHelperService,
    private toastr: ToastrService,
    private router: Router
  ) {
    if (this.router.getCurrentNavigation().extras.state){
      this.page = this.router.getCurrentNavigation().extras.state.page;
      this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
    }
  }

  ngOnInit(): void {
    this.resourceUrl = environment.resourceUrl;
    this.companiesService.getSponsors().subscribe(result => {
      this.sponsors = result;
    })

    this.companiesService.getAllCompanies().subscribe(result => {
      this.companies = result;
      this.setFilter();
    })

    this.currentConferenceHelper.currentConference.subscribe(result => {
      this.currentConference = result;

    })
  }

  private _filter(value: string): Company[] {
    const filterValue = value.toLowerCase();
    return this.companies
      .filter(company => company.name
        .concat(" ")
        .concat(company.nip)
        .concat(" ")
        .concat(company.symbol)
        .toLowerCase()
        .includes(filterValue)
      );
  }

  getCompanyInfo() {
    this.selectedCompany = this.companies.find(company => company.name == this.model.companyName);
  }

  onSubmit() {
    this.model.companyId = this.selectedCompany.id;
    this.model.conferenceId = this.currentConference.id;
    if (this.email.hasError('email')) {
      this.toastr.error("Błędny email!");
      return;
    }
    this.companiesService.add(this.model).subscribe(result => {
      this.model = new ConferenceDescription();
      this.setFilter();
      this.model.description = null;
      this.model.offer = null;
      this.toastr.success("Pomyślnie dodano, dodaj kolejną firmę!");
    });
  }
  getErrorMessage() {
    return this.email.hasError('email') ? 'Błędny email' : '';
  }

  setFilter() {
    this.filteredCompanies = this.companyControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
}
