import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '@core/_services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class HttpHeadersHelperService {

  constructor(private auth: AuthService) { }

  getCustomHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Api-Key', 'pcYBMKE*2g8BbixqXiQnAgLWtrD-DBUN')
      .set('Authorization', 'Bearer ' + this.tokenWork());
    return headers;
  }

  tokenWork(): string {
    if (this.auth.currentUserValue.tokenData.token?.length > 0)
      return this.auth.currentUserValue.tokenData.token;
    throw new Error("Token not provided!");
  }
}
