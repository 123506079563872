import { Component, OnInit } from '@angular/core';
import { Project } from '@management/conferences/_models/project'
import { Category } from '@management/conferences/_models/category'
import { ConferenceConnection } from '@management/conferences/_models/conference-connection'
import { ConferenceConnectService } from '@management/conferences/_services/conference-connect.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-conference-connect',
  templateUrl: './conference-connect.component.html',
  styleUrls: ['./conference-connect.component.css']
})
export class ConferenceConnectComponent implements OnInit {

  public projects: Project[] = [];
  public categories: Category[] = [];
  public model: ConferenceConnection = new ConferenceConnection();
  projectControl = new FormControl();
  categoryControl = new FormControl();
  filteredProjects: Observable<Project[]> = new Observable;
  filteredCategories: Observable<Category[]> = null;

  onSubmit() {
    if (this.model) {
      this.model.projectId = this.projects.filter(project => project.name.toLowerCase()
            .includes(this.model.project.name.toLowerCase()))[0].id ?? 0;
      this.model.categoryId = this.categories.filter(category => category.name.toLowerCase()
            .includes(this.model.category.name.toLowerCase()))[0].id ?? 0;
      this.conferenceConnectService.add(this.model).subscribe(result => {
        this.toastr.success("Pomyślnie połączono konferencję!")
        this.router.navigate(["management/conference-list"])
      });
    }
  }

  ngOnInit(): void {

    this.conferenceConnectService.getCategories().subscribe(result => {
      this.categories = result;
      this.filteredCategories = this.categoryControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterCategory(value))
        );
    })

    this.conferenceConnectService.getProjects().subscribe(result => {
      this.projects = result;
      this.filteredProjects = this.projectControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterProject(value))
        );
    })
  }

  private _filterProject(value: string): Project[] {
    const filterValue = value.toLowerCase();

    return this.projects.filter(project => project.name.toLowerCase().includes(filterValue)
    );
  }

  private _filterCategory(value: string): Category[] {
    const filterValue = value.toLowerCase();

    return this.categories.filter(category => category.name.toLowerCase().includes(filterValue)
    );
  }

  constructor(private conferenceConnectService: ConferenceConnectService,
    private toastr: ToastrService,
    private router: Router) { }

}
