import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Company } from '@conference/companies/_models/company';
import { CompanyService } from '@conference/companies/_services/company.service';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { environment } from '@env';
import { BaseTable } from '@shared/_base/base-table';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css']
})
export class CompaniesListComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select','logo', 'id', 'symbol', 'name', 'nip', 'Country'];
  dataSource = new MatTableDataSource<Company>();
  selection = new SelectionModel<Company>(true, []);
  resourceUrl:string;

  ngOnInit(): void {

    this.resourceUrl=environment.resourceUrl;
    this.companyService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.InitFilterPredicate();
      this.setPage();
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  private InitFilterPredicate() {
    this.dataSource.filterPredicate = (data: Company, filter: string) => {
      return (data.name != null && data.name.trim().toLowerCase().indexOf(filter) !== -1) ||
        (data.symbol != null && data.symbol.trim().toLowerCase().indexOf(filter) !== -1) ||
        (data.nip != null && data.nip.trim().toLowerCase().indexOf(filter) !== -1) ||
        (data.country != null && data.country.trim().toLowerCase().indexOf(filter) !== -1);
    };
  }

  edit() {
    if (this.selection.selected.length > 0) {
      var selectedElement = this.selection.selected[0];
      this.router.navigate(['conference/companies-edit', selectedElement.id, selectedElement.companyConference.conferenceId],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        this.selection.selected.forEach(element => {
          let id = element.id;
          this.companyService.delete(element.companyConference.id).subscribe(result => {
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
            this.toastr.success("Pomyślnie usunięto definicję firmy!")
          })
          this.selection.clear();
        });
      }
    });
  }

  generateCompanies(){
    this.confirmDialogService.afterClosed('Czy na pewno chcesz wygenerować profile firm? (Operacja może chwilę potrwać)').subscribe(result => {
      if (result) {
        this.companyService.generateCompaniesForConference().subscribe(result=>{
          this.toastr.success("Udało się wygenerować profile firm dla konferencji.");
          this.ngOnInit();
        })
      }
    });


  }

  constructor(private companyService: CompanyService,
    protected snackBar: MatSnackBar,
    private router: Router,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }
}
