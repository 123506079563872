<div class="row">
  <div class="col-3">
    <h1><strong>Firmy</strong></h1>

  </div>
  <div class="col-9">
    <div class="float-right">
      <button (click)="generateCompanies()" mat-raised-button color="primary">
        Wygeneruj profile firm
      </button>
      <button routerLink="/conference/companies-add" [state]="{page: paginator.pageIndex,pageSize: paginator.pageSize}"
        mat-raised-button color="primary" class="ml-1">
        Dodaj
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="accent"
        (click)="edit()">
        Edytuj
      </button>
      <button [disabled]="selection.selected.length < 1" class="ml-1" mat-raised-button color="warn" (click)="delete()">
        Usuń
      </button>
    </div>

  </div>
</div>

<mat-form-field class="w-100">
  <mat-label>Filtr</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
</mat-form-field>
<div class="mat-elevation-z8">

  <table mat-table matSort class="w-100" [dataSource]="dataSource">
    <caption></caption>
    <ng-container matColumnDef="select">
      <th scope="col" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container class="logo" matColumnDef="logo">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Logo</th>
      <td mat-cell *matCellDef="let element">
        <img *ngIf="element?.logo!=null" [src]=resourceUrl+element?.logo>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]=production>Id</th>
      <td mat-cell *matCellDef="let element" [hidden]=production> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</th>
      <td mat-cell *matCellDef="let element"> {{element?.symbol}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa</th>
      <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
    </ng-container>

    <ng-container matColumnDef="nip">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>NIP</th>
      <td mat-cell *matCellDef="let element"> {{element?.nip}} </td>
    </ng-container>

    <ng-container matColumnDef="Country">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Kraj</th>
      <td mat-cell *matCellDef="let element"> {{element?.country}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator></mat-paginator>
</div>
