import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationType'
})
export class NotificationTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'Push': {
        return 'Push';
      }
      case 'Email': {
        return 'Email';
      }
      case 'SMS': {
        return 'SMS';
      }
      default: {
        return 'brak';
      }
    }
  }

}
