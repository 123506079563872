<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="company-form">

    <div class="row">
        <div class="col-4">
            <h1><strong>Edytowanie połączenia Kategorii i Projektu</strong></h1>
        </div>
        <div class="col-8" >
            <div class="float-right">
                <button mat-stroked-button class="login-button " color="primary"
                routerLink="/management/conference-list">Anuluj</button>
            <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
                color="primary">Zapisz</button>
            </div>           
        </div>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <input type="text" placeholder="Projekt" aria-label="Project" matInput
                            [(ngModel)]="model.project.name" [formControl]="projectControl"
                            [matAutocomplete]="autoProject">
                        <mat-autocomplete #autoProject="matAutocomplete">
                            <mat-option *ngFor="let project of filteredProjects | async | slice : 0 : 10"
                                [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100">
                        <input type="text" placeholder="Kategoria" aria-label="Category" matInput
                            [(ngModel)]="model.category.name" [formControl]="categoryControl"
                            [matAutocomplete]="autoCategory">
                        <mat-autocomplete #autoCategory="matAutocomplete">
                            <mat-option *ngFor="let category of filteredCategories | async | slice : 0 : 10"
                                [value]="category.name">
                                {{category.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</form>