<form (ngSubmit)="addAnswer()" #questionForm="ngForm" class="comment-form">

    <div class="container">
        <div class="row">
            <div class="col-10">
                <div class="row">
                    <h2>{{question.text}} {{question.questionType | questionType}}</h2>
                </div>
            </div>
            <div class="col-2">
                <button mat-raised-button type="button" class="ml-1 w-100" (click)="deleteQuestion()"
                    color="warn">Usuń</button>
            </div>
        </div>
        <div class="row" *ngIf="question.questionType != 'Text'">

            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Treść odpowiedzi" required [(ngModel)]="newAnswer.description"
                        name="text" #name="ngModel">
                </mat-form-field>
            </div>

            <div class="col-12 col-md-2">
                <mat-form-field class="w-100">
                    <mat-label>Rodzaj</mat-label>
                    <mat-select [(ngModel)]="newAnswer.posibleAnswerType" required #name="ngModel" name="questionType">
                        <mat-option *ngFor="let element of answerTypes" [value]="element.name">
                            <div>{{element.displayName}}</div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-2">
                <div class="p-3">
                    <mat-checkbox class="w-100" [(ngModel)]="newAnswer.isCorrectAnswer" name="isCorrectAnswer"
                        #name="ngModel">Poprawna</mat-checkbox>
                </div>
            </div>

            <div class="col-12 col-md-2">
                <button mat-raised-button type="button" class="ml-1 w-100" [disabled]="!questionForm.form.valid"
                    (click)="addAnswer()" color="primary">Dodaj
                    odpowiedź</button>
            </div>

            <div class="container">
                <app-answer *ngFor="let answer of answers" (answerDeleted)="deleteAnswer($event)" class="w-100"
                    [answer]="answer"></app-answer>
            </div>

        </div>
    </div>
</form>