import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';
import { AppUser } from '@management/users/_models/app-user';
import { OldUser } from '../_models/old-user';
import { UserPoints } from '../_models/user-points';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class AppUserService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Users';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getAll() {
    return this.http.get<AppUser[]>(this.apiUrl + this.endPoint + '/GetAccountsWithConnectedPerson', { headers: this.headers });
  }

  getUsersByConferenceId(conferenceId: number){
    return this.http.get<AppUser[]>(this.apiUrl + this.endPoint + '/GetAccountsWithConnectedPersonByConferenceId/' + conferenceId, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<AppUser>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  edit(user: AppUser) {
    return this.http.post<AppUser>(this.apiUrl + this.endPoint + '/UpdateUserAndConnectToOldSystemUser', user, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  getAllOldUsers() {
    return this.http.get<OldUser[]>(this.apiUrl + '/Participants/GetParticipantsWithoutMobileAccount', { headers: this.headers });
  }

  getOldUser(id: number) {
    return this.http.get<OldUser>(this.apiUrl + '/Participants/' + id, { headers: this.headers });
  }

  getProfileImage(id: number) {
    return this.http.get(this.apiUrl + '/Users/GetProfileImage/' + id, { headers: this.headers, responseType: 'blob' });
  }

  setProfileImage(userIdForUpdate: number,newImage:any) {
    return this.http.post(this.apiUrl + '/Users/SetProfileImage/', {userId:userIdForUpdate, image:newImage}, { headers: this.headers, responseType: 'blob' });
  }

  getUserPoints(id:number){
    return this.http.get<UserPoints>(this.apiUrl + '/UserActivities/GetUserPoints/' + id, { headers: this.headers });

  }

  getUserPointsPerConference(userId:number,conferenceId:number){
    return this.http.get<UserPoints>(this.apiUrl + '/UserActivities/GetUserPoints/' + userId + '/' + conferenceId, { headers: this.headers });

  }

  add(user:AppUser){
    return this.http.put(this.apiUrl + '/Users/CreateUser', user, {headers : this.headers});
  }

  confirmEmail(userId:number){
    return this.http.post(this.apiUrl + '/Users/ConfirmEmailManually/'+userId ,null, {headers: this.headers});
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) {
  }
}
