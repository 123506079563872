import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { PollResult } from '../_models/poll-result';

@Injectable({
  providedIn: 'root'
})
export class PollResultService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Polls/GetPollAnswers';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getResults(id: number) {
    return this.http.get<PollResult>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) { }
}
