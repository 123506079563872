<mat-toolbar color="primary">
</mat-toolbar>


<div class="col-12 col-md-6 offset-md-3 mt-5 ">

    <mat-card class="login-form mat-elevation-z8">
        <mat-card-header class="header-element">
            <img alt="Logo" src="../../assets/Logo.png" />
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <mat-form-field class="w-100">
                    <mat-label>Login</mat-label>
                    <input matInput type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </mat-form-field>

                <mat-form-field class="w-100">
                    <mat-label for="password">Hasło</mat-label>
                    <input matInput type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </mat-form-field>

                <div>
                    <button mat-raised-button class="login-button" color="primary" [disabled]="loading">
                        Zaloguj
                    </button>
                    <mat-spinner *ngIf="loading" mode="indeterminate" style="margin:0 auto;"> </mat-spinner>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </mat-card-content>
    </mat-card>
</div>