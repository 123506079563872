import { Component, Input, OnInit } from '@angular/core';
import { Element } from '@conference/conference-info/_models/element';

@Component({
  selector: 'app-program-element',
  templateUrl: './program-element.component.html',
  styleUrls: ['./program-element.component.css']
})
export class ProgramElementComponent implements OnInit {

  @Input('element') element: Element;

  constructor() { }

  ngOnInit(): void {
  }

}
