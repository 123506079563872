import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Relation } from '@conference/relations/_models/relation';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';

@Injectable({
  providedIn: 'root'
})
export class RelationsService {

  private currentConference: CurrentConference = new CurrentConference();
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Relations';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<Relation[]>(this.apiUrl + this.endPoint + '/GetByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<Relation>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
