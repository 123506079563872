<form #companyForm="ngForm" class="registration-form">
  <div class="row">
    <div class="col-4 registration-form">
      <h1><strong>Formularz Rejestracyjny</strong></h1>
    </div>
    <div class="col-8">
      <div class="float-right">
        <button mat-raised-button class="login-button " color="primary" routerLink="/management/registration-forms-list"
          [state]={page:this.page,pageSize:this.pageSize}>Zamknij</button>
      </div>

    </div>
  </div>
  <mat-card>
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field class="w-100">
          <input matInput placeholder="Imię" readonly [(ngModel)]="model.firstName" name="firstName" #name="ngModel">
        </mat-form-field>
        <br>
        <mat-form-field class="w-100">
          <input matInput placeholder="Nazwisko" readonly [(ngModel)]="model.lastName" name="lastName" #name="ngModel">
        </mat-form-field>
        <br>
        <mat-form-field class="w-100">
          <input matInput placeholder="Email" readonly [(ngModel)]="model.contactEmail" name="contactEmail"
            #name="ngModel">
        </mat-form-field>
        <br>
        <mat-form-field class="w-100">
          <input matInput placeholder="Telefon" readonly [(ngModel)]="model.contactPhoneNumber"
            name="contactPhoneNumber" #name="ngModel">
        </mat-form-field>
        <br>
        <mat-form-field class="w-100">
          <mat-label>Data Rejestracji</mat-label>
          <input matInput readonly [matDatepicker]="picker" [(ngModel)]="model.registrationTime" name="registrationTime"
            #name="ngModel">
          <mat-datepicker-toggle disabled matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker disabled #picker></mat-datepicker>
        </mat-form-field>
        <br>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field class="w-100">
          <input matInput placeholder="Firma" readonly [(ngModel)]="model.company" name="company" #name="ngModel">
        </mat-form-field>
        <br>
        <mat-form-field class="w-100">
          <input matInput placeholder="Konferencja" readonly [(ngModel)]="model.conferenceName" name="conference"
            #name="ngModel">
        </mat-form-field>
        <br>
        <mat-form-field class="w-100">
          <textarea matInput matTextareaAutosize placeholder="Uwagi" readonly [(ngModel)]="model.comments"
            name="comments" #name="ngModel"></textarea>
        </mat-form-field>
        <br>

      </div>
    </div>
  </mat-card>
</form>
