<div class="row">
  <div class="col-3">
    <h1><strong>Formularze</strong></h1>
  </div>
  <div class="col-9">
    <div class="float-right">
      <button [disabled]="selection.selected.length != 1" mat-raised-button color="primary" (click)="details()">
        Szczegóły
      </button>
      <button [disabled]="selection.selected.length < 1" class="ml-1" mat-raised-button color="primary"
        (click)="done()">
        Przeczytane
      </button>
      <button [disabled]="selection.selected.length < 1" class="ml-1" mat-raised-button color="accent"
        (click)="cancel()">
        Nieprzeczytane
      </button>
      <button [disabled]="selection.selected.length < 1" class="ml-1" mat-raised-button color="warn" (click)="delete()">
        Usuń
      </button>
    </div>
  </div>
</div>


<mat-form-field class="w-100">
  <mat-label>Filtr</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
</mat-form-field>
<div class="mat-elevation-z8">

  <table class="w-100" mat-table matSort [dataSource]="dataSource">
    <caption></caption>
    <ng-container matColumnDef="select">
      <th scope="col" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]=production>Id</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status" [hidden]=production> {{element.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Imię i nazwisko</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status">
        {{element?.firstName + ' ' + element?.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="contactEmail">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status"> {{element?.contactEmail}} </td>
    </ng-container>

    <ng-container matColumnDef="contactPhoneNumber">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status"> {{element?.contactPhoneNumber}}
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Firma</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status"> {{element?.company}} </td>
    </ng-container>

    <ng-container matColumnDef="conference">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Konferencja</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status"> {{element?.conferenceName}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Data rejestracji</th>
      <td mat-cell *matCellDef="let element" [class.new-form]="!element.status">
        {{element?.registrationTime | date:'dd/MM/yyyy HH:mm'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator></mat-paginator>
</div>
