import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionService } from '@conference/polls/_services/question.service';
import { Poll } from '../_models/poll';
import { Question } from '../_models/question';

@Component({
  selector: 'app-question-add',
  templateUrl: './question-add.component.html',
  styleUrls: ['./question-add.component.css']
})
export class QuestionAddComponent {

  @Input('poll') poll: Poll = new Poll();
  @Output() questionAdded: EventEmitter<any> = new EventEmitter();
  public model: Question = new Question();
  questionTypes: any[] = [
    { id: 1, name: 'SingleChoice', displayName: 'Pojedynczy wybór' },
    { id: 2, name: 'MultipleChoice', displayName: 'Wielokrotnego wyboru' },
    { id: 3, name: 'Text', displayName: 'Tekst' },
    { id: 4, name: 'MultipleChoiceWithText', displayName: 'Wielokrotnego wyboru oraz tekst' }
  ];

  addComment() {
    this.model.pollId = this.poll.id;
    this.questionService.add(this.model).subscribe(result => {
      this.questionService.getByPollId(this.poll.id).subscribe(result => {
        this.poll.questions = result;
        this.model = new Question();
      })
    });
  }

  constructor(private questionService: QuestionService) { }

}
