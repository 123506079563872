<form (ngSubmit)="addComment()" #questionForm="ngForm" class="comment-form">

    <div class="container">
        <div class="row">
            <h1 class="w-100 text-center font-weight-bold">Kolejne Pytanie</h1>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Treść pytania" required [(ngModel)]="model.text" name="text"
                        #name="ngModel">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <mat-label>Rodzaj</mat-label>
                    <mat-select [(ngModel)]="model.questionType" required #name="ngModel" name="questionType">
                        <mat-option *ngFor="let element of questionTypes" [value]="element.name">
                            <div>{{element.displayName}}</div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="offset-3 col-6">
                <button mat-raised-button type="button" class="ml-1 w-100" [disabled]="!questionForm.form.valid"
                    (click)="addComment()" color="primary">Dodaj
                    pytanie</button>
            </div>
        </div>
    </div>
</form>