import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pollState'
})
export class PollStatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'finished': {
        return 'Zakończona';
      }
      case 'inprogress': {
        return 'W trakcie';
      }
      case 'new': {
        return 'Nowa';
      }
      default: {
        return '';
      }
    }
  }

}
