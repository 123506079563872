import { Presenter } from "./presenter";


export class Element {
    id: number;
    name: string;
    date: Date;
    startTime: string;
    endTime: string;
    room: string;
    presenters: Presenter[] = [];
    state: number;
    numberOfLikes:number;
    additionalDescription:string;
}
