<div class="row">
  <div class="col-3">
    <h1><strong>Konferencje</strong></h1>
  </div>
  <div class="col-9">
    <div class="float-right">
      <button [disabled]="selection.selected.length != 1 || !accessHelperService.hasManagementAccess()" class="ml-1"
        mat-raised-button color="warn" (click)="disconnect()">
        Rozłącz
      </button>
      <button [disabled]="!accessHelperService.hasManagementAccess()" class="ml-1" mat-raised-button color="primary"
        (click)="connect()">
        Połącz
      </button>
      <button [disabled]="selection.selected.length != 1 || !accessHelperService.hasManagementAccess()" class="ml-1"
        mat-raised-button color="accent" (click)="edit()">
        Edytuj
      </button>
      <button [disabled]="selection.selected.length != 1 || !accessHelperService.hasConferenceAccess()" class="ml-1"
        mat-raised-button color="accent" (click)="select()">
        Wybierz
      </button>
    </div>
  </div>
</div>


<mat-form-field class="w-100">
  <mat-label>Filtr</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
</mat-form-field>
<div class="mat-elevation-z8">

  <table class="w-100" mat-table matSort [dataSource]="dataSource">
    <caption></caption>
    <ng-container matColumnDef="select">
      <th scope="col" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]=production>Id</th>
      <td mat-cell *matCellDef="let element" [hidden]=production> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</th>
      <td mat-cell *matCellDef="let element"> {{element?.symbol}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let element">
        <div class="row">
          <div class="col col-12">{{element?.startDateOfConference | date:'dd/MM/yyyy'}} -</div>
          <div class="col col-12">{{element?.endDateOfConference | date:'dd/MM/yyyy'}}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="portal">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Branża</th>
      <td mat-cell *matCellDef="let element"> {{element?.portal?.name}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa Konferencji</th>
      <td mat-cell *matCellDef="let element"> <a class="active-link"
          (click)='navigate(element.id)'>{{element?.name}}</a>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator></mat-paginator>
</div>
