import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeetingStand } from '../_models/meeting-stand';
import { TimeInterval } from '../_models/time-interval';
import { MeetingAreaService } from '../_services/meeting-area.service';
import { TimeIntervalService } from '../_services/time-interval.service';

@Component({
  selector: 'app-b2b-meeting-stand',
  templateUrl: './b2b-meeting-stand.component.html',
  styleUrls: ['./b2b-meeting-stand.component.css']
})
export class B2bMeetingStand implements OnInit {

  @Output('addedTimeInterval') addedTimeInterval:EventEmitter<TimeInterval> = new EventEmitter<TimeInterval>();
  @Output('deletedMeetingStand') deletedMeetingStand:EventEmitter<MeetingStand> = new EventEmitter<MeetingStand>();
  @Input('meetingStand') meetingStand: MeetingStand;
  newTimeInterval:TimeInterval = new TimeInterval();

  constructor(private toastr: ToastrService,
    private meetingAreaService: MeetingAreaService,
    private timeIntervalService:TimeIntervalService) { }

  ngOnInit(): void {
  }

  update() {
    this.meetingAreaService.updateMeetingStand(this.meetingStand).subscribe(result => {
      this.toastr.success("Udało się zaktualizowac stoisko!");
    });
  }

  delete() {
    this.meetingAreaService.deleteMeetingStand(this.meetingStand.id).subscribe(result => {
      this.deletedMeetingStand.emit(this.meetingStand);
      this.toastr.success("Pomyślnie usunięto stanowisko!");
    });
  }

  deleteTimeInterval($event) {
    const ind = this.meetingStand.timeIntervals.indexOf($event);
    this.meetingStand.timeIntervals.splice(ind, 1);
    this.toastr.success("Udało się usunąć slot czasowy!");
  }

  addTimeInterval(){
    this.newTimeInterval.meetingStandId = this.meetingStand.id;
    this.newTimeInterval.standSymbol = this.meetingStand.symbol;
    this.timeIntervalService.add(this.newTimeInterval).subscribe(
      result=>{
        this.toastr.success("Udało się dodać slot!");
        this.newTimeInterval = new TimeInterval();
        this.addedTimeInterval.emit(this.newTimeInterval);
      }
    );
  }
  
}
