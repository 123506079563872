<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="company-form">
  <div class="row">
    <div class="col-3">
      <h1><strong>Dodawanie Firmy</strong></h1>
    </div>
    <div class="col-9">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/management/company-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Dodaj</button>
      </div>

    </div>
  </div>

  <div>
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-md-6">

            <mat-form-field class="w-100">
              <input matInput placeholder="Nazwa" required [(ngModel)]="model.name" name="name" #name="ngModel">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Symbol" required [(ngModel)]="model.symbol" name="symbol" #name="ngModel">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="NIP" required [(ngModel)]="model.nip" name="nip" #name="ngModel">
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Kraj</mat-label>
              <mat-select [(ngModel)]="model.country" name="country" #name="ngModel">
                <mat-option *ngFor="let count of countryHelperService.countries" [value]="count.value">
                  {{count.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput type="email" placeholder="Email kontaktowy" [formControl]="email"
                [(ngModel)]="model.contactEmail" name="contactEmail">
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">

            <mat-form-field class="w-100">
              <input matInput placeholder="Kod Pocztowy" [(ngModel)]="model.postalCode" name="postalCode" maxlength="11"
                #name="ngModel">
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="Miasto" [(ngModel)]="model.city" name="city" #name="ngModel">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Ulica" [(ngModel)]="model.street" name="street" #name="ngModel">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Numer domu" [(ngModel)]="model.houseNumber" name="houseNumber" maxlength="10"
                #name="ngModel">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Numer kontaktowy" [(ngModel)]="model.contactPhone" name="contactPhone"
                #name="ngModel">
            </mat-form-field>


          </div>
          <div class="col-12">
            <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" data="<p></p>"
              [(ngModel)]="model.baseDescription" name="description" #name="ngModel"></ckeditor>
          </div>
          <app-file-dialog class="w-100 mt-3" title="Logo firmy (Preferowany rozmiar 280x280)" [image]="image"
            (fileNameChanged)="fileNameChanged($event)" (newImageAdded)="imageAdded($event)">
          </app-file-dialog>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
