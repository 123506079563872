import { QuestionResult } from "./question-result";

export class PollResult {
  id: number;
  conferenceId: number;
  title: string;
  description: string;
  start: Date;
  end: Date;
  questions: QuestionResult[] = [];
  pollState: string;
}