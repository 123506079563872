<div class="col-12">
    <button type="button" mat-raised-button class="w-25 mt-2" (click)="delete()" color="warn">Usuń dzień</button>
</div>

<form class="w-100 mt-3" (ngSubmit)="onSubmit()" #b2bForm="ngForm">

    <div class="row">

        <div class="col-6">
            <mat-form-field class="w-100">
                <input matInput required type="time" placeholder="Godzina Od" [(ngModel)]="model.from" name="from"
                    #name="ngModel">
            </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field class="w-100">
                <input matInput required type="time" placeholder="Godzina Do" [(ngModel)]="model.to" name="to"
                    #name="ngModel">
            </mat-form-field>
        </div>

        <div class="col-12">
            <button mat-raised-button class="ml-1 w-100" [disabled]="!b2bForm.form.valid" color="primary">Dodaj
                godzinę do wszystkich stoisk</button>
        </div>
    </div>
</form>

<div class="col-12">
    <mat-form-field class="col-12 w-100">
        <input matInput placeholder="Symbol" required type="text" 
        [(ngModel)]="newMeetingStand.symbol" #name="ngModel"
            name="stands">
    </mat-form-field>
    <div class="d-flex justify-content-center">
        <button mat-raised-button type="button" (click)="addNewStand()" class="w-25 " color="primary">Dodaj
            Stoisko</button>
    </div>
</div>

<app-b2b-meeting-stand class="w-100" [meetingStand]="stand" *ngFor="let stand of meetingArea.meetingStands"
    (deletedMeetingStand)="RefreshMeetingStands($event)" (addedTimeInterval)="RefreshTimeIntervals($event)">
</app-b2b-meeting-stand>

