import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meetingStatus'
})
export class MeetingStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'pending': {
        return 'Oczekujące';
      }
      case 'accepted': {
        return 'Zaakceptowane';
      }
      case 'completed': {
        return 'Zakończone';
      }
      case 'rejected': {
        return 'Anulowane';
      }
      default: {
        return '';
      }
    }
  }

}
