<div class="row">
  <div class="col-2">
    <h1><strong>Uczestnicy</strong></h1>
  </div>
  <div class="col-10">
    <div class="float-right">
      <button [disabled]='this.selection.selected.length != 1' class="ml-1" mat-raised-button color="accent"
        (click)="hide()">
        Ukryj
      </button>
      <button [disabled]='this.selection.selected.length != 1' class="ml-1" mat-raised-button color="primary"
        (click)="unhide()">
        Odkryj
      </button>
      <button [disabled]='this.selection.selected.length != 1' class="ml-1" mat-raised-button color="warn"
        (click)="delete()">
        Usuń
      </button>
    </div>

  </div>
</div>

<mat-form-field class="w-100">
  <mat-label>Filtr</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
</mat-form-field>
<div class="mat-elevation-z8">

  <table mat-table matSort class="w-100" [dataSource]="dataSource">
    <caption></caption>

    <ng-container matColumnDef="select">
      <th scope="col" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Imię i nazwisko</th>
      <td mat-cell *matCellDef="let element"> {{element.name + ' ' + element?.surname}} </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Stanowisko</th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <ng-container matColumnDef="gender">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Płeć</th>
      <td mat-cell *matCellDef="let element"> {{element.gender }} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Firma</th>
      <td mat-cell *matCellDef="let element"> {{element?.company}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="acceptsInvitations">
      <th scope="col" mat-header-cell *matHeaderCellDef>Widoczność B2B</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.acceptsInvitations">visibility</mat-icon>
        <mat-icon *ngIf="element.acceptsInvitations == false">visibility_off</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator></mat-paginator>
</div>
