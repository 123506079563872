import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { Meeting } from '@conference/b2b/_models/meeting';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { MeetingStand } from '../_models/meeting-stand';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  private currentConference: CurrentConference = new CurrentConference();
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Meetings';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<Meeting[]>(this.apiUrl + this.endPoint + '/GetMeetingsByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<Meeting>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  acceptProposal(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/AcceptProposal/' + id, null, { headers: this.headers })
  }

  rejectProposal(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/RejectProposal/' + id, null, { headers: this.headers })
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/DeleteMeeting/' + id, { headers: this.headers })
  }

  getMeetingStand(timeIntervalId: number) {
    return this.http.get<MeetingStand>(this.apiUrl + this.endPoint + '/GetMeetingIdByTimeIntervalId/' + timeIntervalId, { headers: this.headers });
  }



  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
