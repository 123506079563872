import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  private apiUrl: string = `${environment.apiUrl}`;

  private endPoint: string = '/resources';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getInstruction() {
    return this.http.get(this.apiUrl + this.endPoint + '/GetInstruction', { headers: this.headers, responseType: 'blob' });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) {
  }
}
