import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeetingArea } from '../_models/meeting-area';
import { MeetingStand } from '../_models/meeting-stand';
import { TimeInterval } from '../_models/time-interval';
import { MeetingAreaService } from '../_services/meeting-area.service';
import { TimeIntervalService } from '../_services/time-interval.service';

@Component({
  selector: 'app-b2b-define-hours',
  templateUrl: './b2b-define-hours.component.html',
  styleUrls: ['./b2b-define-hours.component.css']
})
export class B2BDefineHoursComponent implements OnInit {
  @Output() timeIntervalAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() meetingStandAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() meetingStandDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() elementDeleted: EventEmitter<Number> = new EventEmitter<Number>();
  @Input() meetingArea: MeetingArea;

  newMeetingStand: MeetingStand = new MeetingStand();
  model: TimeInterval = new TimeInterval();

  constructor(private toastr: ToastrService,
    private timeIntervalService: TimeIntervalService,
    private meetingAreaService: MeetingAreaService) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    for (let index = 0; index < this.meetingArea.meetingStands.length; index++) {
      if (this.meetingArea.meetingStands[index].timeIntervals == null)
        this.meetingArea.meetingStands[index].timeIntervals = [];
      this.model.meetingStandId = this.meetingArea.meetingStands[index].id;
      this.timeIntervalService.add(this.model).subscribe(result => {
        this.toastr.success("Dodano pomyślnie interwał czasowy!");
        this.timeIntervalService.getTimeIntervals(this.meetingArea.meetingStands[index].id).subscribe(result => {
          this.meetingArea.meetingStands[index].timeIntervals = result;
        })
      });
    }
    this.model = new TimeInterval();
  }

  delete() {
    this.elementDeleted.emit(this.meetingArea.id);
  }

  addAdditonalMeetingStand() {
    const max = this.meetingArea.meetingStands.reduce((prev, current) => (prev.symbol > current.symbol) ? prev : current)

    const newStand: MeetingStand = this.copyElement(max);

    this.meetingArea.meetingStands.push(newStand);
  }

  copyElement(elementToCopyFrom: MeetingStand) {
    const newStand: MeetingStand = new MeetingStand();
    newStand.symbol = (+elementToCopyFrom.symbol + 1) + "";
    newStand.meetingAreaId = elementToCopyFrom.meetingAreaId;
    newStand.timeIntervals = [];
    elementToCopyFrom.timeIntervals.forEach(interval => {
      const newTimeInterval: TimeInterval = new TimeInterval();
      newTimeInterval.meetingStandId = interval.meetingStandId;
      newTimeInterval.timeIntervalStatus = 'empty';
      newTimeInterval.from = interval.from;
      newTimeInterval.to = interval.to;
      newStand.timeIntervals.push(newTimeInterval);
    });
    return newStand;
  }

  addNewStand() {
    this.newMeetingStand.meetingAreaId = this.meetingArea.id
    this.meetingAreaService.addMeetingStand(this.newMeetingStand).subscribe(result => {
      this.meetingStandAdded.emit(this.newMeetingStand);
      this.toastr.success("Udało się dodać stoisko!");
    })
  }

  RefreshTimeIntervals($event) {
    for (let index = 0; index < this.meetingArea.meetingStands.length; index++) {
      if (this.meetingArea.meetingStands[index].timeIntervals == null)
      {
        this.meetingArea.meetingStands[index].timeIntervals = [];
      }
      this.model.meetingStandId = this.meetingArea.meetingStands[index].id;
      this.timeIntervalService.getTimeIntervals(this.meetingArea.meetingStands[index].id).subscribe(result => {
        this.meetingArea.meetingStands[index].timeIntervals = result;
      })
    }
  }

  RefreshMeetingStands($event){
    this.meetingStandDeleted.emit($event);
  }
}
