import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possibleAnswerType'
})
export class PossibleAnswerTypePipe implements PipeTransform {

  transform(value: string, args: string): string {
    switch (value) {
      case 'text': {
        return 'Tekst';
      }
      case 'choice': {
        return 'Wybór';
      }
      default: {
        return '';
      }
    }
  }
}
