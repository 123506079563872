import { Component, OnInit } from '@angular/core';
import { Stand } from '../_models/stand';
import { Router, ActivatedRoute } from '@angular/router';
import { StandsService } from '../_services/stands.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { Company } from '@conference/companies/_models/company';
import { CompanyService } from '@conference/companies/_services/company.service';
import { ToastrService } from 'ngx-toastr';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';

@Component({
  selector: 'app-stands-edit',
  templateUrl: './stands-edit.component.html',
  styleUrls: ['./stands-edit.component.css']
})
export class StandsEditComponent implements OnInit {

  public model: Stand = new Stand();
  currentConference: CurrentConference = new CurrentConference();
  companies: Company[];
  public Editor = ClassicEditor;
  page:any;
  pageSize:any;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };

  ngOnInit(): void {

    this.currentConferenceHelperService.currentConference.subscribe(result => {
      this.currentConference = result;
      this.companyService.getAll().subscribe(result => {
        this.companies = result;
      }, error => {
        this.toastr.error( error)
      });
    })

    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.standsService.get(id).subscribe(result => {
      this.model = result
    }, error => {
      this.toastr.error("nie udało się pobrać elementu do edycji!")
      this.router.navigate(['conference/stands-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  onCompanyChanged(event: any) {
    this.model.companyId = event.value.Id;
    const company: Company = event.value;
    if (company?.companyConference?.description?.length > 0) {
      this.model.description = company?.companyConference?.description;
    }
    else {
      this.model.description = company.baseDescription;
    }
    this.model.name = company?.companyConference?.sponsorType.name;
  }

  onSubmit() {
    this.model.conferenceId = this.currentConference.id;
    this.standsService.edit(this.model).subscribe(result => {
      this.toastr.success("Pomyślnie zaktualizowano stoisko!");
      this.router.navigate(['conference/stands-list'],{state:{page:this.page,pageSize:this.pageSize}});
    }, error => {
      this.toastr.error("Nie udało się dodać stoiska!")
    });
  }

  filterCompanies(val: string) {
    if (val) {
      const filterValue = val.toLowerCase();
      return this.companies.filter(state => state.name.toLowerCase().search(filterValue));
    }
    return this.companies;
  }

  constructor(private standsService: StandsService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private router: Router,
    private currentConferenceHelperService: CurrentConferenceHelperService,
    private route: ActivatedRoute) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
