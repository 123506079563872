export class Comment {
    id: number;
    debateId: number;
    value: string;
    userId: number;
    userName: number;
    date: Date;
    commentStateName: string;
    isAnonymous: Boolean;
}
