<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="element-form">

  <div class="row">
    <div class="col-6">
      <h1><strong>Edycja profilu firmy</strong></h1>
    </div>
    <div class="col-6 add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/companies-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Zapisz</button>
      </div>

    </div>
  </div>

  <mat-card class="mb-3">
    <mat-card-content>
      <div class="row">

        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Sponsor</mat-label>
            <mat-select required [(ngModel)]="model.sponsorTypeId" #name="ngModel" name="project">
              <mat-option *ngFor="let sponsor of sponsors" [value]="sponsor.id">
                {{sponsor.name}} {{sponsor.priority}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Strona WWW" [(ngModel)]="model.pageLink" name="pageLink" #name="ngModel">
          </mat-form-field>

          <mat-form-field class="w-100">
            <input matInput type="email" placeholder="Email" [formControl]="email" [(ngModel)]="model.email"
              name="email">
            <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <input type="tel" matInput placeholder="Telefon" [(ngModel)]="model.phone" name="phone" #name="ngModel">
          </mat-form-field>

          <mat-form-field class="w-100">
            <input type="tel" matInput placeholder="Adres" [(ngModel)]="model.address" name="address" #name="ngModel">
          </mat-form-field>

          <mat-form-field class="w-100">
            <input matInput placeholder="Fax" [(ngModel)]="model.fax" name="fax" #name="ngModel">
          </mat-form-field>

          <mat-form-field class="w-100">
            <input matInput placeholder="Link WWW Materiały" [(ngModel)]="model.materialPath" name="linkWithMaterials"
              #name="ngModel">
          </mat-form-field>



        </div>
        <div class="col-6">

          <div class="w-100">
            <mat-label>Opis</mat-label>
            <ckeditor class="w-100" [editor]="Editor" data="<p></p>" [(ngModel)]="model.description" name="description"
              #name="ngModel"></ckeditor>
          </div>

          <div class="w-100">
            <mat-label>Oferta</mat-label>
            <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" data="<p></p>"
              [(ngModel)]="model.offer" name="offer" #name="ngModel"></ckeditor>
          </div>
        </div>

      </div>

    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="selectedCompany!=null">
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-sm-6 row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
              <input matInput placeholder="Nazwa" disabled [value]="selectedCompany.name">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Symbol" disabled [value]="selectedCompany.symbol">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="NIP" disabled [value]="selectedCompany.nip">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="NIP" disabled [value]="selectedCompany.country">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Kod Pocztowy" disabled [value]="selectedCompany.postalCode">
            </mat-form-field>


          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
              <input matInput placeholder="Miasto" disabled [value]="selectedCompany.city">
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="Ulica" disabled [value]="selectedCompany.street">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Numer domu" disabled [value]="selectedCompany.houseNumber">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Numer kontaktowy" disabled [value]="selectedCompany.contactPhone">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput type="email" placeholder="Email kontaktowy" disabled
                [value]="selectedCompany.contactEmail">
            </mat-form-field>

          </div>

          <div class="col-12 d-flex justify-content-center">
            <img *ngIf="selectedCompany.logo!=null" [src]=resourceUrl+selectedCompany.logo>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="w-100">
            <ckeditor class="w-100" [editor]="Editor" [data]="selectedCompany.baseDescription"
              [(ngModel)]="selectedCompany.baseDescription" name="baseDescription" disabled="true" #name="ngModel">
            </ckeditor>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
