import { Component, OnInit } from '@angular/core';
import { VersionMessage } from '@core/_models/version-message';
import { ImageService } from '@core/_services/image.service';
import { VersionService } from '@core/_services/version.service';
import { InformationService } from './_services/information.service';
import { environment } from '@env';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {
  panelVersion:string = environment.appVersion;;
  versionMessage: VersionMessage = new VersionMessage();
  image:File;
  constructor(private versionService: VersionService, private infromationService: InformationService,
    private imageService:ImageService) {}

  ngOnInit(): void {
    this.versionService.get().subscribe(result => {
      this.versionMessage = result;
    })
  }

  DownloadInstruction()
  {
    this.infromationService.getInstruction().subscribe(result => {
        
        let url = window.URL.createObjectURL(result as Blob);
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("style", "display: none");
        link.href = url;
        link.download =  "instrukcja.pdf";
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    })   
  }
}
