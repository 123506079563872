<form (ngSubmit)="onSubmit()" #pollsform="ngForm" class="element-form">
  <div class="row">
    <div class="col-6">
      <h1><strong>Dodawanie ankiety</strong></h1>
    </div>
    <div class="col-6">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/polls-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!pollsform.form.valid"
          color="primary">Dodaj</button>
      </div>
    </div>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-md-4">
          <mat-form-field class="w-100">
            <input matInput placeholder="Tytuł" required [(ngModel)]="model.title" name="title" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="datetime-local" matInput placeholder="Godzina" required [(ngModel)]="model.start" name="start"
              #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="datetime-local" matInput placeholder="Godzina" required [(ngModel)]="model.end" name="end"
              #name="ngModel">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-8">
          <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" data="<p></p>"
            [(ngModel)]="model.description" name="description" #name="ngModel"></ckeditor>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
