<div class="border p-3 mt-3">
    <div class="row mb-2">
        <div class="col-2 mb-2">
            <div [ngSwitch]="comment.commentStateName">
                <strong>{{comment.userName}} </strong> <em>{{comment.date | date:'dd-MM-yyyy HH:mm' }}</em>
            </div>
        </div>

        <div class="col-10" >
            <div class="float-right">
                <button mat-mini-fab color="primary" [ngClass]="{'bg-success': comment.commentStateName == 'accepted', 
                'bg-secondary': comment.commentStateName != 'accepted'}" type="button" class="mr-1" (click)="accept()">
                    <mat-icon>done</mat-icon>
                </button>
                <button mat-mini-fab color="warn" type="button" class="mr-1" (click)="delete()">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

        </div>

    </div>
    <div class="row">
        <div class="col-12">
            <mat-checkbox class="mr-2" [disabled]="comment.isAnonymous" [(ngModel)]="comment.isAnonymous" name="isAnonymous" #name="ngModel">Anonimowo
            </mat-checkbox>
            <mat-form-field class="w-100">
                <textarea class="comment-description" matInput placeholder="Opis" required [(ngModel)]="comment.value"
                    name="value" #name="ngModel"></textarea>
            </mat-form-field>
        </div>
    </div>
</div>