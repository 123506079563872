import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { VersionMessage } from '../_models/version-message';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private apiUrl: string = `${environment.versionUrl}`;
  private endPoint: string = '/Status/Version';

  get() {
    return this.http.get<VersionMessage>(this.apiUrl + this.endPoint);
  }

  constructor(private http: HttpClient) {}
}
