<form (ngSubmit)="onSubmit()" #b2b="ngForm" class="element-form">
  <div class="row">
    <div class="col-6">
      <h1><strong>Grafik B2B</strong></h1>
    </div>
    <div class="col-6">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/b2b-list"
          [state]={page:this.page,pageSize:this.pageSize}>Powrót</button>
      </div>

    </div>
  </div>
  <mat-card class="w-100">
    <mat-card-content>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="col-6 w-100">
            <input type="date" matInput placeholder="Data" required [(ngModel)]="model.date" name="start"
              #name="ngModel">
          </mat-form-field>

          <mat-form-field class="col-6 w-100">
            <input matInput placeholder="Ilość stoisk" min="0" max="100" maxlength="3" required type="number"
              name="stands" [(ngModel)]="model.numberOfStands" (change)="checkNumberOfStands()" #name="ngModel">
          </mat-form-field>
          <div class="d-flex justify-content-center">
            <button mat-raised-button type="submit" class="w-25 " [disabled]="!b2b.form.valid"
              color="primary">Dodaj</button>
          </div>
        </div>
        <div class="col-12">
          <mat-tab-group mat-stretch-tabs class="mt-3 w-100">
            <mat-tab [label]="(b2b.date | date: 'dd.MM.yyyy')+ ' ('+b2b.numberOfStands+') '"
              *ngFor="let b2b of models;">

              <div class="col-12">
                <app-b2b-define-hours (elementDeleted)="delete($event)" *ngIf="b2b" [meetingArea]="b2b"
                  (timeIntervalAdded)="Refresh()" (meetingStandDeleted)="RefreshAfterDelete($event)"
                  (meetingStandAdded)="Refresh()">
                </app-b2b-define-hours>
              </div>

            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
