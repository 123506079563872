<div class="row">
  <div class="col-6">
    <h1>
      <strong>{{ conference.name }}</strong
      ><br />
    </h1>
    <strong
      ><em style="color: gray">{{ conference.symbol }}</em></strong
    >
  </div>
  <div class="col-6">
    <div class="float-right">
      <button mat-raised-button (click)="save()" class="ml-1" color="primary">
        Zapisz
      </button>
    </div>
  </div>
</div>

<mat-card class="row">
  <mat-card-content>
    <div class="row">
      <button
        mat-raised-button
        (click)="openWall()"
        class="w-100 m-1"
        color="primary"
      >
        Wall
      </button>
    </div>

    <div class="row">
      <button
        mat-raised-button
        (click)="clearWall()"
        class="w-100 m-1 mb-3"
        color="warn"
      >
        Wyczyść Wall
      </button>
    </div>

    <div class="row" *ngIf="conference.showParticipants; else showUsers">
      <button
        mat-raised-button
        (click)="changeUserDisplay()"
        class="w-100 m-1 mb-3"
        color="warn"
      >
        Ukryj uczestników
      </button>
    </div>
    <ng-template #showUsers
      ><button
        mat-raised-button
        (click)="changeUserDisplay()"
        class="w-100 m-1 mb-3"
        color="primary"
      >
        Pokaż uczestników
      </button></ng-template
    >
<div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field class="w-100">
         <input
            matInput
            placeholder="Link stream-a"
            #name="ngModel"
            name="liveStreamUrl"
            [(ngModel)]="conference.liveStreamUrl"
          />
        </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <button
        mat-raised-button
        (click)="changeLiveStreamUrl()"
        class="w-100 m-1 mb-3"
        color="primary"
      >
        Zapisz
      </button>
          </div>
          </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field class="w-100">
          <input
            matInput
            placeholder="Lokalizacja"
            #name="ngModel"
            name="location"
            [(ngModel)]="conference.location"
            readonly
          />
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Data Rozpoczęcia</mat-label>
          <input
            matInput
            readonly
            [matDatepicker]="pickerStart"
            [(ngModel)]="conference.startDateOfConference"
            name="startDateOfConference"
            #name="ngModel"
          />
          <mat-datepicker-toggle
            disabled
            matSuffix
            [for]="pickerStart"
          ></mat-datepicker-toggle>
          <mat-datepicker disabled #pickerStart></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Data Zakończenia</mat-label>
          <input
            matInput
            readonly
            [matDatepicker]="pickerEnd"
            [(ngModel)]="conference.endDateOfConference"
            name="endDateOfConference"
            #name="ngModel"
          />
          <mat-datepicker-toggle
            disabled
            matSuffix
            [for]="pickerEnd"
          ></mat-datepicker-toggle>
          <mat-datepicker disabled #pickerEnd></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field class="w-100">
          <textarea
            class="description-field"
            style="min-height: 133px"
            matInput
            placeholder="Opis"
            #name="ngModel"
            name="description"
            [(ngModel)]="conference.description"
            readonly
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 w-100">
        <h1 class="w-100 text-center"><em>Program Konferencji</em></h1>
        <app-program-element
          class="m-2"
          [element]="element"
          *ngFor="let element of conference?.elements"
        ></app-program-element>
      </div>
    </div>

    <div class="row">
      <app-file-dialog
        MaxFileSizeKB="4000"
        class="w-100"
        title="Plan stoisk"
        [image]="image"
        (newImageAdded)="imageAdded($event)"
        (errorOccured)="imageAddError($event)"
        (fileNameChanged)="fileNameChanged($event)"
        (imageDeleted)="delete()"
      ></app-file-dialog>
    </div>
  </mat-card-content>
</mat-card>
