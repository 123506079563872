import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '../_models/question';
import { PossibleAnswer } from '../_models/possible-answer';
import { QuestionService } from '../_services/question.service';
import { PossibleAnswerService } from '../_services/possible-answer.service'

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  @Input('question') question: Question;
  @Output('questionDeleted') questionDeleted: EventEmitter<any> = new EventEmitter();

  public newAnswer: PossibleAnswer = new PossibleAnswer();
  public answers: PossibleAnswer[] = [];

  answerTypes: any[] = [
    { id: 1, name: 'Text', displayName: 'Tekstowa' },
    { id: 2, name: 'Choice', displayName: 'Wybór' }
  ];

  constructor(private questionService: QuestionService,
    private possibleAnswerService: PossibleAnswerService) { }

  ngOnInit(): void {
    this.newAnswer.questionId = this.question.id;
    this.newAnswer.posibleAnswerType = 'Choice';
    this.possibleAnswerService.getByQuestionId(this.question.id).subscribe(result => {
      this.answers = result;
    })
  }

  addAnswer() {

    this.possibleAnswerService.add(this.newAnswer).subscribe(result => {
      this.possibleAnswerService.getByQuestionId(this.question.id).subscribe(result => {
        this.answers = result;
        this.newAnswer = new PossibleAnswer();
        this.newAnswer.posibleAnswerType = 'choice';
        this.newAnswer.questionId = this.question.id;
      })
    })
  }


  deleteQuestion() {
    this.questionService.delete(this.question.id).subscribe(result => {
      this.questionDeleted.emit(this.question);
    });
  }

  deleteAnswer($event) {
    this.answers = this.answers.filter(obj => obj !== $event);
  }
}
