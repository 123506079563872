import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentConferenceHelperService } from '../_services/current-conference-helper.service';


@Injectable({
  providedIn: 'root'
})
export class ConferenceGuard implements CanActivate {

  private currentConference: any;
  constructor(
    private router: Router,
    private authenticationService: CurrentConferenceHelperService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.authenticationService.currentConference.subscribe(result => {
      this.currentConference = result;
    });
    //Conference selected
    if (this.currentConference) {
      return true;
    }
    //Conference not selected
    this.router.navigate(['management/conference']);
    return false;

  }


}
