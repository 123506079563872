import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { Element } from '@conference/conference-info/_models/element';
import { ConferenceService } from '@conference/conference-info/_services/conference.service';
import { Comment } from '@conference/debates/_models/comment';
import { DebatesService } from '@conference/debates/_services/debates.service';
import { ToastrService } from 'ngx-toastr';
import { Debate } from '../_models/debate';
import { DebateSignalService } from '../../wall/_services/debate-signal.service';
import { interval, Subscription } from 'rxjs';



@Component({
  selector: 'app-debates-edit',
  templateUrl: './debates-edit.component.html',
  styleUrls: ['./debates-edit.component.css']
})
export class DebatesEditComponent implements OnInit {

  public model: Debate = new Debate();
  public programElements: Element[];
  public Editor = ClassicEditor;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };
  page:any;
  pageSize:any;

  private numberOfComments = 0;
  subscription:Subscription;

  ngOnInit(): void {
    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.debatesService.get(id).subscribe(result => {
      result.comments.forEach(element => {
        element.debateId = result.id;
      });
      this.model = result;
      this.debateSignalService.startConnection();

      this.debateSignalService.addNewCommentDataListener().on("CommentAdded" + this.model.id, result => {
        this.model.comments.unshift(result);
        this.numberOfComments++;
      });

      this.subscription = interval(5000).subscribe(val => {
        if(this.numberOfComments>0){
          this.toastr.info("Dodano "+this.numberOfComments+" nowych komentarz!");
        }
        this.numberOfComments = 0;
      });

    }, error => {
      this.router.navigate(['management/company-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });

    this.conferenceService.getProgramElements().subscribe(result => {
      this.programElements = result.elements;
    });


  }

  deleteComment($event) {
    this.model.comments.splice(this.model.comments.indexOf($event), 1);
  }

  onSubmit() {
    this.model.debateStateName = 'new';
    this.debatesService.editDebate(this.model).subscribe(result => {
      this.router.navigate(['conference/debates-list'],{state:{page:this.page,pageSize:this.pageSize}});
    })
  }

  commentAdded(event: Comment) {
    this.model.comments.push(event);
  }

  constructor(private debatesService: DebatesService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private conferenceService: ConferenceService,
    private debateSignalService: DebateSignalService) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
