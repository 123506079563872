<div class="page-container">
    <div class="container p-5 ">
        <div class="m-3 text-center">
            <span class="debate-header">{{model.title}}</span>
        </div>
        <ng-container *ngFor="let comment of model.comments | slice:0:4;; let i = index">

            <mat-card class="m-3">
                <mat-card-content>
                    <span class="description"> {{comment.value}} </span>
                </mat-card-content>
            </mat-card>
            <div class="row header-title" [ngClass]="{'mr-4': i % 2 == 0, 'ml-4': i%2==1}"
                [attr.align]="i % 2 == 0 ? 'end' : 'start'">
                <div class="col-12" *ngIf="comment.isAnonymous">
                    <span class="description">{{comment.date | date:'HH:mm'}} Anonim</span>
                </div>
                <div class="col-12" *ngIf="!comment.isAnonymous">
                    <span class="description">{{comment.date | date:'HH:mm'}} {{comment.userName}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>