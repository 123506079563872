import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUserService } from '../_services/app-user.service';
import { AppUser } from '../_models/app-user';
import { OldUser } from '../_models/old-user';
import {FormControl, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ImageOperationHelperService } from '@core/_services/image-operation-helper.service';
import { Company } from '@management/comapnies/_models/company';
import { CompaniesService } from '@management/comapnies/_services/companies.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  model: AppUser = new AppUser();
  oldUsers: OldUser[] = [];
  oldUsersFormControl = new FormControl();
  filteredOldUsers: Observable<OldUser[]>;
  fileName:string;
  image:any;
  imageWasUpdated:Boolean = false;
  companies: Company[] = [];
  companiesFormControl = new FormControl();
  filteredCompanies: Observable<Company[]>;
  email = new FormControl('', [Validators.required, Validators.email]);
  page:any;
  pageSize:any;

  ngOnInit(): void {


    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.appUserService.get(id).subscribe(result => {

      this.model = result;
      this.companiesService.getAll().subscribe(result=>{
        this.companies = result;
        this.filteredCompanies = this.companiesFormControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterCompanies(value))
          );
      });

      this.appUserService.getAllOldUsers().subscribe(result=>{
        this.oldUsers = result;
        if(this.model.oldSystemUserId > 0){
          this.appUserService.getOldUser(this.model.oldSystemUserId).subscribe(result=>{
            this.oldUsers.push(result);
            if(this.oldUsers.find(user=> user.id == this.model.oldSystemUserId)){
              const oldUser = this.oldUsers.filter(user=> user.id == this.model.oldSystemUserId)[0];
              this.model.oldSystemUserEmail = oldUser?.email;
            }
          })
        }
        if(this.oldUsers.find(user=> user.id == this.model.oldSystemUserId)){
          const oldUser = this.oldUsers.filter(user=> user.id == this.model.oldSystemUserId)[0];
          this.model.oldSystemUserEmail = oldUser?.email;
        }



        this.filteredOldUsers = this.oldUsersFormControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      });

      this.appUserService.getProfileImage(this.model.id).subscribe(async result=>{
          this.model.userProfileIcon = this.imageOperationHelperService._arrayBufferToBase64(await result.arrayBuffer());
          this.image = this.imageOperationHelperService._arrayBufferToBase64(await result.arrayBuffer());
        })

    }, error => {
      this.router.navigate(['management/user-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });

  }

  private _filterCompanies(value: string): Company[] {
    const filterValue = value.toLowerCase();

    return this.companies.filter(option => option.name.concat(" ").concat(option.nip).concat(" ")
            .concat(option.contactEmail).toLowerCase().includes(filterValue)
    );
  }



  private _filter(value: string): OldUser[] {
    const filterValue = value.toLowerCase();

    return this.oldUsers.filter(option => option.name.concat(" ").concat(option.surname).concat(" ")
            .concat(option.email).toLowerCase().includes(filterValue)
    );
  }


  onSubmit() {
    if(this.oldUsersFormControl.value === ""){
      this.model.oldSystemUserId = 0;
    }
    else{
      var oldUser = this.oldUsers.filter(user=>user.email == this.model.oldSystemUserEmail)[0];
      if(oldUser){
        this.model.oldSystemUserId = oldUser.id;
      }
    }

    if(this.companiesFormControl.value === ""){
      this.model.companyId = null;
    }
    else{
      var company = this.companies.filter(company=>company.name == this.model.companyName)[0];
      if(company){
        this.model.companyId = company.id;
      }
    }


    this.appUserService.edit(this.model).subscribe(result => {
      if(this.imageWasUpdated){
        this.appUserService.setProfileImage(this.model.id, this.image).subscribe(result=>{
          console.log("Aktualizuję zdjęcie!");
          this.toastr.success("Pomyślnie zaktualizowano użytkownika!")
          this.router.navigate(['management/user-list'],{state:{page:this.page,pageSize:this.pageSize}});
        });
      }
      else{
        this.toastr.success("Pomyślnie zaktualizowano użytkownika!")
        this.router.navigate(['management/user-list'],{state:{page:this.page,pageSize:this.pageSize}});
      }

    });
  }

  imageAdded($event) {
    this.image = $event;
    this.imageWasUpdated = true;
  }

  fileNameChanged($event) {
    this.fileName = $event;
  }

  delete() {
    this.image = null;
    this.imageWasUpdated = true;
  }

  errorOccured($title){
    console.log("error catched!");
    this.toastr.error("Przekroczono rozmiar pliku : " +  $title  + "MB, Maximum to 150KB");
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Wprowadź wartość';
    }

    return this.email.hasError('email') ? 'Błędny email' : '';
  }

  confirmEmail() {
    this.appUserService.confirmEmail(this.model.id).subscribe(result => {
      this.model.emailConfirmed = true;
      this.toastr.success("Pomyślnie potwierdzono użytkownika");
    }, error => {
      this.toastr.error("Nie udało się potwierdzić użytkownika")
    }
    );
  }

  constructor(private appUserService: AppUserService,
    private router: Router,
    private companiesService:CompaniesService,
    private imageOperationHelperService: ImageOperationHelperService,
    private toastr:ToastrService,
    private route: ActivatedRoute) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
      }

}
