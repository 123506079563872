<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar class="row" color="primary">
        <div class="col d-flex flex-row align-items-center">
            <button mat-icon-button (click)="snav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <h1 class="d-none d-sm-block">{{currentConference.name}}</h1>
        </div>
        <div class="col d-flex flex-row-reverse">
            <button mat-raised-button routerLinkActive="active-link" (click)="logout()">Wyloguj</button>
            <div class="mr-2 d-none d-sm-block">
                <h1>Witaj, {{currentUser.firstName}} {{currentUser.lastName}}! </h1>
            </div>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
            fixedTopGap="40">
            <mat-nav-list class="menu">
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/info" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>info</mat-icon> Konferencja
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/participants-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>person</mat-icon> Uczestnicy
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/companies-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>apartment</mat-icon>Profil Firmy
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/stands-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>contacts</mat-icon>Stoiska
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/debates-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>comment</mat-icon>Debaty
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/polls-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>question_answer</mat-icon>Ankiety
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/b2b-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>meeting_room</mat-icon>B2B
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/notifications-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>message</mat-icon>Powiadomienia
                </a>
                <a mat-list-item routerLinkActive="active-link" routerLink="/conference/relations-list" [disabled]="!accessHelperService.hasConferenceAccess()">
                    <mat-icon>camera_alt</mat-icon>Relacje
                </a>

                <a mat-list-item routerLinkActive="active-link" (click)="cancelConference()">
                    <mat-icon>cancel</mat-icon>Opuść Konferencję
                </a>
            </mat-nav-list>
            <div class="fixed-bottom logo-menu">
                <div class="row d-none d-lg-block">
                    <img alt="logo" src="../../assets/Logo.png" />
                </div>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>