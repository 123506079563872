import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserLoginComponent } from '@shared/user-login/user-login.component'
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { A11yModule } from '@angular/cdk/a11y';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@material/material.module';
import { ToastrModule } from 'ngx-toastr';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FileDialogComponent } from './file-dialog/file-dialog.component';

@NgModule({
  exports: [
    UserLoginComponent,
    ConfirmDialogComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileDialogComponent,
    BrowserAnimationsModule,
    ScrollingModule,
    HttpClientModule,
    A11yModule,
    CKEditorModule,
    RouterModule,
    ToastrModule
  ],
  declarations: [
    UserLoginComponent,
    ConfirmDialogComponent,
    FileDialogComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ScrollingModule,
    HttpClientModule,
    A11yModule,
    RouterModule,
    MaterialModule,
    CKEditorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000
    })
  ]
})
export class SharedModule { }
