import { Question } from "./question";

export class Poll {
    id: number;
    conferenceId:number;
    title: string;
    description: string;
    start: Date;
    end: Date;
    pollState: string;
    questions: Question[] = [];
}
