<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="w-100">
  <div class="row">
    <div class="col-6">
      <h1><strong>Edytowanie debaty</strong></h1>

    </div>
    <div class="col-6 add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/debates-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Zapisz</button>
      </div>

    </div>
  </div>
  <div class="row">
    <mat-card class="col-12">
      <mat-card-content>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <input matInput placeholder="Tytuł" required [(ngModel)]="model.title" name="title" #name="ngModel">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-label>Element Programu</mat-label>
              <mat-select [(ngModel)]="model.programElementId" #name="ngModel" name="programElementId">
                <mat-option *ngFor="let element of programElements" [value]="element.id">
                  <div [innerHTML]="element.name"></div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <input type="datetime-local" matInput placeholder="Data Rozpoczęcia" required [(ngModel)]="model.start"
                name="start" #name="ngModel">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <input type="datetime-local" matInput placeholder="Data Zakończenia" required [(ngModel)]="model.end"
                name="end" #name="ngModel">
            </mat-form-field>
          </div>
          <div class="col-12">
            <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" data="<p></p>"
              [(ngModel)]="model.description" name="description" #name="ngModel"></ckeditor>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="col-12 mt-5">
      <h1><strong>Komentarze</strong></h1>
    </div>
    <mat-card class="col-12 ">
      <mat-card-content>
        <app-comment *ngFor="let comment of model.comments" (deleted)="deleteComment($event)" [comment]="comment">
        </app-comment>
      </mat-card-content>
    </mat-card>
  </div>
</form>
