export class Company {
    id: number;
    name: string
    symbol: string;
    baseDescription: string;
    nip: string;
    country: string;
    postalCode: string;
    city: string;
    street: string;
    houseNumber: string;
    contactEmail: string;
    contactPhone: string;
    logo: string;
    logoFile: string;
    logoFileName: string;
    isActive: Boolean;
}