import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { PollResult } from '../_models/poll-result';
import { QuestionResult } from '../_models/question-result';
import { PollResultService } from '../_services/poll-result.service';
import { PollsSignalService } from '../_services/polls-signal.service';

@Component({
  selector: 'app-polls-details',
  templateUrl: './polls-details.component.html',
  styleUrls: ['./polls-details.component.css']
})
export class PollsDetailsComponent implements AfterViewInit, OnInit {

  @Input() conferenceId:number;
  private _id;
  @Input()
  get id(): number { return this._id; }
  set id(id: number) {
    this._id = id;
    this.ngOnInit();
  }

  @ViewChild('tabGroup') tabGroup;
  
  actualResult:QuestionResult = new QuestionResult();
  model: PollResult = new PollResult();
  selectedIndex: number = 0;
  timer: any;
  newData: Boolean = false;
  currentConference: CurrentConference = new CurrentConference();


  ngOnInit(): void {

    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });

    this.pollResultService.getResults(this.id).subscribe(result => {

      this.model = result;
      this.model.questions = this.model.questions.sort(d => d.id);

      if(result.questions.length > 0){
        this.actualResult = result.questions[0];
        this.selectedIndex = 0;
      }

      this.pollsSignalService.startConnection();

      this.pollsSignalService
      .addNewCommentDataListener()
      .on("ReceivePoll" + this.model.id, result => {        
        console.log("Get new Info!");
        this.model.questions = result.questions.sort(d => d.id);
        if(this.model.questions.length == 1){
          this.actualResult = this.model.questions[0];
        }
      });
    });   
  }

  ngAfterViewInit() {
    this.timer = setInterval(() => {
      if (this.selectedIndex >= this.model.questions.length - 1) {
        this.selectedIndex = 0;
        this.actualResult = this.model.questions[this.selectedIndex];
      }
      else {
        this.selectedIndex++;
        this.actualResult = this.model.questions[this.selectedIndex];
      }
    }, 5000)

  }

  constructor(private pollResultService: PollResultService,
    private pollsSignalService: PollsSignalService,
    private currentConferenceHelperService:CurrentConferenceHelperService) { }

}
