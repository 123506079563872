import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Stand } from '../_models/stand';
import { StandsService } from '../_services/stands.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CompanyService } from '@conference/companies/_services/company.service';
import { Company } from '@conference/companies/_models/company';
import { ToastrService } from 'ngx-toastr';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-stands-add',
  templateUrl: './stands-add.component.html',
  styleUrls: ['./stands-add.component.css']
})
export class StandsAddComponent implements OnInit {

  model: Stand = new Stand();
  currentConference: CurrentConference = new CurrentConference();
  companies: Company[];
  Editor = ClassicEditor;
  page:any;
  pageSize:any;
  companyControl = new FormControl();
  filteredCompanies: Observable<Company[]>;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };

  ngOnInit(): void {
    this.companyService.getAll().subscribe(result => {
      this.companies = result;
      this.setFilter();
    }, error => {
      console.log(error);
      this.toastr.error("Nie udało się pobrać firm")
    });
    this.currentConferenceHelperService.currentConference.subscribe(result => {
      this.currentConference = result;
    })
  }

  onSubmit() {
    if (this.model.companyName != '') {
      const company = this.companies.filter(company => company.name == this.model.companyName)[0];
      this.model.companyId = company.id;
    }
    this.model.conferenceId = this.currentConference.id;
    this.standsService.add(this.model).subscribe(result => {
      this.toastr.success("Pomyślnie dodano, dodaj kolejne stoisko!")
      this.model = new Stand();
      this.setFilter();
      this.model.description=null;
    });

  }

  private _filter(value: string): Company[] {
    const filterValue = value.toLowerCase();

    return this.companies.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onCompanyChanged(event: any) {
    this.model.companyId = event.value.Id;
    const company: Company = event.value;
    if (company?.companyConference?.description?.length > 0) {
      this.model.description = company?.companyConference?.description;

    }
    else {
      this.model.description = company.baseDescription;
    }
    this.model.name = company?.companyConference?.sponsorType.name;
  }

  setFilter() {
    this.filteredCompanies = this.companyControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  };

  constructor(private standsService: StandsService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private currentConferenceHelperService: CurrentConferenceHelperService,
    private router: Router) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }

}
