import { NumberSymbol } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Participant } from '@conference/participants/_models/participant';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';

@Injectable({
  providedIn: 'root'
})
export class ParticipantsService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Participants';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();
  currentConference: CurrentConference;

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x })
    return this.http.get<Participant[]>(this.apiUrl + this.endPoint + '/GetParticipantsByConference/' + this.currentConference.id, { headers: this.headers });
  }

  hide(id:number){
    return this.http.post(this.apiUrl + this.endPoint + '/changeParticipantVisibility', {oldUserSystemId:id, conferenceId: this.currentConference.id, isVisible: false} , { headers: this.headers });

  }

  unhide(id:number){
    return this.http.post(this.apiUrl + this.endPoint + '/changeParticipantVisibility', {oldUserSystemId:id, conferenceId: this.currentConference.id, isVisible: true } , { headers: this.headers });

  }

  delete(id:number){
    return this.http.delete(this.apiUrl + this.endPoint + '/RemoveB2BAccess/' + id + '/' + this.currentConference.id, { headers: this.headers });

  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) {
  }

}
