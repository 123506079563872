import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';
import { Debate } from '@conference/debates/_models/debate'
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';

@Injectable({
  providedIn: 'root'
})
export class DebatesService {

  private currentConference: CurrentConference = new CurrentConference();
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Debate';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  addDebate(debate: Debate) {
    return this.http.post(this.apiUrl + this.endPoint, debate, { headers: this.headers });
  }

  editDebate(debate: Debate) {
    return this.http.put(this.apiUrl + this.endPoint, debate, { headers: this.headers });
  }

  deleteDebate(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<Debate>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  getAll() {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<Debate[]>(this.apiUrl + this.endPoint + '/GetByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  Open(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/Open/' + id, null, { headers: this.headers });
  }

  Close(id: number) {
    return this.http.put(this.apiUrl + '/debate/Close/' + id, null, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }

}
