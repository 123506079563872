import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from '@conference/notifications/_services/notifications.service';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { BaseTable } from '@shared/_base/base-table';
import { ToastrService } from 'ngx-toastr';
import { GroupedNotification } from '../_models/grouped-notification';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.css']
})
export class NotificationsListComponent extends BaseTable implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'name', 'date', 'notificationType'];
  dataSource = new MatTableDataSource<GroupedNotification>();
  selection = new SelectionModel<GroupedNotification>(true, []);

  ngOnInit(): void {
    this.notificationsService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: GroupedNotification, filter: string) => {
        return (data.title != null && data.title.trim().toLowerCase().indexOf(filter) !== -1)
      }
      this.setPage();
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {

      if(property == "name"){
        item[property] = item["title"];
      }else if(property =="date"){
        item[property] = item["dontSendBefore"];
      }else if(property == "notificationType" && item[property]!=null && item[property]!=undefined){
        property="notificationTypeTemp";
        item[property] = item["notificationType"]["name"];
      }
      if(item[property] == null || item[property] == undefined || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
  };
  }

  details() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/notifications-details/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }


  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.notificationsService.delete(element.id).subscribe(result => {
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
            this.toastr.success("Pomyślnie usunięto powiadomienie!");
          });
        }
        this.selection.clear();
      }
    });
  }

  constructor(private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }

}
