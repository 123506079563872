import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TimeInterval } from '../_models/time-interval';
import { MeetingService } from '../_services/meeting.service';
import { TimeIntervalService } from '../_services/time-interval.service';

@Component({
  selector: 'app-b2b-hour',
  templateUrl: './b2b-hour.component.html',
  styleUrls: ['./b2b-hour.component.css']
})
export class B2bHourComponent implements OnInit {

  from: string;
  to: string;
  _timeInterval: TimeInterval;

  get timeInterval(): TimeInterval {
    return this._timeInterval;
  }
  @Input() set timeInterval(value: TimeInterval) {
    this._timeInterval = value;
    this.from = ("0" + (new Date(this._timeInterval.from)).getHours()).slice(-2) + ':' + ("0" + (new Date(this._timeInterval.from)).getMinutes()).slice(-2);
    this.to = ("0" + (new Date(this._timeInterval.to)).getHours()).slice(-2) + ':' + ("0" + (new Date(this._timeInterval.to)).getMinutes()).slice(-2);
  }
  @Output() timeIntervalDeleted: EventEmitter<TimeInterval> = new EventEmitter<TimeInterval>();

  constructor(private toastr: ToastrService,
    private router: Router,
    private timeIntervalService: TimeIntervalService,
    private meetingService: MeetingService) { }

  ngOnInit(): void {
  }

  block() {
    const old = this.timeInterval.timeIntervalStatus;
    this.timeInterval.timeIntervalStatus = 'suspended';
    this.timeIntervalService.updateStatus(this.timeInterval).subscribe(result => {
      this.toastr.success("Udało się zaktualizowac slot!");
    }, error => {
      this.timeInterval.timeIntervalStatus = old;
    });
  }

  unblock() {
    const old = this.timeInterval.timeIntervalStatus;
    this.timeInterval.timeIntervalStatus = 'empty';
    this.timeIntervalService.updateStatus(this.timeInterval).subscribe(result => {
      this.toastr.success("Udało się zaktualizowac slot!");
    }, error => {
      this.timeInterval.timeIntervalStatus = old;
    });
  }

  delete() {
    this.timeIntervalService.delete(this.timeInterval.id).subscribe(result => {
      this.timeIntervalDeleted.emit(this.timeInterval);
    }
    );
  }

  saveTimeInterval() {
    console.log(this.timeInterval);

    const date1 = new Date(this.timeInterval.from);
    const date2 = new Date(this.timeInterval.to);

    this.timeInterval.from = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    this.timeInterval.to = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    this.timeInterval.from.setHours(this.getHour(this.from) - (date1.getTimezoneOffset() / 60));
    this.timeInterval.from.setMinutes(this.getMinutes(this.from));
    this.timeInterval.to.setHours(this.getHour(this.to) - (date2.getTimezoneOffset() / 60));
    this.timeInterval.to.setMinutes(this.getMinutes(this.to));



    this.timeIntervalService.updateTime(this.timeInterval).subscribe(result => {
      this.toastr.success("Udało się zaktualizowac datę!");
    });
  }

  getHour(value: string) {
    return parseInt(value.slice(0, 2));
  }

  getMinutes(value: string) {
    return parseInt(value.slice(3, 5));
  }


  timeIntervalInfo() {
    this.meetingService.getMeetingStand(this.timeInterval.id).subscribe(result => {
      if (result?.id > 0) {
        this.router.navigate(['conference/b2b-edit/' + result.id]);
      }
      else {
        this.toastr.error("Nie udało się pobrac identyfikatora dla tego stanowiska!");
      }
    });
  }

  
}
