<div class="row">
  <div class="col-2">
    <h1><strong>B2B</strong></h1>

  </div>
  <div class="col-10">
    <div class="float-right">
      <button routerLink="/conference/b2b-define" [state]="{page: paginator.pageIndex,pageSize: paginator.pageSize}"
        mat-raised-button color="primary">
        Grafik
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="accent"
        (click)="edit()">
        Edytuj
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="warn"
        (click)="delete()">
        Usuń
      </button>
      <button [disabled]="selection.selected.length != 1" class="ml-1" mat-raised-button color="warn"
        (click)="details()">
        Szczegóły
      </button>
    </div>
  </div>
</div>

<mat-form-field class="w-100">
  <mat-label>Filtr</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Wyszukaj..." #input>
</mat-form-field>
<div class="mat-elevation-z8">

  <table class="w-100" mat-table matSort [dataSource]="dataSource" matSortActive="date" matSortDirection="desc">
    <caption></caption>
    <ng-container matColumnDef="select">
      <th scope="col" scope="col" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th scope="col" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]=production>Id</th>
      <td mat-cell *matCellDef="let element" [hidden]=production> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="invitingPerson">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Zaprosił(a) </th>
      <td mat-cell *matCellDef="let element"> {{element?.creationPerson.firstName + ' '+
                element?.creationPerson.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="invitingPersonCompany">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Zaprosił(a) Firma</th>
      <td mat-cell *matCellDef="let element"> {{element?.creationPerson.company}}</td>
    </ng-container>

    <ng-container matColumnDef="invitedPerson">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Adresat</th>
      <td mat-cell *matCellDef="let element"> {{element?.targetPerson.firstName + ' ' +
                element?.targetPerson.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="invitedPersonCompany">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Adresat Firma</th>
      <td mat-cell *matCellDef="let element">{{element?.targetPerson.company}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let element"> {{element?.creationDate | date:'dd-MM-yyyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="meetingPlace">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Miejsce</th>
      <td mat-cell *matCellDef="let element"> {{element?.meetingPlace}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element"> {{element?.meetingStatus | meetingStatus}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="10" showFirstLastButtons #paginator></mat-paginator>
</div>
