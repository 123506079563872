import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PossibleAnswer } from '../_models/possible-answer';
import { PossibleAnswerService } from '../_services/possible-answer.service';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.css']
})
export class AnswerComponent{

  @Input('answer') answer: PossibleAnswer = new PossibleAnswer();
  @Output('answerDeleted') answerDeleted: EventEmitter<any> = new EventEmitter<any>();

  constructor(private posibleAnswerService: PossibleAnswerService) { }

  deleteAnswer() {
    this.posibleAnswerService.delete(this.answer.id).subscribe(result => {});
    this.answerDeleted.emit(this.answer);
  }
}
