import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module'
import { CompanyAddComponent } from '@management/comapnies/company-add/company-add.component'
import { CompanyEditComponent } from '@management/comapnies/company-edit/company-edit.component'
import { CompanyListComponent } from '@management/comapnies/company-list/company-list.component'
import { ConferenceListComponent } from '@management/conferences/conference-list/conference-list.component'
import { UserListComponent } from '@management/users/user-list/user-list.component'
import { UserDetailComponent } from '@management/users/user-detail/user-detail.component'
import { MaterialModule } from '@material/material.module';
import { RegistrationFormsListComponent } from './registration-forms/registration-forms-list/registration-forms-list.component';
import { RegistrationFormsDetailsComponent } from './registration-forms/registration-forms-details/registration-forms-details.component';
import { ManagementComponent } from './management.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { ConferenceConnectComponent } from './conferences/conference-connect/conference-connect.component';
import { ConferenceConnectEditComponent } from './conferences/conference-connect-edit/conference-connect-edit.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { InformationComponent } from './information/information.component';

@NgModule({
  declarations: [
    CompanyAddComponent,
    CompanyEditComponent,
    CompanyListComponent,
    ConferenceListComponent,
    UserListComponent,
    UserDetailComponent,
    RegistrationFormsListComponent,
    RegistrationFormsDetailsComponent,
    ManagementComponent,
    UserEditComponent,
    ConferenceConnectComponent,
    ConferenceConnectEditComponent,
    UserAddComponent,
    InformationComponent
  ],
  exports: [
    CompanyAddComponent,
    CompanyEditComponent,
    CompanyListComponent,
    ConferenceListComponent,
    UserListComponent,
    UserDetailComponent
  ],
  imports: [
    SharedModule,
    MaterialModule
  ]
})
export class ManagementModule { }
