<form (ngSubmit)="onSubmit()" #standsForm="ngForm" class="stands-form">
  <div class="row">
    <div class="col-6">
      <h1><strong>Dodawanie Stoiska</strong></h1>
    </div>
    <div class="col-6">
      <div class="float-right">
        <button mat-stroked-button color="primary" routerLink="/conference/stands-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!standsForm.form.valid"
          color="primary">Dodaj</button>
      </div>

    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <input type="text" placeholder="Firma" aria-label="Company" matInput [(ngModel)]="model.companyName"
              [formControl]="companyControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let company of filteredCompanies | async | slice : 0 : 10" [value]="company.name">
                {{company.name}} | {{company.symbol}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Numer stoiska" (keydown.enter)="onSubmit()" required [(ngModel)]="model.symbol"
              name="symbol" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Nazwa stoiska" (keydown.enter)="onSubmit()" [(ngModel)]="model.name"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <ckeditor class="w-100" [config]="htmlEditorConfig" [editor]="Editor" data="<p></p>" onsubmit=""
            [(ngModel)]="model.description" name="description" #name="ngModel"></ckeditor>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
