import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { Element } from '@conference/conference-info/_models/element';
import { ConferenceService } from '@conference/conference-info/_services/conference.service';
import { DebatesService } from '@conference/debates/_services/debates.service';
import { ToastrService } from 'ngx-toastr';
import { Debate } from '../_models/debate';


@Component({
  selector: 'app-debates-add',
  templateUrl: './debates-add.component.html',
  styleUrls: ['./debates-add.component.css']
})
export class DebatesAddComponent implements OnInit {

  public model: Debate = new Debate();
  public programElements: Element[];
  public Editor = ClassicEditor;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };
  page:any;
  pageSize:any;

  ngOnInit(): void {
    this.conferenceService.getProgramElements().subscribe(result => {
      this.programElements = result.elements;
    });
  }

  onSubmit() {
    this.model.debateStateName = 'new';
    this.debatesService.addDebate(this.model).subscribe(result => {
      this.toastr.success("Dodano pomyślnie debatę!");
      this.router.navigate(['conference/debates-list'],{state:{page:this.page,pageSize:this.pageSize}});
    })
  }

  constructor(private debatesService: DebatesService,
    private toastr: ToastrService,
    private router: Router,
    private conferenceService: ConferenceService) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
