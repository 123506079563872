import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@core/_services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ManagementAccessGuard implements CanActivate {
  private privilegeName:string = 'Panel Admin Zarządzanie'

  constructor(
    private router: Router,
    private authenticationService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      const currentUser = this.authenticationService.currentUserValue;      
      if (currentUser.authenticationPrivileges.find(d=> d.name==this.privilegeName) != null) {
        return true;
      }
      else if (currentUser.isFullAdmin) {
        return true
      }
      else {
        this.router.navigate(['conference/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
  }
  
}
