import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessage } from '../_models/error-message';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const message = error.error as ErrorMessage;
          switch (error.status) {
            case 400: {
              if (message?.messages != null) {
                this.showErrorMessages(message);
              }
              else {
                this.toastr.error("Podane żądanie jest nie prawidłowe!")
              }
              break;
            }
            case 401: {
              this.toastr.error("Wystąpił problem z uwierzytelniem. Zaloguj się ponownie!");
              // this.router.navigate(['authentication/login']);
              break;
            }
            case 403: {
              this.toastr.error("Nie posiadasz uprawnień!");
              break;
            }
            case 404: {
              if (message?.messages != null) {
                this.showErrorMessages(message);
              }
              else {
                this.toastr.error("Brak metody!")
              }
              break;
            }
            case 500: {
              if (message?.messages != null) {
                this.showErrorMessages(message);
              }
              else {
                this.toastr.error("Wystąpił błąd Serwera!")
              }
              break;
            }
            default: {
              this.toastr.error('Wystąpił nieznany błąd!');
              break;
            }
          };
          return throwError(error);
        })
      )
  }

  showErrorMessages(message: ErrorMessage) {
    message.messages.forEach(element => {
      this.toastr.error(element);
    });
  }
  constructor(private toastr: ToastrService
    ,private router: Router) { }
}
