import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'K': {
        return 'Kobieta';
      }
      case 'M': {
        return 'Mężczyzna';
      }
      default: {
        return 'brak';
      }
    }
  }

}
