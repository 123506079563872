import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GroupedNotification } from '../_models/grouped-notification';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { NotificationsService } from '../_services/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AppUser } from '@management/users/_models/app-user';
import { AppUserService } from '@management/users/_services/app-user.service';
import { BaseTable } from '@shared/_base/base-table';
import { CurrentConference } from '@shared/_models/current-conference';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { Console } from 'console';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';


@Component({
  selector: 'app-notifications-add',
  templateUrl: './notifications-add.component.html',
  styleUrls: ['./notifications-add.component.css']
})
export class NotificationsAddComponent extends BaseTable implements OnInit {

  currentConference: CurrentConference = new CurrentConference();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'firstName', 'lastName', 'userName', 'email', 'personConnection'];
  dataSource = new MatTableDataSource<AppUser>();
  currentTime:string;
  page:any;
  pageSize:any;
  selection = new SelectionModel<AppUser>(true, []);
  model: GroupedNotification = new GroupedNotification();
  Editor = ClassicEditor;
  notificationTypes: any[] = [
    { id: 1, name: 'sms', displayName: 'SMS' },
    { id: 2, name: 'push', displayName: 'Push' },
    { id: 3, name: 'email', displayName: 'Email' }
  ];
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };

  private allSelected:Boolean = false;


  ngOnInit(): void {

    this.currentConferenceHelperService.currentConference.subscribe(x => {
      this.currentConference = x;

      this.appUserService.getUsersByConferenceId(this.currentConference.id).subscribe(result => {
        this.dataSource.data = result;
      })
      this.model.description = this.currentConference.name;
      this.cdr.detectChanges();

    });
  }

  selectAllElements(){
    this.dataSource.data.forEach(element => {
      this.selection.select(element);
    });
  }


  onSubmit() {
    const minutesInHour = 60;
    const secondsInMinute = 60;
    const msInSecond = 1000;

    var time = this.currentTime.split(':'); //00:00
    const hour = time[0];
    const minutes = time[1];
    this.model.dontSendBefore.setHours(Number.parseInt(hour));
    this.model.dontSendBefore.setMinutes(Number.parseInt(minutes));
    var timeZoneDifference =  Math.abs(this.model.dontSendBefore.getTimezoneOffset() / minutesInHour);
    this.model.dontSendBefore.setTime(this.model.dontSendBefore.getTime() + (timeZoneDifference * minutesInHour * secondsInMinute * msInSecond));
    this.model.toUserIds = this.selection.selected.map(({ id }) => id);
    this.notificationsService.add(this.model).subscribe(() => {
      this.model.senderId = 1;
      this.toastrService.success("Udało się pomyślnie dodać powiadomienie!");
      this.router.navigate(['conference/notifications-list'],{state:{page:this.page,pageSize:this.pageSize}});
    })
  }


  selectAll(){
    if(this.allSelected){
      this.selection.clear();
      this.allSelected = false;
    }
    else{
      this.selectAllElements();
      this.allSelected = true;
    }
  }

  constructor(private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    protected toastrService: ToastrService,
    private router: Router,
    private appUserService: AppUserService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { super()
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }
}
