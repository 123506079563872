export class WallSetter {
    conferenceId: number;
    wallType: string;
    referenceObjectId: number;

    constructor(conferenceId: number, wallType: string, referenceObjectId: number) {
        this.conferenceId = conferenceId;
        this.wallType = wallType;
        this.referenceObjectId = referenceObjectId;
    }
}