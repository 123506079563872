import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Comment } from '@conference/debates/_models/comment';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { CommentService } from '../_services/comment.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent {

  @Input() comment: Comment;
  @Output() deleted: EventEmitter<any> = new EventEmitter();

  accept() {

    this.save().subscribe(result => {
      if(this.comment.commentStateName == 'accepted'){
        this.reject();
      }
      else{
        this.comment.commentStateName = 'accepted';
        this.commentService.acceptComment(this.comment.id).subscribe(result => {
          this.toastr.success("Pomyślnie zatwierdzono komentarz!");
        });
      }
    });
  }

  reject() {
    this.save().subscribe(result => {
      this.comment.commentStateName = "rejected";
      this.commentService.rejectComment(this.comment.id).subscribe(result => {
        this.toastr.success("Pomyślnie odrzucono komentarz!");
      });
    });
  }

  save() {
    return this.commentService.editComment(this.comment);
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć komentarz?').subscribe(result => {
      if (result) {
        this.commentService.deleteComment(this.comment.id).subscribe(result => {
          this.toastr.success("Pomyślnie usunięto komentarz!");
          this.deleted.emit(this.comment);
        });
      }
    });
  }

  constructor(private commentService: CommentService,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService) { }
}
