import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MeetingArea } from '@conference/b2b/_models/meeting-area'
import { ToastrService } from 'ngx-toastr';
import { MeetingStand } from '../_models/meeting-stand';
import { MeetingAreaService } from '@conference/b2b/_services/meeting-area.service'
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';

@Component({
  selector: 'app-b2b-define',
  templateUrl: './b2b-define.component.html',
  styleUrls: ['./b2b-define.component.css']
})
export class B2bDefineComponent implements OnInit {

  models: MeetingArea[] = [];
  modelsToUpdate: MeetingArea[];
  model: MeetingArea = new MeetingArea();
  page:any;
  pageSize:any;

  constructor(private toastr: ToastrService,
    private meetingAreaService: MeetingAreaService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

  onSubmit() {
    this.checkNumberOfStands();
    this.addMeetingArea();
  }

  checkNumberOfStands()
  {
    if (this.model.numberOfStands.valueOf()>100) this.model.numberOfStands=100;
    if (this.model.numberOfStands.valueOf()<0) this.model.numberOfStands=0;
  }

  addMeetingArea() {
    this.meetingAreaService.add(this.model).subscribe(result => {
      const currentDate = this.model.date;
      this.createMeetingStandsForMeetingArea(currentDate);
    });
  }

  createMeetingStandsForMeetingArea(addedDate: Date) {
    this.meetingAreaService.getAll().subscribe(result => {
      this.models = result;
      this.models.forEach(element => {
        element.numberOfStands = element.meetingStands.length;
      });
      this.modelsToUpdate = this.models;

      const newMeetingArea = this.getRecentlyAddedMeetingArea(addedDate);
      newMeetingArea.numberOfStands = this.model.numberOfStands;
      for (let index = 0; index < this.model.numberOfStands; index++) {
        const element = this.model.numberOfStands;
        const meetingStand = new MeetingStand();
        meetingStand.symbol = (index + 1).toString();

        meetingStand.meetingAreaId = newMeetingArea.id;
        this.meetingAreaService.addMeetingStand(meetingStand).subscribe(result => {
          const newMeetingArea = this.getRecentlyAddedMeetingArea(addedDate);
          newMeetingArea.meetingStands.push(meetingStand);
          this.toastr.success("Pomyślnie dodano stanowisko!");
          if (index==this.model.numberOfStands-1) this.ngOnInit();
        });
      };

    });
  }

  getRecentlyAddedMeetingArea(addedDate: Date) {
    return this.modelsToUpdate.filter(d => {
      var d1 = new Date(d.date);
      var d2 = new Date(addedDate);
      return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
    }
    )[0];
  }


  ngOnInit(): void {
    this.meetingAreaService.getAll().subscribe(result => {
      result.forEach(element => {
        element.numberOfStands = element.meetingStands.length;
      });
      this.models = result.sort((a, b) => { return a.date < b.date ? -1 : 1 });
      this.models.forEach(d => {
        d.meetingStands = d.meetingStands.sort((a, b) => a.symbol > b.symbol ? 1 : -1);
      })
    });
  }


  delete($event) {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        const element = this.models.find(e => e.id == $event);
        this.meetingAreaService.delete(element.id).subscribe(result => {
          var ind = this.models.indexOf(element);
          this.models.splice(ind, 1);
        });
      }
    });
  }

  Refresh() {
    this.ngOnInit();
  }

  RefreshAfterDelete($event) {
    console.log($event);
    this.models.forEach(element => {
      const index = element.meetingStands.findIndex(m => m.id == $event.id)
      if (index != -1) {
        this.models.splice(index, 1);
        this.ngOnInit();
      }
    });
  }


}
