import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DebateSignalService } from '@conference/wall/_services/debate-signal.service';
import { Debate } from '@conference/debates/_models/debate';
import { DebatesService } from '@conference/debates/_services/debates.service';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';


@Component({
  selector: 'app-debates-details',
  templateUrl: './debates-details.component.html',
  styleUrls: ['./debates-details.component.css']
})
export class DebatesDetailsComponent implements OnInit {

  @Input() conferenceId: number;
  
  private _id;
  @Input()
  get id(): number { return this._id; }
  set id(id: number) {
    this._id = id;
    this.ngOnInit();
  }

  public model: Debate = new Debate();
  public programElements: Element[];
  public currentConference: CurrentConference = new CurrentConference();  

  ngOnInit(): void {
    this.currentConferenceHelperService.currentConference.subscribe(result => {
      this.currentConference = result;
    })

    this.debatesService.get(this.id).subscribe(result => {

      result.comments = result.comments.sort((a, b) => (a.date <= b.date) ? 1 : -1).filter(x => x.commentStateName == 'accepted')
      this.model = result;
      
      this.debateSignalService.startConnection();

      this.debateSignalService
      .addNewCommentDataListener()
      .on("ReceiveDebateAfterAcceptedComment" + this.model.id, result => {
        result.comments = result.comments.sort((a, b) => (a.date <= b.date) ? 1 : -1).filter(x => x.commentStateName == 'accepted')
        this.model = result;
      });

      this.debateSignalService.addNewCommentDataListener().on("CommentRejected" + this.model.id, result => {
        this.model.comments.forEach( (item, index) => {
          if(item.id === result.id) this.model.comments.splice(index,1);
        });
      });

    });
  }

  constructor(private debatesService: DebatesService,
    private debateSignalService: DebateSignalService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
