<form (ngSubmit)="onSubmit()" #companyForm="ngForm" class="company-form" autocomplete="off">
  <div class="row">
    <div class="col">
      <h1><strong>Edytowanie Użytkownika</strong></h1>
    </div>
    <div class="col add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/management/user-list"
          [state]={page:this.page,pageSize:this.pageSize}>Anuluj</button>
        <button mat-raised-button type="submit" class="ml-1" [disabled]="!companyForm.form.valid"
          color="primary">Zapisz</button>
      </div>

    </div>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field class="w-100">
            <input matInput placeholder="Imię" required [(ngModel)]="model.firstName" name="firstName" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Nazwisko" required [(ngModel)]="model.lastName" name="lastName"
              #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Email" readonly required [(ngModel)]="model.email" name="email"
              #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input required type="text" placeholder="Firma" matInput autocomplete="off" [(ngModel)]="model.companyName"
              [formControl]="companiesFormControl" [matAutocomplete]="companies">
            <mat-autocomplete autocomplete="off" #companies="matAutocomplete">
              <mat-option *ngFor="let company of filteredCompanies | async | slice : 0 : 10" [value]="company.name"
                matTooltip="{{company.name}} | {{company.nip}} | {{company.symbol}}">
                {{company.name}} | {{company.nip}} | {{company.symbol}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Telefon" [(ngModel)]="model.phoneNumber" name="phoneNumber" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Stanowisko" [(ngModel)]="model.companyPosition" name="companyPosition"
              #name="ngModel">
          </mat-form-field>

          <mat-form-field class="w-100">
            <textarea matInput matTextareaAutosize [maxLength]="1000"
              placeholder="Opis ({{((model.description!=null)?model.description.length:0)}}/1000)"
              [(ngModel)]="model.description" name="description" #name="ngModel"></textarea>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="text" placeholder="Użytkownik w startm systemie" aria-label="User" matInput
              [(ngModel)]="model.oldSystemUserEmail" [formControl]="oldUsersFormControl" [matAutocomplete]="users">
            <mat-autocomplete #users="matAutocomplete">
              <mat-option *ngFor="let oldUser of filteredOldUsers | async | slice : 0 : 10" [value]="oldUser.email">
                {{oldUser.name}} {{oldUser.surname}} {{oldUser.email }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="datetime-local" matInput placeholder="Data Rejestracji" readonly
              [(ngModel)]="model.registerDate" name="registerDate" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="datetime-local" matInput placeholder="Aktywność w aplikacji" readonly
              [(ngModel)]="model.lastLoginAttempt" name="lastLoginAttempt" #name="ngModel">
          </mat-form-field>

          <div class="confirmed-email">
            Potwierdzony email:
            <mat-icon style="color:green;text-align:center;" *ngIf="model.emailConfirmed">done</mat-icon>
            <mat-icon style="color:red;text-align:center;" *ngIf="!model.emailConfirmed">clear</mat-icon>
            <button class="ml-1" type="button" [disabled]="model.emailConfirmed" mat-raised-button color="primary"
              (click)="confirmEmail()">
              Potwierdź Email
            </button>
          </div>

        </div>
        <div class="col-12 col-lg-8">
          <div class="justify-content-center mt-2">
            <app-file-dialog title="Profil Użytkownika" class="w-100 m-1" [image]="image"
              (newImageAdded)="imageAdded($event)" (fileNameChanged)="fileNameChanged($event)" MaxFileSizeKB="150"
              (imageDeleted)="delete()" (errorOccured)="errorOccured($event)"></app-file-dialog>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
