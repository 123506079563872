import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss']
})
export class FileDialogComponent implements AfterViewInit {

  currentImage:string | ArrayBuffer = null;
  public file: any;
  @Input('title') title:string = "";
  @Input('fileName') fileName:string = "";
  private _image : string; 
  get image(): string { 
    return this._image;
  }
  @Input('image')
  set image(val: string) {
    this._image = val;
    if(this._image){
      this.currentImage = this._base64ToArrayBuffer(this._image);
    }
  }

  @Input("MaxFileSizeKB") MaxFileSizeKB = 1000;

  @Input("disableAdd") disableAdd = false;
  @Input("disableDelete") disableDelete = false;

  @Output('newImageAdded') newImageAdded = new EventEmitter<any>();
  @Output('imageDeleted') imageDeleted = new EventEmitter<any>();
  @Output('fileNameChanged') fileNameChanged = new EventEmitter<string>();
  @Output('errorOccured') errorOccured = new EventEmitter<string>();

  ngAfterViewInit(){
  }

  readFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      let objectURL = reader.result;
      this.currentImage = objectURL;
      this.newImageAdded.emit(this._arrayBufferToBase64( this.currentImage ));      
    };
    reader.readAsArrayBuffer(new Blob([file]));
  }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

  sanitize( url:string ) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getFirstFile() : File{
      return this.file;
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    const FileSize = files[0].size / 1024; // in MiB
        if (FileSize > this.MaxFileSizeKB) {
            this.errorOccured.emit(FileSize + "");
        } else {
          this.fileName = files[0].name;
          const title = this.fileName;
          this.fileNameChanged.emit(title);
          this.prepareFilesList(files[0]);
          this.readFile(files[0]);
        }
  }

  deleteFile(index: number) {
    this.file = null;
    this.image = null;
    this.imageDeleted.emit(this.file);
  }

  prepareFilesList(file: any) {
    this.file = file;
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  
  constructor(protected sanitizer: DomSanitizer) { }

}
