import { Component, OnInit } from '@angular/core';
import { Company } from '@management/comapnies/_models/company';
import { CompaniesService } from '@management/comapnies/_services/companies.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from '@core/_services/image.service';
import { FormControl, Validators } from '@angular/forms';
import { CountryHelperService } from '@core/_services/country-helper.service'


@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {

  model: Company = new Company();
  image: any;
  page: any;
  pageSize: any;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    }
  };
  Editor = ClassicEditor;
  email = new FormControl('', [Validators.required, Validators.email]);

  ngOnInit(): void {
    const id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.companiesService.get(id).subscribe(company => {
      const image = company.logoFile;
      this.image = image;
      this.model = company;
      this.imageService.getImage(this.model.logo).subscribe(async imageResult => {
        if (imageResult != null) {
          this.model.logoFile = this._arrayBufferToBase64(await imageResult.arrayBuffer());
          this.image = this._arrayBufferToBase64(await imageResult.arrayBuffer());
        }
      })
    }, error => {
      this.router.navigate(['management/company-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  _arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  onSubmit() {
    if(this.email.hasError('email')){
      this.toastr.error("Błędny email!");
      return;
    }
    this.companiesService.edit(this.model).subscribe(result => {
      this.toastr.success("Pomyślnie zaktualizowano firmę!");
      this.router.navigate(['management//company-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  getErrorMessage() {
    return this.email.hasError('email') ? 'Błędny email' : '';
  }

  imageAdded($event) {
    this.image = $event;
    this.model.logoFile = $event;
    this.model.logo = null;
  }

  fileNameChanged($event) {
    this.model.logoFileName = $event;
    this.model.logo = null;
  }

  delete(){
    this.image = null;
    this.model.logoFile = null;
    this.model.logo = null;
  }


  constructor(private companiesService: CompaniesService,
    private router: Router,
    private imageService: ImageService,
    public countryHelperService:CountryHelperService,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }



}
