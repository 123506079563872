import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { Relation } from '../_models/relation';
import { RelationsService } from '../_services/relations.service';
import { ImageService } from '@core/_services/image.service'
import { environment } from '@env';


@Component({
  selector: 'app-relations-details',
  templateUrl: './relations-details.component.html',
  styleUrls: ['./relations-details.component.css']
})
export class RelationsDetailsComponent implements OnInit {

  model: Relation = new Relation();
  currentImage: string;
  public Editor = ClassicEditor;
  page:any;
  pageSize:any;
  private resourceUrl: string = `${environment.resourceUrl}`;

  ngOnInit(): void {
    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.relationsService.get(id).subscribe(result => {
      this.model = result
      if(this.model.photo != null){
        this.currentImage = this.resourceUrl + this.model.photo;
      }
    }, error => {
      this.router.navigate(['conference/relations-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  downloadImage() {
    this.imageService.getImage(this.model.photo).subscribe(result => {
      let url = window.URL.createObjectURL(result as Blob);
      let link = document.createElement("a");
      document.body.appendChild(link);
      link.setAttribute("style", "display: none");
      link.href = url;
      link.download = this.model.photoFileName ?? "image.jpg";
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    });
  }

  constructor(private relationsService: RelationsService,
    private router: Router,
    private route: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    private imageService: ImageService) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
