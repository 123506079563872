import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { environment } from '@env';


@Injectable({
  providedIn: 'root'
})
export class PollsSignalService {

  public data: any[];
  private apiUrl: string = `${environment.versionUrl}`;
  private endPoint: string = '/PollHub';
  private hubConnection: signalR.HubConnection

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.apiUrl + this.endPoint)
      .build();
      
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addNewCommentDataListener = () => {
    return this.hubConnection;
  }

  constructor() { }
}
