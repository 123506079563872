import { Notification } from './notification'

export class GroupedNotification {
    id: number;
    title: string;
    date: Date;
    description: string;
    notifications: Notification[];
    notificationTypeId: number;
    notificationType: string;
    dontSendBefore: Date;
    senderId: number;
    conferenceId: number;
    toUserIds: number[] = [];
}

