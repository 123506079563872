<div class="file-container" (fileDropped)="onFileDropped($event)">
    <div class="col-12">
        <input type="file"  #fileDropRef id="fileDropRef" single (change)="fileBrowseHandler($event.target.files)" />
    </div>
    <div class="col-12">
        <h3>{{title}}</h3><br />
    </div>
    <div class="col-12">
        <h4 class="name">
            <em>{{ file?.name }}</em>
        </h4>
        <p *ngIf="file" class="size">
            {{ formatBytes(file?.size,2) }}
        </p>
        <img alt="Upload Image" *ngIf="image" class="mb-3 image" 
            [src]="sanitize('data:image/jpg;base64, ' + _arrayBufferToBase64(currentImage))" />
    </div>
    <div class="col-12">
        <label for="fileDropRef" *ngIf="!disableAdd" class="w-50" color="primary">Dodaj</label>
    </div>
    <div class="col-12">
        <label (click)="deleteFile(file)" *ngIf="!disableDelete"  class="w-50" style="background-color: #f44336;">Usuń</label>
    </div>

</div>