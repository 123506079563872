import { ViewChild, Directive } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { INITIAL_CONFIG } from '@angular/platform-server';
import { environment } from '@env';

@Directive({})
export class BaseTable {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public displayedColumns;
  public dataSource;
  public selection;
  production:boolean;
  page:any=0;
  pageSize:any=10;

  isAllSelected() {
    const numSelected = this.selection?.selected?.length;
    const numberOfElements = this.dataSource?.filteredData?.length;
    const numRows = this.dataSource?.paginator?.pageSize;
    var pageIndex = this.dataSource?.paginator?.pageIndex;
    if(pageIndex == Math.floor(numberOfElements/numRows)){
      return numSelected >= numberOfElements-(pageIndex*numRows) || parseInt(numberOfElements) <= (parseInt(numSelected) - 1);
    }
    return numSelected >= numRows || parseInt(numberOfElements) <= (parseInt(numSelected) - 1);
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.selectCurrentPage();
  }

  setPage(){
    this.paginator.pageIndex = this.page;
    this.paginator.pageSize = this.pageSize;
    this.dataSource.paginator = this.paginator;
  }

  selectCurrentPage() {
    var numberOfElements = this.dataSource.filteredData.length;
    var pageIndex = this.dataSource.paginator.pageIndex;
    var pageSize = this.dataSource.paginator.pageSize;
    for (let index = pageIndex * pageSize; (index < (pageIndex * pageSize) + pageSize)&&(index < numberOfElements); index++) {
      const element = this.dataSource.sortData(this.dataSource.filteredData,this.dataSource.sort)[index];
      this.selection.select(element)
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  ngAfterViewInit() {

    this.production=environment.production;
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.sortConfig();
    }, 0);
  }



  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      if(item[property] == null || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor( ) {}

}
