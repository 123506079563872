import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageOperationHelperService } from '@core/_services/image-operation-helper.service';
import { ImageService } from '@core/_services/image.service';
import { ToastrService } from 'ngx-toastr';
import { Meeting } from '../_models/meeting';
import { MeetingService } from '../_services/meeting.service';

@Component({
  selector: 'app-b2b-edit',
  templateUrl: './b2b-edit.component.html',
  styleUrls: ['./b2b-edit.component.css']
})
export class B2bEditComponent implements OnInit {

  model: Meeting = new Meeting();
  creationPersonImage: any;
  targetPersonImage: any;
  page:any;
  pageSize:any;


  constructor(private toastr: ToastrService,
    private meetingService: MeetingService,
    private router: Router,
    private route: ActivatedRoute,
    private imageService: ImageService,
    private imageOperationHelperService: ImageOperationHelperService) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

  ngOnInit(): void {
    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.meetingService.get(id).subscribe(result => {
      this.model = result

      this.imageService.getProfileImage(this.model.creationPerson.id).subscribe(async result => {
        this.creationPersonImage = this.imageOperationHelperService._arrayBufferToBase64(await result.arrayBuffer());
      })

      this.imageService.getProfileImage(this.model.targetPerson.id).subscribe(async result => {
        this.targetPersonImage = this.imageOperationHelperService._arrayBufferToBase64(await result.arrayBuffer());
      })

    }, error => {
      this.toastr.error("nie udało się pobrać elementu do edycji!")
      this.router.navigate(['conference/b2b-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }

  onSubmit() {

  }
}
