import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as  ClassicEditor  from 'assets/ckeditor/ckeditor.js';
import { Element } from '@conference/conference-info/_models/element';
import { ConferenceService } from '@conference/conference-info/_services/conference.service';
import { PollsService } from '@conference/polls/_services/polls.service';
import { Poll } from '../_models/poll';

@Component({
  selector: 'app-polls-add',
  templateUrl: './polls-add.component.html',
  styleUrls: ['./polls-add.component.css']
})
export class PollsAddComponent implements OnInit {

  public model: Poll = new Poll();
  public programElements: Element[];
  public Editor = ClassicEditor;
  page:any;
  pageSize:any;
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };

  ngOnInit(): void {
    this.conferenceService.getProgramElements().subscribe(result => {
      this.programElements = result.elements;
    });
  }

  onSubmit() {
    this.debatesService.add(this.model).subscribe(result => {
      this.router.navigate(['conference/polls-list'],{state:{page:this.page,pageSize:this.pageSize}});
    })
  }

  constructor(private debatesService: PollsService,
    private router: Router,
    private conferenceService: ConferenceService) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
