import { Component, OnInit, AfterViewInit , ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AppUserService } from '@management/users/_services/app-user.service';
import { AppUser } from '@management/users/_models/app-user';
import { BaseTable } from '@shared/_base/base-table'
import { Router } from '@angular/router';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends BaseTable implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'name',  'email', 'companyName', 'emailConfirmed', 'personConnection'];
  dataSource = new MatTableDataSource<AppUser>();
  selection = new SelectionModel<AppUser>(true, []);

  ngOnInit(): void {
    this.appUserService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: AppUser, filter: string) =>  !filter ||
      (data.firstName + ' ' + data.lastName).trim().toLowerCase().indexOf(filter) !== -1 ||
      data.email.trim().toLowerCase().indexOf(filter) !== -1 ||
      (data.companyName && data.companyName.trim().toLowerCase().indexOf(filter) !== -1);
      this.setPage();
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      if(property == "name"){
        item[property] = item["firstName"]+" "+item["lastName"];
      }
      if(item[property] == null || item[property] == undefined || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
  };
  }

  edit() {
    if (this.selection.selected.length > 0) {
      var selectedElement = this.selection.selected[0];
      this.router.navigate(['management/user-edit/' + selectedElement.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć użytkowników?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.appUserService.delete(element.id).subscribe(result => {
            this.toastr.success("Pomyślnie usunięto użytkownika!")
          });
        }
        this.selection.clear();
      }
    });
  }

  details() {
    if (this.selection.selected.length > 0) {
      var selectedElement = this.selection.selected[0];
      this.router.navigate(['management/user-detail/' + selectedElement.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  add(){
    this.router.navigate(['management/user-add/'],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
  }

  constructor(private appUserService: AppUserService,
    private router: Router,
    private toastr:ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super( )
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
   }

}
