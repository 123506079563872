<form #companyForm="ngForm" class="company-form">
  <div class="row">
    <div class="col">
      <h1><strong>Szczegóły Relacji</strong></h1>
    </div>
    <div class="col add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/relations-list"
          [state]={page:this.page,pageSize:this.pageSize}>Powrót</button>
      </div>
    </div>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <input matInput placeholder="Tytuł" readonly [(ngModel)]="model.title" name="title" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Imię" readonly [(ngModel)]="model.userName" name="userName" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Nazwisko" readonly [(ngModel)]="model.userLastName" name="userLastName"
              #name="ngModel">
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <ckeditor disabled class="w-100" [editor]="Editor" data="<p></p>" [(ngModel)]="model.description"
            name="description" #name="ngModel"></ckeditor>
        </div>

      </div>



      <div class="col-12">
        <div class="d-flex justify-content-center">
          <img alt="Relation" class="img-fluid rounded mt-2" style="max-height: 700px; max-width: 400px;"
            [src]="currentImage" />
        </div>
      </div>
      <div class="col-12 mt-3">
        <button class="w-100" mat-raised-button color="primary" [disabled]="this.model.photo == null"
          (click)="downloadImage()">Pobierz</button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
