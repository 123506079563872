import { Component, OnInit } from '@angular/core';
import { RegistrationForm } from '@management/registration-forms/_models/registration-form';
import { RegistrationFormsService } from '@management/registration-forms/_services/registration-forms.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration-forms-details',
  templateUrl: './registration-forms-details.component.html',
  styleUrls: ['./registration-forms-details.component.css']
})
export class RegistrationFormsDetailsComponent implements OnInit {

  public model: RegistrationForm = new RegistrationForm();
  page:any;
  pageSize:any;

  ngOnInit(): void {
    var id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.registrationFormsService.get(id).subscribe(result => {
      this.model = result
    }, error => {
      this.router.navigate(['management/registration-forms-list'],{state:{page:this.page,pageSize:this.pageSize}})
    });
  }

  constructor(private registrationFormsService: RegistrationFormsService,
    private router: Router,
    private route: ActivatedRoute) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }


}
