import { Component } from '@angular/core';
import { AuthService } from '@core/_services/auth.service';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { User } from '@shared/_models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public currentUser: User;
  public currentConference: CurrentConference;

  constructor(


    private authenticationService: AuthService,
    private currentConferenceHelperService: CurrentConferenceHelperService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
  }
}
