import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as  ClassicEditor from 'assets/ckeditor/ckeditor.js';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { ToastrService } from 'ngx-toastr';
import { ConferenceDescription } from '../_models/conference-description';
import { Sponsor } from '../_models/sponsor';
import { CompanyService } from '../_services/company.service';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '@env';
import { Company } from '../_models/company';

@Component({
  selector: 'app-companies-edit',
  templateUrl: './companies-edit.component.html',
  styleUrls: ['./companies-edit.component.scss']
})
export class CompaniesEditComponent implements OnInit {
  selectedCompany:Company;
  resourceUrl:string;
  page:any;
  pageSize:any;
  sponsors: Sponsor[] = [];
  files: any[] = [];
  public image: any;
  public model: ConferenceDescription = new ConferenceDescription();
  public companies: Company[] = [];
  public Editor = ClassicEditor;
  email = new FormControl('', [Validators.required, Validators.email]);
  htmlEditorConfig = {
    mediaEmbed: {
      previewsInData: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    }
  };

  constructor(private companiesService: CompanyService,
    private router: Router,
    private toastr: ToastrService,
    protected currentConferenceHelper: CurrentConferenceHelperService,
    private route: ActivatedRoute
  ) {
    if (this.router.getCurrentNavigation().extras.state){
      this.page = this.router.getCurrentNavigation().extras.state.page;
      this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
    }
   }

  ngOnInit(): void {

    this.resourceUrl=environment.resourceUrl;
    const companyId = parseInt(this.route.snapshot.paramMap.get('companyId'));
    const conferenceId = parseInt(this.route.snapshot.paramMap.get('conferenceId'));
    this.companiesService.get(companyId, conferenceId).subscribe(result => {
      this.model = result;
      this.companiesService.getAllCompanies().subscribe(result => {
        result.forEach(element => {
          if(element.id==companyId)
            this.selectedCompany = element;
        });
      });

    }, error => {
      this.router.navigate(['conference/companies-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });

    this.companiesService.getSponsors().subscribe(result => {
      this.sponsors = result;
    })
  }
  getErrorMessage() {
    return this.email.hasError('email') ? 'Błędny email' : '';
  }

  onSubmit() {
    if(this.email.hasError('email')){
      this.toastr.error("Błędny email!");
      return;
    }
    this.companiesService.edit(this.model).subscribe(result => {
      this.toastr.success("Pomyślnie zaktualizowano definicję firmy!")
      this.router.navigate(['conference/companies-list'],{state:{page:this.page,pageSize:this.pageSize}});
    });
  }
}
