import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { TimeInterval } from '../_models/time-interval';

@Injectable({
  providedIn: 'root'
})
export class TimeIntervalService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/MeetingAreas';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  add(timeInterval: TimeInterval) {
    return this.http.post(this.apiUrl + this.endPoint + '/AddTimeInterval', timeInterval, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/RemoveTimeInterval/' + id, { headers: this.headers });
  }

  updateStatus(timeInterval: TimeInterval) {
    return this.http.put(this.apiUrl + this.endPoint + '/UpdateTimeIntervalStatus', timeInterval, { headers: this.headers });
  }

  updateTime(timeInterval: TimeInterval) {
    return this.http.put(this.apiUrl + this.endPoint + '/UpdateTimeInterval', timeInterval, { headers: this.headers });
  }

  getTimeIntervals(meetingStandId: number) {
    return this.http.get<TimeInterval[]>(this.apiUrl + this.endPoint + '/GetTimeIntervalsForMeetingStand/' + meetingStandId, { headers: this.headers });

  }
  
  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) { }
}
