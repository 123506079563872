import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Meeting } from '@conference/b2b/_models/meeting'
import { BaseTable } from '@shared/_base/base-table'
import { MeetingService } from '@conference/b2b/_services/meeting.service'
import { Router } from '@angular/router';
import { MeetingStatusPipe } from '@conference/b2b/_pipes/meeting-status.pipe';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';

@Component({
  selector: 'app-b2b-list',
  templateUrl: './b2b-list.component.html',
  styleUrls: ['./b2b-list.component.css']
})
export class B2bListComponent extends BaseTable implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'id', 'invitingPerson', 'invitingPersonCompany', 'invitedPerson', 'invitedPersonCompany', 'date', 'meetingPlace', 'status'];
  dataSource = new MatTableDataSource<Meeting>();
  selection = new SelectionModel<Meeting>(true, []);
  pipe = new MeetingStatusPipe();

  ngOnInit(): void {
    this.meetingService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Meeting, filter: string) =>  !filter ||
      (data.targetPerson.firstName+' '+data.targetPerson.lastName).trim().toLowerCase().indexOf(filter) !== -1 ||
      (data.creationPerson.firstName+' '+data.creationPerson.lastName).trim().toLowerCase().indexOf(filter) !== -1 ||
      data.targetPerson.company.trim().toLowerCase().indexOf(filter) !== -1 ||
      data.creationPerson.lastName.trim().toLowerCase().indexOf(filter) !== -1
      this.setPage();
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {

      if(property == "invitingPerson"){
        item[property] = item["creationPerson"].firstName+" "+item["creationPerson"].lastName;
      }else if(property =="invitingPersonCompany"){
        item[property] = item["creationPerson"].company;
      }else if(property == "invitedPerson"){
        item[property] = item["targetPerson"].firstName+" "+item["targetPerson"].lastName;
      }else if(property =="invitedPersonCompany"){
        item[property] = item["targetPerson"].company;
      }else if(property == "date"){
        item[property] = item["creationDate"];
      }else if(property =="status"){
        item[property] = this.pipe.transform(item["meetingStatus"]);
      }
      if(item[property] == null || item[property] == undefined || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
  };
  }

  edit() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/b2b-edit/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  delete(){
    if (this.selection.selected.length > 0) {
      this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć spotkanie?').subscribe(result => {
        if (result) {
          const element = this.selection.selected[0];
          this.meetingService.delete(element.id);
        }
      });
    }
  }

  details() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/b2b-details/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }

  }

  constructor(private meetingService: MeetingService,
    private router: Router,
    private confirmDialogService:ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
}
}
