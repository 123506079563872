<mat-card class="mat-elevation-z2">
    <mat-card-content>
        <div class="row">
            <div class="col-12">
                <h1>
                    {{proposal.proposalStatus | proposalStatus}}
                </h1>
            </div>
            <div *ngIf="proposal.stand != null" class="col-12">
                <h3>Stoisko</h3>
                <span>{{proposal.stand.symbol}}</span>
                <span>{{proposal.stand.name}}</span><br />
            </div>
            <div *ngIf="proposal.timeInterval != null" class="col-12">
                <h3>Strefa B2B</h3>
                Data: <span>{{proposal.timeInterval.from | date:'dd/MM/yyyy HH:mm'}} - {{proposal.timeInterval.to |
                    date:'dd/MM/yyyy HH:mm'}}</span><br />
                Stanowisko: <span>{{proposal.timeInterval.standSymbol}}</span>
            </div>
            <div class="col-12">
                Wiadomość: <span>{{proposal.description}}</span>
            </div>
        </div>
        <div class="row" *ngIf="!isDisabled">
            <div class="col-6">
                <button (click)="reject()" [disabled]="proposal.proposalStatus != 'pending'" mat-stroked-button
                    type="button" class=" w-100" color="primary">Odrzuć</button>
            </div>
            <div class="col-6">
                <button (click)="accept()" [disabled]="proposal.proposalStatus != 'pending'" mat-raised-button
                    type="button" class="ml-1 w-100" color="primary">Zaakceptuj</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>