import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@shared/_models/user';
import { AuthService } from '@core/_services/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service'
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { AccessHelperService } from '@core/_services/access-helper.service'


@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.css']
})
export class ConferenceComponent {

  isExpanded = false;
  showFiller = false;
  mobileQuery: MediaQueryList;
  public currentUser: User;
  public currentConference: CurrentConference;
  private _mobileQueryListener: () => void;


  logout() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz się wylogować?').subscribe(result => {
      if (result) {
        this.authenticationService.logout();
        this.router.navigate(['authentication/login']);
      }
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  cancelConference() {
    this.currentConferenceHelperService.clearStorage();
    this.router.navigate(['/']);
  }

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    private currentConferenceHelperService: CurrentConferenceHelperService,
    media: MediaMatcher,
    public accessHelperService: AccessHelperService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    currentConferenceHelperService.currentConference.subscribe(result => {
      this.currentConference = result;
    })
  }
}
