import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  title: string = 'Informacja';
  cancelText: string = 'Anuluj';
  confirmText: string = 'Ok';


  afterClosed(message) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.title,
        message: message,
        cancelText: this.cancelText,
        confirmText: this.confirmText
      }
    });

    return dialogRef.afterClosed();
  }

  constructor(private dialog: MatDialog) { }
}
