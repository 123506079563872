import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUserService } from '../_services/app-user.service';
import { AppUser } from '../_models/app-user';
import {ImageOperationHelperService} from '@core/_services/image-operation-helper.service'
import { UserPoints } from '../_models/user-points';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  model: AppUser = new AppUser();
  currentImage: string;
  oldUsers: any[] = [];
  userPoints:UserPoints = new UserPoints();
  image:any;
  page:any;
  pageSize:any;

  private id: number;
  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.appUserService.get(this.id).subscribe(result => {
      this.model = result
      if (this.model.oldSystemUserId > 0) {
        this.appUserService.getOldUser(this.model.oldSystemUserId).subscribe(result => {
          this.model.oldSystemUserEmail = result.email;
        })
      }

      this.appUserService.getUserPoints(this.model.id).subscribe(result=>{
        this.userPoints = result;
      })

      this.appUserService.getProfileImage(this.model.id).subscribe(async result=>{
        this.model.userProfileIcon = this.imageOperationHelperService._arrayBufferToBase64(await result.arrayBuffer());
        this.image = this.imageOperationHelperService._arrayBufferToBase64(await result.arrayBuffer());
      })

    }, error => {
      this.router.navigate(['management/user-list']);
    });
  }

  edit() {
    if (this.id != null) {
      if(this.page){
        this.router.navigate(['management/user-edit/' + this.id],{state: { page: this.page}});
      }else{
        this.router.navigate(['management/user-edit/' + this.id]);
      }
    }
  }

  constructor(private appUserService: AppUserService,
    private router: Router,
    private route: ActivatedRoute,
    private imageOperationHelperService:ImageOperationHelperService) {
      if (this.router.getCurrentNavigation().extras.state){
        this.page = this.router.getCurrentNavigation().extras.state.page;
        this.pageSize = this.router.getCurrentNavigation().extras.state.pageSize;
      }
      }

}
