import { Injectable } from '@angular/core';
import { CurrentConference } from '@shared/_models/current-conference'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentConferenceHelperService {

  private currentConferenceSubject: BehaviorSubject<CurrentConference>;
  public currentConference: Observable<CurrentConference>;


  public setItem(conference: CurrentConference) {
    localStorage.setItem("currentConference", JSON.stringify(conference));
    this.currentConferenceSubject.next(conference);
  }

  public clearStorage() {
    localStorage.removeItem("currentConference");
  }

  constructor() {
    this.currentConferenceSubject = new BehaviorSubject<CurrentConference>(JSON.parse(localStorage.getItem('currentConference')));
    this.currentConference = this.currentConferenceSubject.asObservable();
  }
}
