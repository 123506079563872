import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@shared/_models/user';
import { AuthService } from '@core/_services/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { AccessHelperService } from '@core/_services/access-helper.service'

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent {

  isExpanded = false;
  showFiller = false;
  dialog: MatDialog;
  mobileQuery: MediaQueryList;
  currentUser: User;
  private _mobileQueryListener: () => void;


  logout() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz się wylogować?').subscribe(result => {
      if (result) {
        this.authenticationService.logout();
        this.router.navigate(['authentication/login']);
      }
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(private router: Router,
    private authenticationService: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public accessHelperService: AccessHelperService,
    private confirmDialogService: ConfirmDialogService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

}
