export class AppUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    contactEmail: string;
    CompanyPhoneNumber: string;
    companyId: number;
    companyName: string;
    companyPosition: string;
    webPageLink: string;
    facebookLink: string;
    phoneNumber: string;
    linkedInLink: string;
    description: string;
    password: string;
    emailConfirmed: boolean;
    confirmPassword: string;
    userProfileIcon: string;
    oldSystemUserId: number;
    oldSystemUserEmail: string;
    lastLoginAttempt: Date;
    registerDate: Date;


    public get fullName() : string {
        return this.firstName + this.lastName;
    }

}
