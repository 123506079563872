import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { Question } from '../_models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Questions';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  add(questionDTO: Question) {
    return this.http.post(this.apiUrl + this.endPoint, questionDTO, { headers: this.headers });
  }

  edit(questionDTO: Question) {
    return this.http.put(this.apiUrl + this.endPoint, questionDTO, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  getByPollId(pollId: number) {
    return this.http.get<Question[]>(this.apiUrl + this.endPoint + '/GetQuestionsByPollId/' + pollId, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) { }
}
