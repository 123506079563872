import { Injectable } from '@angular/core';
import { ConferenceConnection } from '../_models/conference-connection';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { Project } from '../_models/project';
import { Category } from '../_models/category';

@Injectable({
  providedIn: 'root'
})
export class ConferenceConnectService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Conferences';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getProjects() {
    return this.http.get<Project[]>(this.apiUrl + this.endPoint + '/GetProjects', { headers: this.headers });
  }

  getProject(id: number) {
    return this.http.get<Project>(this.apiUrl + this.endPoint + '/GetProjects', { headers: this.headers });
  }

  getCategory(id: number) {
    return this.http.get<Category>(this.apiUrl + this.endPoint + '/GetCategories', { headers: this.headers });
  }


  getCategories() {
    return this.http.get<Category[]>(this.apiUrl + this.endPoint + '/GetCategories', { headers: this.headers });
  }

  add(conferenceConnection: ConferenceConnection) {
    return this.http.post<ConferenceConnection>(this.apiUrl + this.endPoint + '/ProjectCategoryConnections', conferenceConnection, { headers: this.headers });
  }

  edit(conferenceConnection: ConferenceConnection) {
    return this.http.put<ConferenceConnection>(this.apiUrl + this.endPoint + '/ProjectCategoryConnections', conferenceConnection, { headers: this.headers });
  }

  get(conferenceId: number) {
    return this.http.get<ConferenceConnection>(this.apiUrl + this.endPoint + '/ProjectCategoryConnections/ByConferenceId/' + conferenceId, { headers: this.headers });
  }

  delete(conferenceId: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/ProjectCategoryConnections/' + conferenceId, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) {
  }
}
