import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Participant } from '@conference/participants/_models/participant';
import { ParticipantsService } from '@conference/participants/_services/participants.service';
import { BaseTable } from '@shared/_base/base-table';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';



@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.css']
})
export class ParticipantsListComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'name', 'position', 'gender', 'email', 'phone', 'company', 'type', 'acceptsInvitations'];
  dataSource = new MatTableDataSource<Participant>();

  selection = new SelectionModel<Participant>(true, []);

  ngOnInit(): void {
    this.participantsService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Participant, filter: string) => {
        return (data.name + ' ' + data.surname).trim().toLowerCase().indexOf(filter) !== -1 ||
          (data.phone != null && data.phone.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.position != null && data.position.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.email != null && data.email.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.company != null && data.company.trim().toLowerCase().indexOf(filter) !== -1)
      }
    })
  }

  hide() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.participantsService.hide(element.id).subscribe(result => {
        this.toastr.success("Udało się ukryć użytkownika dla B2B.");
        element.acceptsInvitations = false;
      });
    }
  }

  unhide() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.participantsService.unhide(element.id).subscribe(result => {
        this.toastr.success("Udało się odkryć użytkownika dla B2B.");
        element.acceptsInvitations = true;
      });
    }
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        if (this.selection.selected.length > 0) {
          const element = this.selection.selected[0];
          this.participantsService.delete(element.id).subscribe(result => {
            this.toastr.success("Udało się usunąć użytkownika z konferencji.");
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
          });
        }
      }
    });
  }


  constructor(private participantsService: ParticipantsService,
    private confirmDialogService: ConfirmDialogService,
    private toastr: ToastrService) { super(); }

}
