import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '@env';
import { CurrentConference } from '@shared/_models/current-conference';
import { Wall }from '../_models/wall'

@Injectable({
  providedIn: 'root'
})
export class WallService {

  private currentConference: CurrentConference = new CurrentConference();
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Walls/';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  Get(){
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    return this.http.get<Wall>(this.apiUrl + this.endPoint + this.currentConference.id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }
}
