<div class="container">
    <h1>Podstawowe Informacje na temat aplikacji:</h1>
    
    <div>
        <h2>
                Wersja Panelu: {{panelVersion}}<br/>
                Wersja API: {{versionMessage.version}}<br/>
                Środowisko: {{versionMessage.environmentReadableMessage}}
        </h2>
    </div>

    <button mat-raised-button color="accent" (click)="DownloadInstruction()">
        Pobierz Instrukcję dla aplikacji mobilnej
    </button>
</div>