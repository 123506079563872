import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service';
import { environment } from '../../../../environments/environment';
import { PossibleAnswer } from '../_models/possible-answer';
import { PossibleAnswerType } from '../_models/possible-answer-type';


@Injectable({
  providedIn: 'root'
})
export class PossibleAnswerService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/PosibleAnswers';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  get(id: number) {
    return this.http.get<PossibleAnswer>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  getByQuestionId(id: number) {
    return this.http.get<PossibleAnswer[]>(this.apiUrl + this.endPoint + '/GetPosibleAnswersByQuestionId/' + id, { headers: this.headers });
  }

  getAllPosibleAnswerType() {
    return this.http.get<PossibleAnswerType[]>(this.apiUrl + this.endPoint, { headers: this.headers });
  }

  add(possibleAnswer: PossibleAnswer) {
    return this.http.post(this.apiUrl + this.endPoint, possibleAnswer, { headers: this.headers });
  }

  edit(possibleAnswer: PossibleAnswer) {
    return this.http.put(this.apiUrl + this.endPoint, possibleAnswer, { headers: this.headers });
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) { }
}
