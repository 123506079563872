import { Component, OnInit } from '@angular/core';
import { Project } from '@management/conferences/_models/project'
import { Category } from '@management/conferences/_models/category'
import { ConferenceConnection } from '@management/conferences/_models/conference-connection'
import { ConferenceConnectService } from '@management/conferences/_services/conference-connect.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-conference-connect-edit',
  templateUrl: './conference-connect-edit.component.html',
  styleUrls: ['./conference-connect-edit.component.css']
})
export class ConferenceConnectEditComponent implements OnInit {

  public projects: Project[] = [];
  public categories: Category[] = [];
  public model: ConferenceConnection = new ConferenceConnection();
  projectControl = new FormControl();
  categoryControl = new FormControl();
  filteredProjects: Observable<Project[]>;
  filteredCategories: Observable<Category[]>;

  onSubmit() {
    if (this.model) {
      this.conferenceConnectService.edit(this.model).subscribe(result => {
        this.toastr.success("Pomyślnie połączono konferencję!")
        this.router.navigate(["management/conference-list"])
      });
    }
  }

  ngOnInit(): void {
    var id = parseInt(this.route.snapshot.paramMap.get('id'));

    this.conferenceConnectService.get(id).subscribe(result => {
      this.model = result;

      this.conferenceConnectService.getCategories().subscribe(result => {
        this.projects = result;
        this.projects.push({ id: this.model.projectId, name: this.model.project.name });
        this.filteredProjects = this.projectControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterProject(value))
          );
      })
  
      this.conferenceConnectService.getProjects().subscribe(result => {
        this.categories = result;
        
        this.categories.push({ id: this.model.categoryId, name: this.model.category.name });
        this.filteredCategories = this.categoryControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterCategory(value))
          );
      })

    }, error => {
      this.router.navigate(["management/conference-list"]);
    });
    
  }

  private _filterProject(value: string): Project[] {
    const filterValue = value.toLowerCase();

    return this.projects.filter(project => project.name?.toLowerCase().includes(filterValue)
    );
  }

  private _filterCategory(value: string): Category[] {
    const filterValue = value.toLowerCase();

    return this.categories.filter(category => category.name?.toLowerCase().includes(filterValue)
    );
  }

  constructor(private conferenceConnectService: ConferenceConnectService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute) { }

}
