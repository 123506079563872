<div class="row">
    <div class="col-1">
        <em>
            <mat-icon *ngIf="answer.isCorrectAnswer" class="text-success">done</mat-icon>
        </em>
    </div>
    <div class="col-11 col-md-7">
        <h3><em>{{answer.description}} </em></h3>
    </div>
    <div class="col-12 col-md-2">
        <h3><em>{{answer.posibleAnswerType | possibleAnswerType}} </em></h3>
    </div>
    <div class="col-12 col-md-2">
        <button mat-raised-button type="button" class="w-100" (click)="deleteAnswer()" color="warn">Usuń</button>
    </div>
</div>