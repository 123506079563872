import { Injectable } from '@angular/core';
import { User } from '@shared/_models/user';
import { AuthService } from '@core/_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccessHelperService {

  currentUser: User;

  hasConferenceAccess(){
    return (this.currentUser.isFullAdmin || this.currentUser.authenticationPrivileges.find(d=> d.name=='Panel Admin Konferencja') != null)
  }

  hasManagementAccess(){
    return (this.currentUser.isFullAdmin || this.currentUser.authenticationPrivileges.find(d=> d.name=='Panel Admin Zarządzanie') != null)
  }
    
    constructor(private authenticationService: AuthService) { 
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
}
