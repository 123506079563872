import { AnswerResult } from "./answer-result";

export class QuestionResult {
    id: number;
    pollTitle: string;
    text: string;
    questionType: string;
    numberOfAllAnswers: number;
    answers: AnswerResult[];
}
