import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';
import { Company } from '@management/comapnies/_models/company';

@Injectable({
  providedIn: 'root'
})

export class CompaniesService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/companies';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getAll() {
    return this.http.get<Company[]>(this.apiUrl + this.endPoint, { headers: this.headers });
  }

  add(company: Company) {
    return this.http.post<Company>(this.apiUrl + this.endPoint, company, { headers: this.headers });
  }

  edit(company: Company) {
    return this.http.put<Company>(this.apiUrl + this.endPoint, company, { headers: this.headers });
  }

  delete(id: number) {
    const url = this.apiUrl + this.endPoint + '/' + id;
    return this.http.delete(url, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<Company>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) {
  }
}
