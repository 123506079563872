<mat-card class="mat-elevation-z8" [class.panel]="element.startTime == element.endTime">
    <div class="row">
        <div class="col-12 col-md-2">
            <h1>{{element.date | date:'dd-MM-yyyy'}}</h1>
        </div>
        <div class="col-12 col-md-8 ">
            <h1 *ngIf="element.startTime != element.endTime">{{element.startTime.slice(0, -3)}} -
                {{element.endTime.slice(0, -3)}}</h1>
        </div>
        <div  class="col-12 col-md-2">
            <div class="float-right">
                <h1><mat-icon>thumb_up</mat-icon> {{element?.numberOfLikes}}</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-3">
            {{element?.room}}
        </div>
        <div class="col-12 col-md-12">
            <div class="text-align-center" [innerHTML]="element?.name"></div>
        </div>
             
        <div class="col-12 col-md-12 mt-2" *ngFor="let item of element.presenters">
            <div class="text-align-center font-weight-bold">
                {{item.firstName}} {{item.lastName}} {{item.phone}} {{item.email}} {{item.companyName}}
            </div>
        </div>
        <div class="col-12 col-md-12">
            <div class="text-align-center" [innerHTML]="element?.additionalDescription"></div>
        </div>  
    </div>
</mat-card>