import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';
import { Company } from '@conference/companies/_models/company';
import { ConferenceDescription } from '@conference/companies/_models/conference-description';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { Sponsor } from '../_models/sponsor';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private currentConference: CurrentConference;
  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Companies';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  private getCurrentConference() {
    this.currentCompanyHelperService.currentConference.subscribe(result => {
      this.currentConference = result;
    });
  }

  getAll() {
    this.getCurrentConference();
    return this.http.get<Company[]>(this.apiUrl + this.endPoint + '/GetCompaniesByConferenceId/' + this.currentConference.id, { headers: this.headers });
  }

  getAllCompanies() {
    this.getCurrentConference();
    return this.http.get<Company[]>(this.apiUrl + this.endPoint, { headers: this.httpHeadersHelperService.getCustomHeaders() });
  }

  get(companyId: number, conferenceId: number) {
    return this.http.get<ConferenceDescription>(this.apiUrl + this.endPoint + '/CompanyConferenceConnection/' + companyId + '/' + conferenceId, { headers: this.headers });
  }

  add(company: ConferenceDescription) {
    return this.http.post<ConferenceDescription>(this.apiUrl + this.endPoint + '/CompanyConferenceConnection/', company, { headers: this.headers });
  }

  edit(company: ConferenceDescription) {
    return this.http.put<ConferenceDescription>(this.apiUrl + this.endPoint + '/CompanyConferenceConnection/', company, { headers: this.headers });
  }

  delete(conferenceDescriptionId: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/CompanyConferenceConnection/' + conferenceDescriptionId, { headers: this.headers });
  }

  getSponsors() {
    return this.http.get<Sponsor[]>(this.apiUrl + '/SponsorTypes', { headers: this.headers });
  }

  generateCompaniesForConference(){
    return this.http.post(this.apiUrl + this.endPoint + '/CreateCompaniesFromOldSystemAtConference/' + this.currentConference.id,null, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService,
    private currentCompanyHelperService: CurrentConferenceHelperService) {
  }
}
