import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Conference } from "@conference/conference-info/_models/conference";
import { ConferencePlanService } from "@conference/conference-info/_services/conference-plan.service";
import { ConferenceService } from "@conference/conference-info/_services/conference.service";
import { WallSetter } from "@core/_models/wall-setter";
import { ConfirmDialogService } from "@core/_services/confirm-dialog.service";
import { CurrentConferenceHelperService } from "@core/_services/current-conference-helper.service";
import { WallSetterService } from "@core/_services/wall-setter.service";
import { CurrentConference } from "@shared/_models/current-conference";
import { ToastrService } from "ngx-toastr";
import { ConferencePlan } from "./_models/conference-plan";

@Component({
  selector: "app-conference-info",
  templateUrl: "./conference-info.component.html",
  styleUrls: ["./conference-info.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ConferenceInfoComponent implements OnInit {
  private currentConference: CurrentConference = new CurrentConference();
  public conference: Conference = new Conference();
  displayedColumns: string[] = [
    "date",
    "startTime",
    "endTime",
    "room",
    "presenterName",
  ];
  expandedElement: Element | null;
  conferencePlan: ConferencePlan = new ConferencePlan();
  image: any;
  fileName: string;

  constructor(
    private conferenceService: ConferenceService,
    private currentConferenceHelperService: CurrentConferenceHelperService,
    protected toastr: ToastrService,
    protected conferencePlanService: ConferencePlanService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private wallSetterService: WallSetterService
  ) {
    this.currentConferenceHelperService.currentConference.subscribe((x) => {
      this.currentConference = x;
    });
  }

  ngOnInit(): void {
    this.conferenceService
      .get(this.currentConference.id)
      .subscribe((result) => {
        this.conference = result;
      });

    this.conferencePlanService.getByConferenceId().subscribe((result) => {
      if (result != null) {
        if (result.planFile != null) {
          this.image = result.planFile;
        }
        this.conferencePlan = result;
      }
    });
  }

  openWall() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/wall/${this.currentConference.id}"])
    );
    window.open(url, "_blank");
  }

  clearWall() {
    this.confirmDialogService
      .afterClosed("Czy na pewno chcesz wyczyścić Wall?")
      .subscribe((result) => {
        if (result) {
          const wallSetter = new WallSetter(this.conference.id, "basewall", 0);
          this.wallSetterService.Set(wallSetter).subscribe((result) => {
            this.toastr.success("Udało się pomyślnie wyczyścić wall!");
          });
        }
      });
  }

  changeUserDisplay() {
    this.conferenceService.switchShowParticipants().subscribe(
      (result) => {
        this.toastr.success(
          "Udało się pomyślnie zmienić wyświetlanie użytkowników!"
        );
        this.conference.showParticipants = !this.conference.showParticipants;
      },
      (err) => {
        this.toastr.error("Błąd zmiany wyświetlania użytkowników!");
      }
    );
  }

  changeLiveStreamUrl() {
    this.conferenceService
      .setLiveStreamUrl(this.conference.liveStreamUrl)
      .subscribe(
        (result) => {
          this.toastr.success(
            "Udało się pomyślnie ustawić link do Live Streama!"
          );
        },
        (err) => {
          this.toastr.error("Błąd ustawiania linku Live Streama!");
        }
      );
  }

  save() {
    console.log("Zapisuje!");
    this.conferencePlanService.getByConferenceId().subscribe((result) => {
      if (result != null) {
        this.conferencePlan.id = result.id;
        this.conferencePlan.fileName = this.fileName.split(" ").join("-");
        this.conferencePlan.planFile = this.image;
        this.conferencePlan.conferenceId = this.conference.id;
        this.conferencePlanService
          .edit(this.conferencePlan)
          .subscribe((result) => {
            this.toastr.success("Udało się edytować plan!");
          });
      } else {
        this.conferencePlan.fileName = this.fileName.split(" ").join("-");
        this.conferencePlan.planFile = this.image;
        this.conferencePlan.conferenceId = this.conference.id;
        this.conferencePlan.id = 0;
        this.conferencePlan.planLink = "";
        this.conferencePlanService
          .add(this.conferencePlan)
          .subscribe((result) => {
            this.toastr.success("Udało się dodać nowy plan!");
          });
      }
    });
  }

  str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  imageAdded($event) {
    console.log("Image!");
    this.image = $event;
    this.save();
  }

  fileNameChanged($event) {
    this.fileName = $event;
  }

  imageAddError($event) {
    this.toastr.error(
      "Wystąpił błąd, prawdopodobnie zdjęcie jest zbyt duże. Maksymalny rozamiar to 4MB"
    );
  }

  delete() {
    this.conferencePlanService.getByConferenceId().subscribe((result) => {
      if (result != null) {
        this.conferencePlan.id = result.id;
        console.log(this.conferencePlan.id);
        if (this.conferencePlan.id > 0) {
          this.conferencePlanService
            .delete(this.conferencePlan.id)
            .subscribe((result) => {
              this.toastr.success("Udało się usunąć plan!");
            });
        }
      } else {
        this.toastr.error("Brak planu do usunięcia!");
      }
    });
  }
}
