<div class="page-container">
  <div class="container p-5">
    <div *ngIf="this.model.questions.length <= 0" class=" text-center">
      <span class="title">{{currentConference.name}}</span>
    </div>
    <div class="title" align="middle">
      <span>{{actualResult.text }}</span>
    </div>
        <div class="row " >
          <div [class.col]="actualResult.answers.length <= 3"
          [class.col-3]="actualResult.answers.length >= 4"
          *ngFor="let answer of actualResult.answers; index as i">
          <mat-card class="card-container mat-elevation-z8 m-1" >
            <mat-card-content >
              <div align="middle">
                <span class="percent">{{answer.numberOfAnswersInPercent}}%</span>
              </div>
              <div align="middle" >
                <span [class.BigText]="answer.value.length <= 25"
                [class.MediumText]="answer.value.length >= 25 && answer.value.length <= 50"
                [class.SmallText]="answer.value.length > 50"
                
                class="question">{{answer.value | maxText}}</span>
              </div>
            </mat-card-content>
          </mat-card>
          </div>
        </div>
   
    <div class="fotter"  *ngIf="this.model.questions.length > 0">
      <div class="float-right">
        {{this.selectedIndex + 1}}/{{this.model.questions.length}}
      </div>
    </div>
  </div>
</div>