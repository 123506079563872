import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Debate } from '@conference/debates/_models/debate';
import { DebatesService } from '@conference/debates/_services/debates.service';
import { WallSetter } from '@core/_models/wall-setter';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { WallSetterService } from '@core/_services/wall-setter.service';
import { BaseTable } from '@shared/_base/base-table';
import { CurrentConference } from '@shared/_models/current-conference';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-debates-list',
  templateUrl: './debates-list.component.html',
  styleUrls: ['./debates-list.component.css']
})
export class DebatesListComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'title', 'start', 'end', 'debateStateName', 'comments'];
  dataSource = new MatTableDataSource<Debate>();
  selection = new SelectionModel<Debate>(true, []);
  currentConference:CurrentConference;

  ngOnInit(): void {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });

    this.debatesService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Debate, filter: string) => {
        return (data.title != null && data.title.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.start != null && data.start.toString().trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.end != null && data.end.toString().trim().toLowerCase().indexOf(filter) !== -1)
      }
      this.setPage();
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  edit() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/debates-edit/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  runOnWall() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.wallSetterService.Set(new WallSetter(this.currentConference.id,"debatewall",element.id)).subscribe(result=>{
        this.toastr.info("Wyświetlono debatę na tablicy!");
      });
    }
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.debatesService.deleteDebate(element.id).subscribe(result => {
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
            this.toastr.success("Pomyślnie usunięto debatę!");
          });
        }
        this.selection.clear();
      }
    });
  }

  open() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz otworzyć debatę?').subscribe(result => {
      if (result) {
        if (this.selection.selected.length > 0) {
          const element = this.selection.selected[0];
          if (element.debateStateName.toLowerCase() != 'closed') {
            this.debatesService.Open(element.id).subscribe(result => {
              element.debateStateName = 'open'
            });
          }
          else {
            this.toastr.error("Nie można zmienić statusu debaty, gdyż jest zakończona!");
          }
        }
      }
    });
  }

  close() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz zakończyć debatę?').subscribe(result => {
      if (result) {
        if (this.selection.selected.length > 0) {
          const element = this.selection.selected[0];
          if (element.debateStateName.toLowerCase() != 'closed') {
            this.debatesService.Close(element.id).subscribe(result => {
              element.debateStateName = 'closed'
            });
          }
        }
      }
    });
  }

  constructor(private debatesService: DebatesService,
    private toastr: ToastrService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private wallSetterService:WallSetterService,
    private currentConferenceHelperService:CurrentConferenceHelperService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
