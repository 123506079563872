import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ɵɵqueryRefresh } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ConferenceService } from '@management/conferences/_services/conference.service';
import { Conference } from '@management/conferences/_models/conference';
import { Router } from '@angular/router';
import { BaseTable } from '@shared/_base/base-table'
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { User } from '@shared/_models/user';
import { AuthService } from '@core/_services/auth.service';
import { AccessHelperService } from '@core/_services/access-helper.service'
import { ConferenceConnectService } from '../_services/conference-connect.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';


@Component({
  selector: 'app-conference-list',
  templateUrl: './conference-list.component.html',
  styleUrls: ['./conference-list.component.css']
})
export class ConferenceListComponent extends BaseTable implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'symbol', 'date', 'portal', 'name'];
  dataSource = new MatTableDataSource<Conference>();
  selection = new SelectionModel<Conference>(true, []);
  public currentUser: User;

  ngOnInit(): void {
    this.refresh();
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  refresh(){
    this.conferenceService.getAll().subscribe(
      result => {
        this.dataSource.data = result;
        this.dataSource.filterPredicate =
      (data: Conference, filter: string) => !filter || 
      (data.symbol != null && data.symbol.trim().toLowerCase().indexOf(filter) !== -1) || 
      (data.name != null &&data.name.trim().toLowerCase().indexOf(filter) !== -1) || 
      (data.portal.name != null &&data.portal.name.trim().toLowerCase().indexOf(filter) !== -1);
      })
  }

  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      if(property == "portal" && item[property]!=undefined && item[property]["name"] != null){
        property = "portalTemp"
        item[property] = item["portal"].name;
      }else if(property == "date"){
        item[property] = item["startDateOfConference"].toString()+item["endDateOfConference"].toString();
      }
      if(item[property] == null || item[property] == undefined || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';           
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
  };
  }

  select() {
    if (this.selection.selected.length > 0) {
      var selectedElement = this.selection.selected[0];
      const currentConference = new CurrentConference();
      currentConference.id = selectedElement.id;
      currentConference.name = selectedElement.name;
      this.router.navigate(['conference/info']);
      this.currentConferenceHelperService.setItem(currentConference);
    }
  }

  edit() {
    if (this.selection.selected.length > 0) {
      var selectedElement = this.selection.selected[0];
      this.router.navigate(['management/conference-connect-edit', selectedElement.id]);
    }
  }

  navigate(event: any) {

    if (event) {
      var selectedElement = this.dataSource.data.filter(r => r.id == event)[0];
      if (selectedElement) {
        const currentConference = new CurrentConference();
        currentConference.id = selectedElement.id;
        currentConference.name = selectedElement.name;
        this.router.navigate(['/conference/info']);
        this.currentConferenceHelperService.setItem(currentConference);
      }
    }
  }

  connect() {
    this.router.navigate(['/management/conference-connect']);
  }

  disconnect() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz rozłączyć konferencję?').subscribe(result => {

      if (result && this.selection.selected.length==1) {
        var selectedElement = this.selection.selected[0];
        this.conferenceConnectService.get(selectedElement.id).subscribe(result => {
          this.conferenceConnectService.delete(result.id).subscribe(result => {
            this.toastr.success("Pomyślnie rozłączono konferencję!")
            this.selection.clear();
            var indexToDelete = this.dataSource.data.indexOf(selectedElement);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
          });
        });
      }
    });

  }

  constructor(private conferenceService: ConferenceService,
    private conferenceConnectService: ConferenceConnectService,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    public accessHelperService: AccessHelperService,
    private currentConferenceHelperService: CurrentConferenceHelperService,
    private router: Router, private authenticationService: AuthService,
    private changeRef: ChangeDetectorRef) { 
      super(); 
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      
    }

}
