import { Comment } from '@conference/debates/_models/comment';

export class Debate {
    id: number;
    title: string;
    programElementId: number;
    description: string;
    start: Date;
    end: Date;
    debateStateName: string;
    comments: Comment[] = [];
}
