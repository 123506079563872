<form #companyForm="ngForm" class="company-form">
  <div class="row">
    <div class="col">
      <h1><strong>Szczegóły Użytkownika</strong></h1>
    </div>
    <div class="col add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/management/user-list"
          [state]={page:this.page,pageSize:this.pageSize}>Powrót</button>
        <button class="ml-1" mat-raised-button color="accent" (click)="edit()">
          Edytuj
        </button>

      </div>
    </div>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-lg-4">
          <h3><b>Sumaryczne punkty aktywności: {{userPoints.points}}</b></h3>

          <mat-form-field class="w-100">
            <input matInput placeholder="Imię" readonly [(ngModel)]="model.firstName" name="firstName" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Nazwisko" readonly [(ngModel)]="model.lastName" name="lastName"
              #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Email" readonly [(ngModel)]="model.email" name="email" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Firma" readonly [(ngModel)]="model.companyName" name="company" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Telefon" readonly [(ngModel)]="model.phoneNumber" name="phone" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Stanowisko" readonly [(ngModel)]="model.companyPosition" name="position"
              #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <textarea readonly matInput matTextareaAutosize placeholder="Opis" [(ngModel)]="model.description"
              name="description" #name="ngModel"></textarea>
          </mat-form-field>
          <mat-form-field readonly class="w-100">
            <input matInput placeholder="Użytkownik w starym systemie" readonly [(ngModel)]="model.oldSystemUserEmail"
              name="oldUser" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Aktywność w aplikacji" readonly [(ngModel)]="model.lastLoginAttempt"
              name="lastActivity" #name="ngModel">
          </mat-form-field>
          <mat-form-field class="w-100">
            <input matInput placeholder="Data Rejestracji" readonly [(ngModel)]="model.registerDate" name="registerDate"
              #name="ngModel">
          </mat-form-field>
          <div class="confirmed-email">
            Potwierdzony email:
            <mat-icon style="color:green;text-align:center;" *ngIf="model.emailConfirmed">done</mat-icon>
            <mat-icon style="color:red;text-align:center;" *ngIf="!model.emailConfirmed">clear</mat-icon>
          </div>

        </div>

        <div class="col-12 col-lg-8 mx-auto">
          <div class="row justify-content-center">
            <app-file-dialog [disableDelete]="true" [disableAdd]="true" title="Profil Użytkownika" class="w-100 m-1"
              [image]="image"></app-file-dialog>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
