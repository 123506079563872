import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private apiUrl: string = `${environment.versionUrl}`;
  private endPoint: string = '/Images/';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getImage(link: string) {
    return this.http.get(this.apiUrl + this.endPoint, { headers: this.headers, params: { imageLink: link }, responseType: 'blob' },);
  }

  getProfileImage(id: number) {
    return this.http.get(this.apiUrl +'/admin' + '/Users/GetProfileImage/' + id, { headers: this.headers, responseType: 'blob' });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) {
  }
}
