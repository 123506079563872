import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Relation } from '@conference/relations/_models/relation';
import { RelationsService } from '@conference/relations/_services/relations.service';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { BaseTable } from '@shared/_base/base-table';

@Component({
  selector: 'app-relations-list',
  templateUrl: './relations-list.component.html',
  styleUrls: ['./relations-list.component.css']
})
export class RelationsListComponent extends BaseTable implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'title', 'firstName', 'lastName'];
  dataSource = new MatTableDataSource<Relation>();
  selection = new SelectionModel<Relation>(true, []);

  ngOnInit(): void {
    this.relationService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Relation, filter: string) => {
        return (data.title != null && data.title.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.userName != null && data.userName.toString().trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.userLastName != null && data.userLastName.toString().trim().toLowerCase().indexOf(filter) !== -1)
      }
      this.setPage();
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {
     console.log(item);
     console.log(property);
      if(property == "firstName"){
        item[property] = item["userName"];
      }else if(property =="lastName"){
        item[property] = item["userLastName"];
      }
      if(item[property] == null || item[property] == undefined || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
  };
  }

  details() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/relations-details/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }

  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.relationService.delete(element.id).subscribe(result => {
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
          });
        }
        this.selection.clear();
      }
    });
  }

  constructor(private relationService: RelationsService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }

}
