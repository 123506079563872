import { MeetingStand } from "./meeting-stand";
import { Person } from "./person";
import { TimeInterval } from "./time-interval";

export class Proposal {
    id: number;
    proposalStatus: string;
    description: string;
    stand: MeetingStand;
    creationPerson: Person;
    targetPerson: Person;
    timeInterval: TimeInterval;
}
