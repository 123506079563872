import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Poll } from '@conference/polls/_models/poll';
import { PollsService } from '@conference/polls/_services/polls.service';
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { BaseTable } from '@shared/_base/base-table';
import { ToastrService } from 'ngx-toastr';
import { WallSetterService } from '@core/_services/wall-setter.service'
import { WallSetter } from '@core/_models/wall-setter';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';

@Component({
  selector: 'app-polls-list',
  templateUrl: './polls-list.component.html',
  styleUrls: ['./polls-list.component.css']
})
export class PollsListComponent extends BaseTable implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'title', 'start', 'end', 'pollState','answers'];
  dataSource = new MatTableDataSource<Poll>();
  selection = new SelectionModel<Poll>(true, []);
  currentConference: CurrentConference;

  ngOnInit(): void {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });

    this.poolsService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.dataSource.filterPredicate = (data: Poll, filter: string) => {
        return (data.title != null && data.title.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.start != null && data.start.toString().trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.end != null && data.end.toString().trim().toLowerCase().indexOf(filter) !== -1)
      }
      this.setPage();
    }
    );
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  edit() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.router.navigate(['conference/polls-edit/' + element.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }


  delete() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.poolsService.delete(element.id).subscribe(result => {
            var indexToDelete = this.dataSource.data.indexOf(element);
            this.dataSource.data.splice(indexToDelete, 1);
            this.dataSource._updateChangeSubscription();
          });
        }
        this.selection.clear();
      }
    });
  }

  runOnWall() {
    if (this.selection.selected.length > 0) {
      const element = this.selection.selected[0];
      this.wallSetterService.Set(new WallSetter(this.currentConference.id, "pollWall", element.id)).subscribe(result => {
        this.toastrService.info("Wyświetlono ankietę na tablicy!");
      });
    }
  }

  start() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz rozpocząć ankietę?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.poolsService.start(element.id).subscribe(result => {
            element.pollState = 'inprogress'
            this.toastrService.success("Pomyślnie rozpoczęto ankietę!");

          });
        }
      }
    });
  }

  end() {
    this.confirmDialogService.afterClosed('Czy na pewno chcesz zakończyć ankietę?').subscribe(result => {
      if (result) {
        for (let index = 0; index < this.selection.selected.length; index++) {
          const element = this.selection.selected[index];
          this.poolsService.end(element.id).subscribe(result => {
            element.pollState = 'finished'
            this.toastrService.success("Pomyślnie zakończono ankietę!");
          });
        }
      }
    });
  }

  constructor(private poolsService: PollsService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private toastrService: ToastrService,
    private wallSetterService: WallSetterService,
    private currentConferenceHelperService: CurrentConferenceHelperService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
    }

}
