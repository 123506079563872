import { Injectable } from '@angular/core';

interface Country {
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class CountryHelperService {

  public countries: Country[]=[
    {value: ""},
    {value: "Polska"},
    {value: "Niemcy"},
    {value: "Czechy"},
    {value: "Austria"},
    {value: "Słowacja"},
    {value: "Albania"},
    {value: "Andora"},
    {value: "Belgia"},
    {value: "Białoruś"},
    {value: "Bośnia i Hercegowina"},
    {value: "Bułgaria"},
    {value: "Chorwacja"},
    {value: "Czarnogóra"},
    {value: "Dania"},
    {value: "Estonia"},
    {value: "Finlandia"},
    {value: "Francja"},
    {value: "Grecja"},
    {value: "Hiszpania"},
    {value: "Holandia"},
    {value: "Irlandia"},
    {value: "Islandia"},
    {value: "Liechtenstein"},
    {value: "Litwa"},
    {value: "Luksemburg"},
    {value: "Łotwa"},
    {value: "Macedonia"},
    {value: "Malta"},
    {value: "Mołdawia"},
    {value: "Monako"},
    {value: "Norwegia"},
    {value: "Portugalia"},
    {value: "Rosja"},
    {value: "Rumunia"},
    {value: "San Marino"},
    {value: "Serbia"},
    {value: "Słowenia"},
    {value: "Szwajcaria"},
    {value: "Szwecja"},
    {value: "Ukraina"},
    {value: "Watykan"},
    {value: "Węgry"},
    {value: "Wielka Brytania"},
    {value: "Włochy"}
  ]

  constructor() { }
}
