import { RouterModule, Routes } from '@angular/router';
import { B2bDefineComponent, B2bDetailsComponent, B2bEditComponent, B2bListComponent } from '@conference/b2b/index';
import { CompaniesAddComponent, CompaniesEditComponent, CompaniesListComponent } from '@conference/companies/index';
import { ConferenceInfoComponent } from '@conference/conference-info/conference-info.component';
import { ConferenceComponent } from '@conference/conference.component';
import { DebatesAddComponent, DebatesEditComponent, DebatesListComponent } from '@conference/debates/index';
import { NotificationsAddComponent, NotificationsDetailsComponent, NotificationsListComponent } from '@conference/notifications/index';
import { ParticipantsListComponent } from '@conference/participants/index';
import { PollsAddComponent, PollsEditComponent, PollsListComponent } from '@conference/polls/index';
import { PollsDetailsComponent } from '@conference/wall/polls-details/polls-details.component';
import { DebatesDetailsComponent } from '@conference/wall/debates-details/debates-details.component';
import { RelationsDetailsComponent, RelationsListComponent } from '@conference/relations/index';
import { StandsAddComponent, StandsEditComponent, StandsListComponent } from '@conference/stands/index';
import { AuthGuard } from '@core/_guards/auth.guard';
import { ConferenceAccessGuard } from '@core/_guards/conference-access.guard'
import { ManagementAccessGuard } from '@core/_guards/management-access.guard';
import { ConferenceGuard } from '@core/_guards/conference.guard';
import { CompanyAddComponent } from '@management/comapnies/company-add/company-add.component';
import { CompanyEditComponent } from '@management/comapnies/company-edit/company-edit.component';
import { CompanyListComponent } from '@management/comapnies/company-list/company-list.component';
import { ConferenceConnectEditComponent } from '@management/conferences/conference-connect-edit/conference-connect-edit.component';
import { ConferenceConnectComponent } from '@management/conferences/conference-connect/conference-connect.component';
import { ConferenceListComponent } from '@management/conferences/conference-list/conference-list.component';
import { ManagementComponent } from '@management/management.component';
import { RegistrationFormsDetailsComponent } from '@management/registration-forms/registration-forms-details/registration-forms-details.component';
import { RegistrationFormsListComponent } from '@management/registration-forms/registration-forms-list/registration-forms-list.component';
import { UserDetailComponent } from '@management/users/user-detail/user-detail.component';
import { UserEditComponent } from '@management/users/user-edit/user-edit.component';
import { UserListComponent } from '@management/users/user-list/user-list.component';
import { UserAddComponent } from '@management/users/user-add/user-add.component';
import { UserLoginComponent } from '@shared/user-login/user-login.component';
import { WallComponent } from '@conference/wall/wall.component';
import { InformationComponent } from '@management/information/information.component';



const routes: Routes = [

  {
    path: 'management', component: ManagementComponent, canActivate: [AuthGuard], children: [
      { path: '', component: ConferenceListComponent , canActivate: [AuthGuard]},
      { path: 'conference-list', component: ConferenceListComponent, canActivate: [AuthGuard] },
      { path: 'conference-connect', component: ConferenceConnectComponent, canActivate: [ManagementAccessGuard] },
      { path: 'conference-connect-edit/:id', component: ConferenceConnectEditComponent, canActivate: [ManagementAccessGuard] },
      { path: 'user-list', component: UserListComponent, canActivate: [ManagementAccessGuard] },
      { path: 'user-detail/:id', component: UserDetailComponent, canActivate: [ManagementAccessGuard] },
      { path: 'user-edit/:id', component: UserEditComponent, canActivate: [ManagementAccessGuard] },
      { path: 'user-add', component: UserAddComponent, canActivate: [ManagementAccessGuard] },
      { path: 'company-list', component: CompanyListComponent, canActivate: [ManagementAccessGuard] },
      { path: 'company-add', component: CompanyAddComponent, canActivate: [ManagementAccessGuard] },
      { path: 'company-edit/:id', component: CompanyEditComponent, canActivate: [ManagementAccessGuard] },
      { path: 'registration-forms-list', component: RegistrationFormsListComponent, canActivate: [ManagementAccessGuard] },
      { path: 'registration-forms-details/:id', component: RegistrationFormsDetailsComponent, canActivate: [ManagementAccessGuard] },
      { path: 'information', component: InformationComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: 'conference', component: ConferenceComponent, canActivate: [AuthGuard, ConferenceGuard], children: [
      { path: '', component: ConferenceInfoComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'info', component: ConferenceInfoComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'b2b-edit/:id', component: B2bEditComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'b2b-define', component: B2bDefineComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'b2b-details/:id', component: B2bDetailsComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'b2b-list', component: B2bListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'debates-list', component: DebatesListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'debates-edit/:id', component: DebatesEditComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'debates-add', component: DebatesAddComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'notifications-list', component: NotificationsListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'notifications-add', component: NotificationsAddComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'notifications-details/:id', component: NotificationsDetailsComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'participants-list', component: ParticipantsListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'polls-list', component: PollsListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'polls-edit/:id', component: PollsEditComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'polls-add', component: PollsAddComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'relations-list', component: RelationsListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'relations-details/:id', component: RelationsDetailsComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'stands-list', component: StandsListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'stands-edit/:id', component: StandsEditComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'stands-add', component: StandsAddComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

      { path: 'companies-list', component: CompaniesListComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'companies-edit/:companyId/:conferenceId', component: CompaniesEditComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
      { path: 'companies-add', component: CompaniesAddComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },

    ]
  },
  { path: 'wall/:conferenceId', component: WallComponent, canActivate: [ConferenceAccessGuard, ConferenceGuard] },
  { path: 'authentication/login', component: UserLoginComponent },
  { path: '**', redirectTo: 'management/conference-list' }
];

export const appRoutingModule = RouterModule.forRoot(routes);