import { Proposal } from './proposal'
import { Person } from './person'

export class Meeting {
    id: number;
    creationPerson: Person = new Person();
    targetPerson: Person = new Person();
    meetingStatus: string;
    meetingPlace: string;
    creationDate: Date;
    proposals: Proposal[] = [];
}
