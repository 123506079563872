<form (ngSubmit)="onSubmit()" #b2b="ngForm" class="w-100">

  <div class="row">
    <div class="col-6">
      <h1><strong>Edytowanie Spotkania</strong></h1>
    </div>
    <div class="col-6 add-company-buttons">
      <div class="float-right">
        <button mat-stroked-button class="login-button " color="primary" routerLink="/conference/b2b-list"
          [state]={page:this.page,pageSize:this.pageSize}>Powrót</button>
      </div>

    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-12 mb-2">
          <mat-card class="mat-elevation-z2">
            <mat-card-content>
              <div class="row w-100">
                <div class="col-6">
                  <h4 class="text-center font-weight-bold">Data Utworzenia: {{model.creationDate |
                                        date:'dd-MM-yyyy HH:mm' }}</h4>
                </div>
                <div class="col-6">
                  <h4 class="text-center font-weight-bold">Status: {{model.meetingStatus |
                                        meetingStatus}}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-4 col-lg-3">
                  <div class="row justify-content-center">
                    <img alt="Profile image" class="profile-image"
                      [src]="'data:image/jpg;base64,' + this.creationPersonImage" />
                  </div>
                </div>
                <div class="col-8 col-lg-3">
                  <span class="font-weight-bold">Zaprosił(a)</span><br />
                  <span>Identyfikator: <strong>{{model.creationPerson.id}}</strong> </span><br />
                  <span>Imię: <strong>{{model.creationPerson.firstName}}</strong></span><br />
                  <span>Naziwsko: <strong>{{model.creationPerson.lastName}}</strong></span><br />
                  <span>firma: <strong>{{model.creationPerson.company}}</strong></span><br />
                  <span>email: <strong>{{model.creationPerson.email}}</strong></span><br />
                  <span>telefon: <strong>{{model.creationPerson.phoneNumber}}</strong></span><br />
                  <span>tytuł: <strong>{{model.creationPerson.title}}</strong></span><br />
                </div>

                <div class="col-4 col-lg-3">
                  <div class="row justify-content-center">
                    <img alt="Profile image" class="profile-image"
                      [src]="'data:image/jpg;base64,' + this.targetPersonImage" />
                  </div>
                </div>
                <div class="col-8 col-lg-3">
                  <span class="font-weight-bold">Adresat</span><br />
                  <span>Identyfikator: <strong>{{model.targetPerson.id}}</strong></span><br />
                  <span>Imię: <strong>{{model.targetPerson.firstName}}</strong></span><br />
                  <span>Naziwsko: <strong>{{model.targetPerson.lastName}}</strong></span><br />
                  <span>firma: <strong>{{model.targetPerson.company}}</strong></span><br />
                  <span>email: <strong>{{model.targetPerson.email}}</strong></span><br />
                  <span>telefon: <strong>{{model.targetPerson.phoneNumber}}</strong></span><br />
                  <span>tytuł: <strong>{{model.targetPerson.title}}</strong></span><br />
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-12 ">
          <div class="d-flex flex-row mb-3" *ngFor="let item of model.proposals;let i = index">
            <div class="d-flex w-100" [ngClass]="{'flex-row-reverse': i % 2 == 1}">
              <app-proposal class="w-100" [proposal]="item"></app-proposal>
            </div>
          </div>
        </div>
      </div>



    </mat-card-content>
  </mat-card>
</form>
