import { Component, OnInit } from '@angular/core';
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';

@Component({
  selector: 'app-default-wall',
  templateUrl: './default-wall.component.html',
  styleUrls: ['./default-wall.component.css']
})
export class DefaultWallComponent implements OnInit {
  
  currentConference: CurrentConference = new CurrentConference();

  constructor(private currentConferenceHelperService:CurrentConferenceHelperService) { }

  ngOnInit(): void {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
  }

}
