<div class="example-container m-auto" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar class="row m-auto" color="primary">
    <div class="col d-flex flex-row align-items-center">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 class="d-none d-sm-block">BMP - Budujemy Możliwości Porozumienia</h1>
    </div>
    <div class="col d-flex flex-row-reverse">
      <button mat-raised-button routerLinkActive="active-link" (click)="logout()">Wyloguj</button>
      <div class="mr-2 d-none d-sm-block">
        <h1>Witaj, {{currentUser.firstName}} {{currentUser.lastName}}! </h1>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="40">
      <mat-nav-list class="menu">
        <a mat-list-item routerLinkActive="active-link" routerLink="/management/conference-list" >
          <mat-icon>work</mat-icon> Konferencje
        </a>
        <a mat-list-item routerLinkActive="active-link" routerLink="/management/user-list" [disabled]="!accessHelperService.hasManagementAccess()">
          <mat-icon>people</mat-icon>Użytkownicy
        </a>
        <a mat-list-item routerLinkActive="active-link" routerLink="/management/registration-forms-list" [disabled]="!accessHelperService.hasManagementAccess()">
          <mat-icon>topic</mat-icon>Formularze
        </a>
        <a mat-list-item routerLinkActive="active-link" routerLink="/management/company-list" [disabled]="!accessHelperService.hasManagementAccess()">
          <mat-icon>apartment</mat-icon>Firmy
        </a>
        <a mat-list-item routerLinkActive="active-link" routerLink="/management/information">
          <mat-icon>info</mat-icon>Informacje
        </a>

      </mat-nav-list>
      <div class="fixed-bottom logo-menu">
        <div class="row ">
          <img alt="logo" src="../../assets/Logo.png" />
        </div>
      </div>

    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>