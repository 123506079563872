import { Component, OnInit } from '@angular/core';
import { WallService } from '@conference/wall/_services/wall.service'
import { CurrentConferenceHelperService } from '@core/_services/current-conference-helper.service';
import { CurrentConference } from '@shared/_models/current-conference';
import { Wall } from './_models/wall';
import { WallSignalService } from './_services/wall-signal.service'

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.css']
})
export class WallComponent implements OnInit {

  wall: Wall = new Wall();
  currentConference: CurrentConference = new CurrentConference();

  ngOnInit(): void {
    this.currentConferenceHelperService.currentConference.subscribe(x => { this.currentConference = x });
    this.wallService.Get().subscribe(result => {
      this.wall = result;

      this.wallSignalService.startConnection();
      this.wallSignalService.addNewCommentDataListener().on("ReceiveWall" + this.currentConference.id, result => {
        this.wall = result;
      });
    })    
  } 

  constructor(private wallService: WallService,
    private wallSignalService: WallSignalService,
    private currentConferenceHelperService: CurrentConferenceHelperService) { }

}
