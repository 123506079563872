import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { environment } from '@env';
import { RegistrationForm } from '@management/registration-forms/_models/registration-form';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormsService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/registration';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  getAll() {
    return this.http.get<RegistrationForm[]>(this.apiUrl + this.endPoint, { headers: this.headers });
  }

  get(id: number) {
    return this.http.get<RegistrationForm>(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  getByConference(conferenceId: number) {
    return this.http.get<RegistrationForm[]>(this.apiUrl + this.endPoint + '/ByConferenceId/' + conferenceId, { headers: this.headers });
  }

  markAsReaded(registrationForm: RegistrationForm) {
    return this.http.put(this.apiUrl + this.endPoint + '/ChangeStatus', registrationForm, { headers: this.headers });
  }

  markAsUnreaded(registrationForm: RegistrationForm) {
    return this.http.put(this.apiUrl + this.endPoint + '/ChangeStatus', registrationForm, { headers: this.headers });
  }

  delete(registrationForm: RegistrationForm) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + registrationForm.id, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) {
  }
}
