import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHeadersHelperService } from '@core/_services/http-headers-helper.service'
import { Comment } from '@conference/debates/_models/comment';
import { environment } from '@env';


@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private apiUrl: string = `${environment.apiUrl}`;
  private endPoint: string = '/Comment';
  private headers: HttpHeaders = this.httpHeadersHelperService.getCustomHeaders();

  addComment(comment: Comment) {
    return this.http.post(this.apiUrl + this.endPoint, comment, { headers: this.headers });
  }

  acceptComment(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/Accept/' + id, null, { headers: this.headers });
  }

  rejectComment(id: number) {
    return this.http.put(this.apiUrl + this.endPoint + '/Reject/' + id, null, { headers: this.headers });
  }

  deleteComment(id: number) {
    return this.http.delete(this.apiUrl + this.endPoint + '/' + id, { headers: this.headers });
  }

  editComment(comment: Comment) {
    return this.http.put(this.apiUrl + this.endPoint, comment, { headers: this.headers });
  }

  getAll(debateId: number) {
    return this.http.get<Comment[]>(this.apiUrl + this.endPoint + '/' + debateId, { headers: this.headers });
  }

  constructor(private http: HttpClient,
    private httpHeadersHelperService: HttpHeadersHelperService) { }
}
