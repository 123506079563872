import { SharedModule } from '@shared/shared.module'
import { NgModule } from '@angular/core';
import { MaterialModule } from '@material/material.module';
import { DebatesListComponent } from '@conference/debates/debates-list/debates-list.component';
import { DebatesAddComponent } from '@conference/debates/debates-add/debates-add.component';
import { DebatesEditComponent } from '@conference/debates/debates-edit/debates-edit.component';
import { DebatesDetailsComponent } from '@conference/wall/debates-details/debates-details.component';
import { NotificationsListComponent } from '@conference/notifications/notifications-list/notifications-list.component';
import { NotificationsAddComponent } from '@conference/notifications/notifications-add/notifications-add.component';
import { NotificationsDetailsComponent } from '@conference/notifications/notifications-details/notifications-details.component';
import { ParticipantsListComponent } from '@conference/participants/participants-list/participants-list.component';
import { PollsListComponent } from '@conference/polls/polls-list/polls-list.component';
import { PollsAddComponent } from '@conference/polls/polls-add/polls-add.component';
import { PollsEditComponent } from '@conference/polls/polls-edit/polls-edit.component';
import { PollsDetailsComponent } from '@conference/wall/polls-details/polls-details.component';
import { B2bListComponent } from '@conference/b2b/b2b-list/b2b-list.component';
import { B2bEditComponent } from '@conference/b2b/b2b-edit/b2b-edit.component';
import { B2bDefineComponent } from '@conference/b2b/b2b-define/b2b-define.component';
import { B2bDetailsComponent } from '@conference/b2b/b2b-details/b2b-details.component';
import { RelationsListComponent } from '@conference/relations/relations-list/relations-list.component';
import { RelationsDetailsComponent } from '@conference/relations/relations-details/relations-details.component';
import { ConferenceInfoComponent } from '@conference/conference-info/conference-info.component';
import { StandsListComponent } from './stands/stands-list/stands-list.component';
import { StandsAddComponent } from './stands/stands-add/stands-add.component';
import { StandsEditComponent } from './stands/stands-edit/stands-edit.component';
import { CompaniesListComponent } from './companies/companies-list/companies-list.component';
import { CompaniesAddComponent } from './companies/companies-add/companies-add.component';
import { CompaniesEditComponent } from './companies/companies-edit/companies-edit.component';
import { ConferenceComponent } from './conference.component';
import { CommentComponent } from './debates/comment/comment.component';
import { QuestionAddComponent } from './polls/question-add/question-add.component';
import { QuestionComponent } from './polls/question/question.component';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { AnswerComponent } from './polls/answer/answer.component';
import { B2BDefineHoursComponent } from './b2b/b2b-define-hours/b2b-define-hours.component';
import { ProgramElementComponent } from './conference-info/program-element/program-element.component';
import { PossibleAnswerTypePipe } from './polls/_pipes/possible-answer-type.pipe';
import { QuestionTypePipe } from './polls/_pipes/question-type.pipe';
import { GenderPipe } from './participants/_pipes/gender.pipe';
import { B2bMeetingStand } from './b2b/b2b-meeting-stand/b2b-meeting-stand.component';
import { B2bHourComponent } from './b2b/b2b-hour/b2b-hour.component';
import { PollStatePipe } from './polls/_pipes/poll-state.pipe';
import { TimeIntervalPipe } from './b2b/_pipes/time-interval.pipe';
import { NotificationTypePipe } from './notifications/_pipes/notification-type.pipe';
import { WallComponent } from './wall/wall.component';
import { DefaultWallComponent } from './wall/default-wall/default-wall.component';
import { MeetingStatusPipe } from './b2b/_pipes/meeting-status.pipe';
import { ProposalComponent } from './b2b/proposal/proposal.component';
import { ProposalStatusPipe } from './b2b/_pipes/proposal-status.pipe';
import { MaxTextPipe } from './wall/_pipes/max-text.pipe';

@NgModule({
  declarations: [
    DebatesListComponent, 
    DebatesAddComponent, 
    DebatesEditComponent, 
    DebatesDetailsComponent, 
    NotificationsListComponent, 
    NotificationsAddComponent, 
    NotificationsDetailsComponent, 
    ParticipantsListComponent, 
    PollsListComponent, 
    PollsAddComponent, 
    PollsEditComponent, 
    PollsDetailsComponent, 
    B2bListComponent, 
    B2bEditComponent, 
    B2bDefineComponent, 
    B2bDetailsComponent, 
    RelationsListComponent, 
    RelationsDetailsComponent, 
    ConferenceInfoComponent, 
    StandsListComponent, 
    StandsAddComponent, 
    StandsEditComponent, 
    CompaniesListComponent, 
    CompaniesAddComponent, 
    CompaniesEditComponent, 
    ConferenceComponent, 
    CommentComponent, 
    QuestionAddComponent, 
    QuestionComponent, 
    AnswerComponent, 
    B2BDefineHoursComponent, 
    ProgramElementComponent, 
    PossibleAnswerTypePipe, 
    QuestionTypePipe, 
    B2bHourComponent,
    GenderPipe,
    PollStatePipe, 
    B2bMeetingStand, TimeIntervalPipe, NotificationTypePipe, WallComponent, DefaultWallComponent, MeetingStatusPipe, ProposalComponent, ProposalStatusPipe, MaxTextPipe],
  imports: [
    SharedModule,
    MaterialModule
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
})
export class ConferenceModule { }
