import { Component, OnInit, AfterViewInit , ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RegistrationForm } from '@management/registration-forms/_models/registration-form';
import { RegistrationFormsService } from '@management/registration-forms/_services/registration-forms.service';
import { Router } from '@angular/router';
import { BaseTable } from '@shared/_base/base-table'
import { ConfirmDialogService } from '@core/_services/confirm-dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-registration-forms-list',
  templateUrl: './registration-forms-list.component.html',
  styleUrls: ['./registration-forms-list.component.css']
})
export class RegistrationFormsListComponent extends BaseTable implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'id', 'name', 'contactEmail', 'contactPhoneNumber', 'company', 'conference','date'];
  dataSource = new MatTableDataSource<RegistrationForm>();
  selection = new SelectionModel<RegistrationForm>(true, []);

  ngOnInit(): void {

    this.registrationFormsService.getAll().subscribe(result => {
      this.dataSource.data = result;
      this.setPage();
      })
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  sortConfig(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      if(property == "name"){
        item[property] = item["firstName"]+" "+item["lastName"];
      }else if(property =="date"){
        item[property] = item["registrationTime"];
      }
      if(item[property] == null || item[property] == undefined || (typeof(item[property]) === 'string' && item[property].trim() == "")) {
        if("desc" == this.sort.direction) return '\u0000'+'\u0000'+'\u0000';
        return '\uFFFF'+'\uFFFF'+'\uFFFF';
    }
    return (typeof item[property] === 'string')?item[property].toLocaleLowerCase():item[property];
  };
  }

  delete() {
    if (this.selection.selected.length > 0) {
      this.confirmDialogService.afterClosed('Czy na pewno chcesz usunąć elementy?').subscribe(result => {
        if (result) {
          for (let index = 0; index < this.selection.selected.length; index++) {
            const element = this.selection.selected[index];
            this.registrationFormsService.delete(element).subscribe(result => {
              var indexToDelete = this.dataSource.data.indexOf(element);
              this.dataSource.data.splice(indexToDelete, 1);
              this.dataSource._updateChangeSubscription();
              this.toastr.success("Pomyślnie usunięto formularz!")
            })
          }
        }
        this.selection.clear();
      });
    }
  }

  details() {
    if (this.selection.selected.length > 0) {
      var selectedElement = this.selection.selected[0];
      this.router.navigate(['management/registration-forms-details/', selectedElement.id],{state: { page: this.paginator.pageIndex, pageSize: this.paginator.pageSize}});
    }
  }


  done() {
    if (this.selection.selected.length > 0) {
      for (let index = 0; index < this.selection.selected.length; index++) {
        const element = this.selection.selected[index];
        element.status = true;
        this.registrationFormsService.markAsReaded(element).subscribe(result => {
        })
      }
    }

  }

  cancel() {
    if (this.selection.selected.length > 0) {
      for (let index = 0; index < this.selection.selected.length; index++) {
        const element = this.selection.selected[index];
        element.status = false;
        this.registrationFormsService.markAsUnreaded(element).subscribe(result => {
        })
      }
    }
  }

  constructor(private registrationFormsService: RegistrationFormsService,
    private router: Router,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private changeRef: ChangeDetectorRef) { super()
      if(this.router.getCurrentNavigation().extras.state){
        this.page=this.router.getCurrentNavigation().extras.state.page;
        this.pageSize=this.router.getCurrentNavigation().extras.state.pageSize;
      }
     }

}
